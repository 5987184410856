import Vue                      from 'vue';

import CitizenApiError          from '@/errors/citizen/CitizenApiError';
import CitizenMaintenanceError  from '@/errors/citizen/CitizenMaintenanceError';
import CitizenConnectApiService from '@/services/citizen/CitizenConnectApiService';

const storeKey = 'citizen.store.reverse911';

const getDefaultState = () => {
	return {
		runningFetch: false,
		runningUpdate: false,
		id: null,
		contact: false,
		dismiss: false,
		contactMethods: [],
		addresses: [],
		alertTypes: [],
	}
}

const state = getDefaultState()

const getters = {}

const actions = {

	initialize: async function ( { commit } ) {
		//console.log( 'citizen reverse 911 initialize' )
		await commit( 'setModuleStateFromLocalStorage' );
	},

	reset: async function ( {commit} ) {
		//console.log( 'citizen reverse 911 reset' )
		await commit( 'reset' );
		if( localStorage.getItem( storeKey ) ) {
			localStorage.removeItem( storeKey );
		}
	},

	fetch: async function ( {commit} ) {
		//set loading state
		commit( 'setRunningFetch', true );

		//get data from api
		let apiResponse = null;
		try {
			let url = 'v2/reverse911/detail';
			apiResponse = await CitizenConnectApiService.get( url );
		}
		catch( e ) {
			commit( 'setRunningFetch', false );
			throw new CitizenApiError( e.message, e.code, {}, '6DD58B22-9980-4E32-BE58-2244B8FCF95B' );
		}

		//handle non standard errors
		if( apiResponse === null ) {
			commit( 'setRunningFetch', false );
			throw new CitizenApiError( 'The server didn\'t return notification account information or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, '1D56FCED-5EC6-4B70-9427-58F00D09AC57' );
		}
		else if( apiResponse.data.error && apiResponse.data.status === 503 ) {
			commit( 'setRunningFetch', false );
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'EF360681-6E18-4BB7-9BC0-CCC6084FD676' );
		}
		else if( apiResponse.data.error ) {
			commit( 'setRunningFetch', false );
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '5CB4FBC9-33B5-487C-BB83-E331BC681B27' );
		}

		//set data
		commit( 'updateState', apiResponse.data.data );

		//set loading state
		commit( 'setRunningFetch', false );
	},

	save: async function ( {commit, state, dispatch} ) {
		//set loading state
		commit( 'setRunningUpdate', true );

		let payload = {
			addresses: state.addresses,
			contactMethods: state.contactMethods,
			alertTypes: state.alertTypes,
		}

		//get data from api
		let apiResponse = null;
		try {
			let url = 'v2/reverse911/save';
			apiResponse = await CitizenConnectApiService.post( url, payload );
		}
		catch( e ) {
			commit( 'setRunningUpdate', false );
			dispatch('sendErrorSnackbar', e, {root:true})
			//throw new CitizenApiError( e.message, e.code, {}, '6DD58B22-9980-4E32-BE58-2244B8FCF95B' );
		}

		//handle non standard errors
		if( apiResponse === null ) {
			commit( 'setRunningUpdate', false );
			dispatch('sendErrorSnackbar', {
				message:'Saving failed. Try signing out and back in. If you keep getting this error, contact support',
				code:500,
				guid:'1D56FCED-5EC6-4B70-9427-58F00D09AC57'
			}, {root:true})
			return
			//throw new CitizenApiError( 'The server didn\'t return notification account information or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, '1D56FCED-5EC6-4B70-9427-58F00D09AC57' );
		}
		else if( apiResponse.data.error && apiResponse.data.status === 503 ) {
			commit( 'setRunningUpdate', false );
			dispatch('sendErrorSnackbar', {
				message:apiResponse.data.message,
				code:apiResponse.data.status,
				guid:'EF360681-6E18-4BB7-9BC0-CCC6084FD676'
			}, {root:true})
			return
			//throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'EF360681-6E18-4BB7-9BC0-CCC6084FD676' );
		}
		else if( apiResponse.data.error ) {
			commit( 'setRunningUpdate', false );
			dispatch('sendErrorSnackbar', {
				message:apiResponse.data.message,
				code:apiResponse.data.status,
				guid:'5CB4FBC9-33B5-487C-BB83-E331BC681B27'
			}, {root:true})
			return
			//throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '5CB4FBC9-33B5-487C-BB83-E331BC681B27' );
		}

		//set data
		commit( 'setId', apiResponse.data.data.id );
		commit( 'setContact', apiResponse.data.data.contact );
		commit( 'setDismiss', apiResponse.data.data.dismiss );

		dispatch('sendSnackbar', {
			message: 'Saved changes successfully',
			color: 'success'
		}, {root:true})

		//set loading state
		commit( 'setRunningUpdate', false );
	},

}

const mutations = {

	setModuleStateFromLocalStorage: function ( state ) {
		if( localStorage.getItem( storeKey ) ) {
			// Replace the state object with the stored item
			let data = JSON.parse( localStorage.getItem( storeKey ) );
			if( data !== null ) {
				Object.assign( state, data )
				state.runningFetch = false;
				state.runningUpdate = false;
			}
		}
	},

	reset: function ( state ) {
		Object.assign( state, getDefaultState() )
	},

	setRunningFetch: function ( state, running ) {
		state.runningFetch = running;
	},

	setRunningUpdate: function ( state, running ) {
		state.runningUpdate = running;
	},

	setId: function ( state, id ) {
		state.id = id;
	},
	setContact: function ( state, contact ) {
		state.contact = contact;
	},
	setDismiss: function ( state, dismiss ) {
		state.dismiss = dismiss;
	},
	setContactMethods: function ( state, contactMethods ) {
		state.contactMethods = contactMethods;
	},
	setAddresses: function ( state, addresses ) {
		state.addresses = addresses;
	},
	setAlertTypes: function ( state, alertTypes ) {
		state.alertTypes = alertTypes;
	},

	updateAppendAddress: function ( state, address ) {
		let updatedAddress = false;
		for( let i in state.addresses ) {
			if( state.addresses[i].id==address.id ) {
				Vue.set( state.addresses, i, address);
				updatedAddress = true
				break;
			}
		}

		if( !updatedAddress ) {
			state.addresses.push( address );
		}
	},

	updateAppendContactMethod: function ( state, contactMethod ) {
		let updatedContactMethod = false;
		for( let i in state.contactMethods ) {
			if( state.contactMethods[i].id==contactMethod.id ) {
				Vue.set( state.contactMethods, i, contactMethod);
				updatedContactMethod = true
				break;
			}
		}

		if( !updatedContactMethod ) {
			state.contactMethods.push( contactMethod );
		}
	},


	updateAppendAlertType: function ( state, alertType ) {
		let updatedAlertType = false;
		for( let i in state.contactMethods ) {
			if( state.alertTypes[i].id==alertType.id ) {
				Vue.set( state.alertTypes, i, alertType);
				updatedAlertType = true
				break;
			}
		}

		if( !updatedAlertType ) {
			state.alertTypes.push( alertType );
		}
	},

	updateState: function ( state, serverResponse ) {
		state.id = serverResponse.id;
		state.contact = serverResponse.contact;
		state.dismiss = serverResponse.dismiss;
		state.contactMethods = serverResponse.contactMethods;
		state.addresses = serverResponse.addresses;
		state.alertTypes = serverResponse.alertTypes;
	},


}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
