import Vue                      from 'vue';

import * as constants           from '@/constants';
import CitizenApiError          from '@/errors/citizen/CitizenApiError';
import CitizenMaintenanceError  from '@/errors/citizen/CitizenMaintenanceError';
import CitizenConnectApiService from '@/services/citizen/CitizenConnectApiService';
import { standardizeParcelId }  from '@/services/citizen/citizenHelper';

const storeKey = 'citizen.store.tax';

const getDefaultState = () => {
	let now = new Date();
	return {
		accounts:                 {},
		paymentClient:            null,
		currentFiscalYear:        now.getMonth()<=5 ? now.getFullYear() : now.getFullYear() + 1,
		runningGetMemberAccounts: false
	}
}

const state = getDefaultState()

const getters = {

	currentFiscalYear:function(state) {
		return state.currentFiscalYear;
	},

	accounts:function(state){
		return state.accounts;
	},

	memberAccounts: function ( state, getters, rootState, rootGetters ) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_TAX_CODE);
		let memberAccounts = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) ) {
				memberAccounts.push( state.accounts[ accountNumbers[ i ] ] );
			}
		}
		return memberAccounts;
	},

	memberNotices:function(state, getters, rootState, rootGetters) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_TAX_CODE);
		let memberNotices = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) && !state.accounts[ accountNumbers[ i ] ]._meta.loading ) {
				memberNotices = memberNotices.concat( state.accounts[ accountNumbers[ i ] ].overview?.messages?.notices );
			}
		}
		return memberNotices;
	},

	memberWarnings:function(state, getters, rootState, rootGetters) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_TAX_CODE);
		let memberWarnings = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) && !state.accounts[ accountNumbers[ i ] ]._meta.loading ) {
				memberWarnings = memberWarnings.concat( state.accounts[ accountNumbers[ i ] ].overview?.messages?.warnings );
			}
		}
		return memberWarnings;
	},


}

const actions = {

	initialize: async function ( { commit, rootGetters } ) {
		//console.log( 'citizen tax initialize' )
		await commit( 'setModuleStateFromLocalStorage' );
		commit( 'defineAccountShells', rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_TAX_CODE) );
	},

	reset: async function ( {commit} ) {
		//console.log( 'citizen tax reset' )
		await commit( 'reset' );
		if( localStorage.getItem( storeKey ) ) {
			localStorage.removeItem( storeKey );
		}
	},

	getMemberAccounts: async function ( { state, commit, dispatch, rootState, rootGetters } ) {
		if(state.runningGetMemberAccounts) {
			return;
		}
/*
		commit('setAccountLoadingTrue', rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_TAX_CODE));
		commit('setAccountFetchingTrue', rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_TAX_CODE));

		//set the running state so that we don't double fetch on navigation
		commit('setRunningGetMemberAccounts', true );

		await dispatch('citizenMember/getAccountNumbers', null, { root:true });

		let memberAccountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_TAX_CODE);

		commit('setAccountLoadingTrue', memberAccountNumbers);
		commit('setAccountFetchingTrue', memberAccountNumbers);

		let dispatches = [];
		for( let i in memberAccountNumbers ) {
			let parcelId = memberAccountNumbers[ i ];
			if(typeof parcelId === 'string') {
				await dispatch( 'getAccount', { parcelId: parcelId, search:false } );
			}
		}

		await Promise.all(dispatches);
		commit('setRunningGetMemberAccounts', false );*/



		//set the running state so that we don't double fetch on navigation
		commit('setRunningGetMemberAccounts', true );

		let startingMemberAccountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_TAX_CODE);

		//set loading state
		commit('setAccountLoadingTrue', startingMemberAccountNumbers );
		commit('setAccountFetchingTrue', startingMemberAccountNumbers);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v3/taxes/accounts/' + rootState.citizenMember.user.userId );
		}
		catch( e ) {
			commit( 'setAccountLoadingFalse', startingMemberAccountNumbers );
			commit( 'setAccountFetchingFalse', startingMemberAccountNumbers );
			commit( 'setRunningGetMemberAccounts', false );
			if( e.code==503 ) {
				throw new CitizenMaintenanceError( e.message, e.code, {}, '64D51E1D-44FA-4DA2-BC40-BB76E55D4F11' );
			}
			throw new CitizenApiError( e.message, e.code, {}, '20A0010B-7077-472A-A042-0E1A4B3B5925' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', startingMemberAccountNumbers);
			commit('setAccountFetchingFalse', startingMemberAccountNumbers);
			commit('setRunningGetMemberAccounts', false );
			throw new CitizenApiError( 'The server didn\'t return the accounts or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, '94B0773A-FCB5-43A3-83CB-1104D0E09115' );
		}

		//set data
		let updatedMemberAccountNumbers = [];
		for( let i in apiResponse.data.data.accounts ) {
			updatedMemberAccountNumbers.push( apiResponse.data.data.accounts[i].account.parcelId );
			commit( 'updateAppendAccount', apiResponse.data.data.accounts[i] );
		}
		dispatch('citizenMember/setAccounts', { accounts:updatedMemberAccountNumbers, type:constants.PAYMENT_TYPE_TAX_CODE }, { root:true } );

		commit('setPaymentClient', apiResponse.data.data.payment );

		//set loading state of the accounts we touched
		commit('setAccountLoadingFalse', startingMemberAccountNumbers);
		commit('setAccountFetchingFalse', startingMemberAccountNumbers);

		//end the running state to allow fetching again
		commit('setRunningGetMemberAccounts', false );
	},

	getAccount: async function ( {commit}, { parcelId, search }  ) {
		parcelId = standardizeParcelId(parcelId);

		//shell the account in case it's not saved yet
		commit('defineAccountShells', [ parcelId ]);

		//set loading state
		commit('setAccountLoadingTrue', parcelId);
		commit('setAccountFetchingTrue', parcelId);

		//get data from api
		let apiResponse = null;
		try {
			let url = 'v3/taxes/detail/' + parcelId;
			if(search) {
				url += '/search';
			}

			apiResponse = await CitizenConnectApiService.get( url );
		}
		catch( e ) {
			commit( 'setAccountLoadingFalse', parcelId );
			commit( 'setAccountFetchingFalse', parcelId );
			if( e.code==503 ) {
				throw new CitizenMaintenanceError( e.message, e.code, {}, 'AAF53FAE-E8DE-4455-84D1-1DA30C792D6A' );
			}
			throw new CitizenApiError( e.message, e.code, {}, '52580E60-4CCC-4422-834D-20835510BE34' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', parcelId);
			commit('setAccountFetchingFalse', parcelId);
			throw new CitizenApiError( 'The server didn\'t return the account or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, '04128A38-4754-4F70-BB66-3A3ACB3B132C' );
		}

		//set data
		commit( 'updateAppendAccount', apiResponse.data.data );

		//set loading state
		commit('setAccountLoadingFalse', parcelId);
		commit('setAccountFetchingFalse', parcelId);
	},

	addAccount: async function ( { commit, dispatch, rootState }, parcelId ) {
		parcelId = standardizeParcelId(parcelId);

		//set loading state
		commit('setAccountLoadingTrue', parcelId);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.post( 'v3/taxes/add/'+rootState.citizenMember.user.userId+'/'+parcelId, {} );
		}
		catch( e ) {
			commit('setAccountLoadingFalse', parcelId);
			throw new CitizenApiError( e.message, e.code, {}, '9D2207BD-FE1B-4323-90FA-3ECBDC247F1A' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', parcelId);
			throw new CitizenApiError( 'Failed to add account. Try again.', 404, {}, 'ED9F1D17-A562-4729-9EA9-0C83F193553C' );
		}
		else if( apiResponse.data.error && apiResponse.data.status=== 503) {
			commit('setAccountLoadingFalse', parcelId);
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '19BBACCB-A7A1-4D7F-AA5F-92BBC4EF3838' );
		}
		else if( apiResponse.data.error ) {
			commit('setAccountLoadingFalse', parcelId);
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'E74826A5-CF0E-4D85-B4E1-4600EB71388A' );
		}

		//add the type to member
		await dispatch( 'citizenMember/addAccount', { type:constants.PAYMENT_TYPE_TAX_CODE, account:apiResponse.data.data.account }, { root:true } );

		commit('defineAccountShells', [ apiResponse.data.data.account ]);

		//get account data
		await dispatch('getAccount', { parcelId:apiResponse.data.data.account, search:false });

		//set loading state
		commit('setAccountLoadingFalse', parcelId);

		//return the new account
		return apiResponse.data.data.account;

	},

	removeAccount: async function ( { dispatch, commit, rootState }, parcelId ) {
		parcelId = standardizeParcelId(parcelId);

		//set removing state
		commit('setAccountRemovingTrue', parcelId);

		//tell api to remove account
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.delete( 'v3/taxes/remove/'+rootState.citizenMember.user.userId+'/'+parcelId );
		}
		catch( e ) {
			commit('setAccountRemovingFalse', parcelId);
			throw new CitizenApiError( e.message, e.code, {}, 'DC5E6339-902B-4AD8-A506-59469C533565' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountRemovingFalse', parcelId);
			throw new CitizenApiError( 'Failed to remove account. Try again.', 404, {}, '6AD169D6-9697-45A8-82AC-818F88F26BD8' );
		}
		else if( apiResponse.data.error && apiResponse.data.status===503 ) {
			commit( 'setAccountRemovingFalse', parcelId );
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '74C86A4E-376E-4338-92EA-EF1BA20DE7EC' );
		}
		else if( apiResponse.data.error ) {
			commit( 'setAccountRemovingFalse', parcelId );
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '26A557CF-A7C8-4467-A9DF-71BE6A16C3B7' );
		}

		//remove the type from member store
		await dispatch( 'citizenMember/removeAccount', {
			type:    constants.PAYMENT_TYPE_TAX_CODE,
			account: apiResponse.data.data.account
		}, { root: true } );

		//set removing state
		commit( 'setAccountRemovingFalse', parcelId );
	},

	clearAccountFromStore: function( { commit }, parcelId ) {
		commit( 'clearAccountFromStore', parcelId )
	},

	clearAccountsFromStore: function( { commit }, parcelIds ) {
		commit( 'clearAccountsFromStore', parcelIds )
	},

	clearNonMemberAccountsFromStore: function( { commit, rootGetters, state } ) {
		let storeParcelIds     = Object.keys( state.accounts );
		let memberParcelIds    = rootGetters[ "citizenMember/accountNumbers" ]( constants.PAYMENT_TYPE_TAX_CODE );
		let nonMemberParcelIds = [];

		for( let i in storeParcelIds ) {
			let parcelId = storeParcelIds[ i ];
			if( !memberParcelIds.includes( parcelId ) ) {
				nonMemberParcelIds.push( parcelId );
			}
		}

		if( nonMemberParcelIds.length>0 ) {
			commit( 'clearAccountsFromStore', nonMemberParcelIds )
		}
	}

}

const mutations = {

	setModuleStateFromLocalStorage: function ( state ) {
		if( localStorage.getItem( storeKey ) ) {
			// Replace the state object with the stored item
			let data = JSON.parse( localStorage.getItem( storeKey ) );
			if( data !== null ) {
				Object.assign( state, data )

				//this is a page refresh so force the accounts to not be loading (in case a refresh started when the account was loading)
				state.runningGetMemberAccounts = false;
				for( let i in state.accounts ) {
					state.accounts[ i ]._meta.fetching = false;
					state.accounts[ i ]._meta.loading  = false;
				}
			}
		}
	},

	clearAccountFromStore: function( state, parcelId ) {
		if( Object.prototype.hasOwnProperty.call( state.accounts, parcelId ) ) {
			delete state.accounts[ parcelId ]
		}
	},

	clearAccountsFromStore: function( state, parcelIds ) {
		for( let i in parcelIds ) {
			if( Object.prototype.hasOwnProperty.call( state.accounts, parcelIds[ i ] ) ) {
				delete state.accounts[ parcelIds[ i ] ]
			}
		}
	},

	reset: function( state ) {
		Object.assign( state, getDefaultState() )
	},

	setRunningGetMemberAccounts: function( state, running ) {
		state.runningGetMemberAccounts = running;
	},

	setAccountLoadingTrue:  function( state, parcelIds ) {
		if( !Array.isArray( parcelIds ) ) {
			parcelIds = [ parcelIds ];
		}
		for( let i in parcelIds ) {
			let parcelId = standardizeParcelId( parcelIds[ i ] );
			if( Object.prototype.hasOwnProperty.call( state.accounts, parcelId ) ) {
				state.accounts[ parcelId ]._meta.loading = true;
			}
		}
	},
	setAccountLoadingFalse: function ( state, parcelIds ) {
		if(!Array.isArray(parcelIds)) {
			parcelIds = [ parcelIds ];
		}
		for( let i in parcelIds ) {
			let parcelId = standardizeParcelId(parcelIds[i]);
			if( Object.prototype.hasOwnProperty.call(state.accounts, parcelId ) ) {
				state.accounts[ parcelId ]._meta.loading = false;
			}
		}
	},

	setAccountFetchingTrue: function ( state, parcelIds ) {
		if(!Array.isArray(parcelIds)) {
			parcelIds = [ parcelIds ];
		}
		for( let i in parcelIds ) {
			let parcelId = standardizeParcelId(parcelIds[i]);
			if( Object.prototype.hasOwnProperty.call(state.accounts, parcelId ) ) {
				state.accounts[ parcelId ]._meta.fetching = true;
			}
		}
	},
	setAccountFetchingFalse: function ( state, parcelIds ) {
		if(!Array.isArray(parcelIds)) {
			parcelIds = [ parcelIds ];
		}
		for( let i in parcelIds ) {
			let parcelId = standardizeParcelId(parcelIds[i]);
			if( Object.prototype.hasOwnProperty.call(state.accounts, parcelId ) ) {
				state.accounts[ parcelId ]._meta.fetching = false;
			}
		}
	},

	setAccountRemovingTrue: function ( state, parcelIds ) {
		if(!Array.isArray(parcelIds)) {
			parcelIds = [ parcelIds ];
		}
		for( let i in parcelIds ) {
			let parcelId = standardizeParcelId(parcelIds[i]);
			if( Object.prototype.hasOwnProperty.call(state.accounts, parcelId ) ) {
				state.accounts[ parcelId ]._meta.removing = true;
			}
		}
	},
	setAccountRemovingFalse: function ( state, parcelIds ) {
		if(!Array.isArray(parcelIds)) {
			parcelIds = [ parcelIds ];
		}
		for( let i in parcelIds ) {
			let parcelId = standardizeParcelId(parcelIds[i]);
			if( Object.prototype.hasOwnProperty.call(state.accounts, parcelId ) ) {
				state.accounts[ parcelId ]._meta.removing = false;
			}
		}
	},

	setPaymentClient:function( state, paymentClient) {
		state.paymentClient = paymentClient;
	},

	updateAppendAccount: function ( state, newAccount ) {
		if( Object.prototype.hasOwnProperty.call(state.accounts, newAccount.account.parcelId)) {
			Object.assign(state.accounts[ newAccount.account.parcelId ], newAccount)
		}
		else {
			Vue.set(state.accounts, newAccount.account.parcelId, newAccount)
		}
	},

	defineAccountShells: function ( state, parcelIds ) {
		if(!Array.isArray(parcelIds)) {
			parcelIds = [ parcelIds ];
		}
		for( let i in parcelIds) {
			let parcelId = standardizeParcelId(parcelIds[i]);
			if( !Object.prototype.hasOwnProperty.call(state.accounts, parcelId)) {
				let accountShell = {
					pulled:false,
					account:{
						parcelId:parcelId
					},
					overview:{
						status:-1
					},
					_meta:{
						loading:true,
						removing:false,
						fetched:null,
						fetching:false
					}
				};
				Vue.set(state.accounts, parcelId, accountShell)
			}
		}

	},


}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
