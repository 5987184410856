import { DateTime }             from 'luxon';
import Vue                      from 'vue';

import * as constants           from '@/constants';
import CitizenApiError          from '@/errors/citizen/CitizenApiError';
import CitizenMaintenanceError  from '@/errors/citizen/CitizenMaintenanceError';
import CitizenConnectApiService from '@/services/citizen/CitizenConnectApiService';

const storeKey = 'citizen.store.atv';

const getDefaultState = () => {
	let now = new Date();
	return {
		accounts:                    {},
		paymentClient:               null,
		currentFiscalYear:           now.getMonth()<=5 ? now.getFullYear() : now.getFullYear() + 1,
		currentPermitYear:           now.getMonth()<9 ? now.getFullYear() : now.getFullYear() + 1,
		runningGetMemberAccounts:    false,
		vehicleTypes:                [],
		newVehicle:                  {},
		engineSizeUnitOfMeasurement: [
			{
				value: 'CC',
				text:  'CC (Cubic Centimetres)'
			},
			{
				value: 'KW',
				text: 'KW (Kilowatts)'
			},
			{
				value: 'HP',
				text: 'HP (Horse Power)'
			},
		],
		vehicleColors:[
			{
				value: 'camo',
				text: 'Camo'
			},
			{
				value: 'white',
				text: 'White'
			},
			{
				value: 'silver',
				text: 'Silver'
			},
			{
				value: 'black',
				text: 'Black'
			},
			{
				value: 'gray',
				text: 'Gray'
			},
			{
				value: 'blue',
				text: 'Blue'
			},
			{
				value: 'red',
				text: 'Red'
			},
			{
				value: 'green',
				text: 'Green'
			},
			{
				value: 'brown',
				text: 'Brown'
			},
			{
				value: 'yellow',
				text: 'Yellow'
			},
			{
				value: 'purple',
				text: 'Purple'
			},
			{
				value: 'maroon',
				text: 'Maroon'
			},
			{
				value: 'orange',
				text: 'Orange'
			},
			{
				value: 'pink',
				text: 'Pink'
			},
		]
	}
}

const state = getDefaultState()

const getters = {

	currentFiscalYear:function(state) {
		return state.currentFiscalYear;
	},

	currentPermitYear:function(state) {
		return state.currentPermitYear;
	},

	accounts:function(state){
		return state.accounts;
	},

	vehicleTypes:function(state){
		return state.vehicleTypes;
	},

	vehicleColors:function(state){
		return state.vehicleColors;
	},

	newVehicle:function(state){
		return state.newVehicle;
	},

	engineSizeUnitOfMeasurement:function(state){
		return state.engineSizeUnitOfMeasurement;
	},

	memberAccounts: function ( state, getters, rootState, rootGetters ) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_ATV_CODE);
		let memberAccounts = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) ) {
				memberAccounts.push( state.accounts[ accountNumbers[ i ] ] );
			}
		}
		return memberAccounts;
	},

	memberNotices:function(state, getters, rootState, rootGetters) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_ATV_CODE);
		let memberNotices = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) && !state.accounts[ accountNumbers[ i ] ]._meta.loading ) {
				memberNotices = memberNotices.concat( state.accounts[ accountNumbers[ i ] ].overview?.messages?.notices );
			}
		}
		return memberNotices;
	},

	memberWarnings:function(state, getters, rootState, rootGetters) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_ATV_CODE);
		let memberWarnings = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) && !state.accounts[ accountNumbers[ i ] ]._meta.loading ) {
				memberWarnings = memberWarnings.concat( state.accounts[ accountNumbers[ i ] ].overview?.messages?.warnings );
			}
		}
		return memberWarnings;
	},


}

const actions = {

	initialize: async function ( { dispatch, commit, rootGetters } ) {
		//console.log( 'citizen atv initialize' )
		await commit( 'setModuleStateFromLocalStorage' );
		commit( 'defineAccountShells', rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_ATV_CODE) );

		if( rootGetters["citizenMember/isSignedIn"] ) {
			let tasks = [
				dispatch( 'fetchVehicleTypes' ),
					dispatch( 'fetchNewVehicle', rootGetters["citizenMember/userId"] )
			]
			await Promise.all( tasks )
		}

	},

	reset: async function ( {commit} ) {
		//console.log( 'citizen atv residential reset' )
		await commit( 'reset' );
		if( localStorage.getItem( storeKey ) ) {
			localStorage.removeItem( storeKey );
		}
	},

	getMemberAccounts: async function ( { state, commit, dispatch, rootState, rootGetters } ) {
		if(state.runningGetMemberAccounts) {
			return;
		}

		//set the running state so that we don't double fetch on navigation
		commit('setRunningGetMemberAccounts', true );

		let startingMemberAccountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_ATV_CODE);

		//set loading state
		commit('setAccountLoadingTrue', startingMemberAccountNumbers );
		commit('setAccountFetchingTrue', startingMemberAccountNumbers);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/atv/accounts/' + rootState.citizenMember.user.userId );
		}
		catch( e ) {
			commit( 'setAccountLoadingFalse', startingMemberAccountNumbers );
			commit( 'setAccountFetchingFalse', startingMemberAccountNumbers );
			commit( 'setRunningGetMemberAccounts', false );
			if( e.code==503 ) {
				throw new CitizenMaintenanceError( e.message, e.code, {}, 'A7017003-C735-4678-9305-F97439B4072E' );
			}
			throw new CitizenApiError( e.message, e.code, {}, '4456CF3D-78C2-416F-B4D9-F6667BB09E52' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', startingMemberAccountNumbers);
			commit('setAccountFetchingFalse', startingMemberAccountNumbers);
			commit('setRunningGetMemberAccounts', false );
			throw new CitizenApiError( 'The server didn\'t return the accounts or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, 'CA2D4F94-6796-4652-BFA0-6C59D08218BC' );
		}

		//set data
		let updatedMemberAccountNumbers = [];
		for( let i in apiResponse.data.data.accounts ) {
			if(apiResponse.data.data.accounts[ i ].account) {
				updatedMemberAccountNumbers.push( apiResponse.data.data.accounts[ i ].account.customerId );
				commit( 'updateAppendAccount', apiResponse.data.data.accounts[ i ] );
			}
		}
		dispatch('citizenMember/setAccounts', { accounts:updatedMemberAccountNumbers, type:constants.PAYMENT_TYPE_ATV_CODE }, { root:true } );

		commit('setPaymentClient', apiResponse.data.data.payment );

		//set loading state of the accounts we touched
		commit('setAccountLoadingFalse', startingMemberAccountNumbers);
		commit('setAccountFetchingFalse', startingMemberAccountNumbers);

		//end the running state to allow fetching again
		commit('setRunningGetMemberAccounts', false );
	},

	getAccount: async function ( {commit}, customerId ) {
		//shell the account in case it's not saved yet
		commit('defineAccountShells', [ customerId ]);

		//set loading state
		commit('setAccountLoadingTrue', customerId);
		commit('setAccountFetchingTrue', customerId);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/atv/detail/' + customerId );
		}
		catch( e ) {
			commit( 'setAccountLoadingFalse', customerId );
			commit( 'setAccountFetchingFalse', customerId );
			if( e.code==503 ) {
				throw new CitizenMaintenanceError( e.message, e.code, {}, '3F0BA787-7E56-46ED-967E-68DB8C096E2A' );
			}
			throw new CitizenApiError( e.message, e.code, {}, '42F83DC9-A40C-4CFA-BD87-9F58DAA0476C' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', customerId);
			commit('setAccountFetchingFalse', customerId);
			throw new CitizenApiError( 'The server didn\'t return the account or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, 'E19497B5-42DB-41C4-9F39-2326417B02E9' );
		}

		//standardize date fields
		if(apiResponse.data.data.customer.dob && apiResponse.data.data.customer.dob.includes('/')) {
			let d = DateTime.fromFormat(apiResponse.data.data.customer.dob, "MM/dd/yyyy")
			if(d.isValid) {
				apiResponse.data.data.customer.dob = d.toISODate();
			}
		}
		//standardize date fields
		if(apiResponse.data.data.customer.co_dob && apiResponse.data.data.customer.co_dob.includes('/')) {
			let d = DateTime.fromFormat(apiResponse.data.data.customer.co_dob, "MM/dd/yyyy")
			if(d.isValid) {
				apiResponse.data.data.customer.co_dob = d.toISODate();
			}
		}

		//set data
		commit( 'updateAppendAccount', apiResponse.data.data );

		//set loading state
		commit('setAccountLoadingFalse', customerId);
		commit('setAccountFetchingFalse', customerId);
	},

	addAccount: async function ( { commit, dispatch, rootState }, { account, year, customerId } ) {
		//account = permit number
		let url = ''
		if(account) {
			url = 'v2/atv/add/'+rootState.citizenMember.user.userId+'/'+account+'/'+year
		}
		else if(customerId) {
			url = 'v2/atv/add_by_customer_id/'+rootState.citizenMember.user.userId+'/'+customerId
		}
		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.post( url, {} );
		}
		catch( e ) {
			throw new CitizenApiError( e.message, e.code, {}, 'BE966746-B6FE-4A7C-AF61-DACC8BF280AB' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			throw new CitizenApiError( 'Failed to add account. Try again.', 404, {}, '62788615-5D5C-4EA4-B7E1-5D8F6CBF0947' );
		}
		else if( apiResponse.data.error && apiResponse.data.status=== 503) {
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'CBF6E3B9-415F-42C6-9D7C-5596C1A5269F' );
		}
		else if( apiResponse.data.error ) {
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'FEB99622-CD25-4BE5-9D11-8C4FB31FEF11' );
		}

		//add the type to member
		await dispatch( 'citizenMember/addAccount', { type:constants.PAYMENT_TYPE_ATV_CODE, account:apiResponse.data.data.account }, { root:true } );

		commit('defineAccountShells', [ apiResponse.data.data.account ]);

		//get account data
		await dispatch('getAccount', apiResponse.data.data.account);

		//return the new account
		return apiResponse.data.data.account;

	},

	removeAccount: async function ( { dispatch, commit, rootState }, customerId ) {
		//set removing state
		commit('setAccountRemovingTrue', customerId);

		//tell api to remove account
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.delete( 'v2/atv/remove/'+rootState.citizenMember.user.userId+'/'+customerId );
		}
		catch( e ) {
			commit('setAccountRemovingFalse', customerId);
			throw new CitizenApiError( e.message, e.code, {}, '5FA6AE43-E5AC-419E-889E-8B66CE26C828' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountRemovingFalse', customerId);
			throw new CitizenApiError( 'Failed to remove account. Try again.', 404, {}, '65594236-B05C-410E-880A-09C584901485' );
		}
		else if( apiResponse.data.error && apiResponse.data.status=== 503) {
			commit('setAccountRemovingFalse', customerId);
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '3778B075-69E2-4574-87C3-1BF6392B865F' );
		}
		else if( apiResponse.data.error ) {
			commit('setAccountRemovingFalse', customerId);
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '4AAFF4C0-4486-473F-B2DA-F5AD10F0B02E' );
		}

		//remove the type from member store
		await dispatch( 'citizenMember/removeAccount', { type:constants.PAYMENT_TYPE_ATV_CODE, account:apiResponse.data.data.account }, { root:true } );

		//set removing state
		commit('setAccountRemovingFalse', customerId);
	},

	fetchVehicleTypes:async function( { commit } ) {

		//tell api to remove account
		try {
			let apiResponse = await CitizenConnectApiService.get( 'v2/atv/vehicle_types' );
			commit('setVehicleTypes', apiResponse.data.data)
		}
		catch( e ) {
			throw new CitizenApiError( e.message, e.code, {}, '9A532380-9892-493A-9D98-A4E2804ECF1E' );
		}

	},

	fetchNewVehicle:async function( { commit }, customerId) {

		try {
			let apiResponse = await CitizenConnectApiService.get( 'v2/atv/vehicle/' + customerId + '/0' );
			commit('setNewVehicle', apiResponse.data.data)
			return apiResponse.data.data;
		}
		catch( e ) {
			throw new CitizenApiError( e.message, e.code, {}, 'B914CE03-AF4F-4D91-AABA-4446B90688EA' );
		}

	}

}




const mutations = {

	setModuleStateFromLocalStorage: function ( state ) {
		if( localStorage.getItem( storeKey ) ) {
			let now = new Date();
			// Replace the state object with the stored item
			let data = JSON.parse( localStorage.getItem( storeKey ) );
			if( data !== null ) {
				Object.assign( state, data )

				state.currentFiscalYear = now.getMonth()<=5 ? now.getFullYear() : now.getFullYear() + 1
				state.currentPermitYear = now.getMonth()<9 ? now.getFullYear() : now.getFullYear() + 1

				//this is a page refresh so force the accounts to not be loading (in case a refresh started when the account was loading)
				state.runningGetMemberAccounts = false;
				for( let i in state.accounts ) {
					state.accounts[ i ]._meta.fetching = false;
					state.accounts[ i ]._meta.loading  = false;
				}
			}
		}
	},

	reset: function ( state ) {
		Object.assign(state, getDefaultState())
	},

	setNewVehicle:function ( state, newVehicle ) {
		state.newVehicle = newVehicle;
	},

	setRunningGetMemberAccounts:function( state, running ) {
		state.runningGetMemberAccounts = running;
	},

	setAccountLoadingTrue: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.loading = true;
			}
		}
	},
	setAccountLoadingFalse: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.loading = false;
			}
		}
	},

	setAccountFetchingTrue: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.fetching = true;
			}
		}
	},
	setAccountFetchingFalse: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.fetching = false;
			}
		}
	},

	setAccountRemovingTrue: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.removing = true;
			}
		}
	},
	setAccountRemovingFalse: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.removing = false;
			}
		}
	},

	setPaymentClient:function( state, paymentClient) {
		state.paymentClient = paymentClient;
	},

	updateAppendAccount: function ( state, newAccount ) {
		if( Object.prototype.hasOwnProperty.call(state.accounts, newAccount.account.customerId)) {
			Object.assign(state.accounts[ newAccount.account.customerId ], newAccount)
		}
		else {
			Vue.set(state.accounts, newAccount.account.customerId, newAccount)
		}
	},

	updateAppendVehicle: function ( state, { customerId, vehicle } ) {

		if( Object.prototype.hasOwnProperty.call(state.accounts, customerId)) {
			let updated = false;
			for( let i in state.accounts[customerId].customer.vehicles ) {
				if(state.accounts[ customerId ].customer.vehicles[i].id==vehicle.id) {
					Object.assign(state.accounts[ customerId ].customer.vehicles[i], vehicle)
					updated = true;
					break;
				}
			}

			if(!updated) {
				state.accounts[ customerId ].customer.vehicles.push( vehicle );
			}
		}
		else {
			// Vue.set(state.accounts, customerId, newAccount)
			//console.warn('No customer with this id in state store')
		}
	},

	defineAccountShells: function ( state, customerIds ) {
		let now = new Date();
		for( let i in customerIds) {
			let customerId = customerIds[i];
			if( !Object.prototype.hasOwnProperty.call(state.accounts, customerId)) {
				let accountShell = {
					account:{
						customerId:customerId
					},
					customer:{},
					overview:{
						status:-1
					},
					_meta:{
						loading:true,
						removing:false,
						fetched:null,
						fetching:false,
						year:{
							current:now.getMonth()<10 ? now.getFullYear() : now.getFullYear()+1,
							next:now.getMonth()<10 ? now.getFullYear()+1 : now.getFullYear()+2
						}
					}
				};
				Vue.set(state.accounts, customerId, accountShell)
			}
		}

	},

	setVehicleTypes:function( state, vehicleTypes ) {
		state.vehicleTypes = vehicleTypes
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
