import { isEmpty }              from 'lodash';
import Vue                      from 'vue';

import CitizenApiError          from '@/errors/citizen/CitizenApiError';
import CitizenMaintenanceError  from '@/errors/citizen/CitizenMaintenanceError';
import CitizenConnectApiService from '@/services/citizen/CitizenConnectApiService';

const storeKey = 'citizen.store.payment';

const getDefaultState = () => {
	return {
		transactions: {},

		fetchingVaults: false,
		vaults:         [],

		fetchingBlankPaymentClient:false,
		blankPaymentClient:[],

		fetchingHistoricalTransactions:false,
		historicalTransactions:[],

		fetchingScheduledTransactions:false,
		scheduledTransactions:[]
	}
}


const state = getDefaultState()

const getters = {


	transactions:function(state){
		return state.transactions;
	},

	vaults:function(state){
		return state.vaults;
	},

	fetchingHistoricalTransactions:function ( state ) {
		return state.fetchingHistoricalTransactions;
	},

	historicalTransactions:function ( state ) {
		return state.historicalTransactions;
	},

	fetchingScheduledTransactions:function ( state ) {
		return state.fetchingScheduledTransactions;
	},

	scheduledTransactions:function ( state ) {
		return state.scheduledTransactions;
	}

}

const actions = {
	initialize: async function ( ) {
		//console.log( 'citizen payment store initialize' )
		//await commit( 'setModuleStateFromLocalStorage' );
	},

	reset:async function({ commit }) {
		commit('reset')
		if( localStorage.getItem( storeKey ) ) {
			localStorage.removeItem( storeKey );
		}
	},


	fetchVaults: async function({ commit, rootGetters }) {
		commit('setFetchingVaults', true);

		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/payment/get_vaults/' + rootGetters['citizenMember/userId'] );
			//console.log( apiResponse )
			await commit( 'setVaults', apiResponse.data.data )
		}
		catch( e ) {
			//console.log(e);
			throw new CitizenApiError( e.message, e.code, {}, 'B462C42D-A175-49B5-907C-0E0CAFB84C14' );
		}

		commit('setFetchingVaults', false);

	},

	fetchBlankPaymentClient: async function({ commit }) {
		commit('setFetchingBlankPaymentClient', true);

		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/payment/blank_client' );
			//console.log( apiResponse )
			await commit( 'setBlankPaymentClient', apiResponse.data.data )
		}
		catch( e ) {
			//console.log(e);
			throw new CitizenApiError( e.message, e.code, {}, 'D4F3F7EF-76E9-4F8D-BAB3-BA1F4A248FD1' );
		}

		commit('setFetchingBlankPaymentClient', false);

	},


	/**
	 * @param {Object} params
	 * @param {Object} params.vault - Vault object (with updated data)
	 * @param {string} params.paymentType - The payment type (taxes, public_utilities, etc)
	 * @param {string} params.methodType - The payment method type (credit, echeck )
	 * @param {function} params.successCallback - Optional success callback function
	 * @param {function} params.errorCallback - Optional error callback function
	 */
	updateVault: async function ( {dispatch, commit, rootGetters}, params ) {

		//console.log( 'updateVault' );

		//VALIDATION
		try {
			if( isEmpty( params ) ) {
				throw new Error( 'Cannot update vault without providing the parameters to update' );
			}
			else if( typeof ( params.paymentType ) != 'string' || params.paymentType === '' ) {
				throw new Error( 'Payment type is required' );
			}
			else if( typeof ( params.methodType ) != 'string' || params.methodType === '' ) {
				throw new Error( 'Payment method is required' );
			}
			else if( isEmpty( params.vault ) || typeof ( params.vault.id ) == 'undefined' || params.vault.id === null ) {
				throw new Error( 'Vault is required' );
			}
		}
		catch( e ) {
			dispatch( 'sendErrorSnackbar', e, {root:true} );
			return;
		}

		//UPDATE
		try {
			let apiResponse = await CitizenConnectApiService.post( 'v2/payment/update_vault/' + rootGetters['citizenMember/userId'] + '/' + params.paymentType + '/' + params.methodType + '/' + params.vault.id, params.vault );

			if( apiResponse.data.error ) {
				throw new Error( apiResponse.data.message );
			}

			if( typeof ( params.successCallback ) == 'function' ) {
				params.successCallback( apiResponse.data );
			}

			commit('updateVault', params.vault);

			dispatch( 'sendSnackbar', {
				message: 'Saved payment method updated successfully',
				color: 'success',
			}, {root: true} )
		}
		catch( e ) {
			if( typeof ( params.errorCallback ) == 'function' ) {
				params.errorCallback( e );
			}

			dispatch( 'sendErrorSnackbar', e, {root: true} )
		}

	},

	/**
	 * @param {Object} params
	 * @param {string} params.vaultId - Vault id to remove
	 * @param {string} params.paymentType - The payment type (taxes, public_utilities, etc)
	 * @param {string} params.methodType - The payment method type (credit, echeck )
	 * @param {function} params.successCallback - Optional success callback function
	 * @param {function} params.errorCallback - Optional error callback function
	 */
	removeVault: async function ( {dispatch, commit, rootGetters}, params ) {

		//console.log( 'removeVault' );

		//VALIDATION
		try {
			if( isEmpty( params ) ) {
				throw new Error( 'Cannot remove vault without providing the parameters to remove' );
			}
			else if( typeof ( params.paymentType ) != 'string' || params.paymentType === '' ) {
				throw new Error( 'Payment type is required' );
			}
			else if( typeof ( params.methodType ) != 'string' || params.methodType === '' ) {
				throw new Error( 'Payment method is required' );
			}

			else if( !params.vaultId ) {
				throw new Error( 'Vault id is required' );
			}
		}
		catch( e ) {
			dispatch( 'sendErrorSnackbar', e, {root: true} );
			return;
		}

		//REMOVE
		try {
			let apiResponse = await CitizenConnectApiService.delete( 'v2/payment/remove_vault/' + rootGetters['citizenMember/userId'] + '/' + params.paymentType + '/' + params.methodType + '/' + params.vaultId );

			if( apiResponse.data.error ) {
				throw new Error( apiResponse.data.message );
			}

			if( typeof ( params.successCallback ) == 'function' ) {
				params.successCallback( apiResponse.data );
			}

			commit('removeVault', params.vaultId);

			dispatch( 'sendSnackbar', {
				message: 'Saved payment method has been removed',
				color: 'success',
			}, {root: true} )
		}
		catch( e ) {
			if( typeof ( params.errorCallback ) == 'function' ) {
				params.errorCallback( e );
			}

			dispatch( 'sendErrorSnackbar', e, {root: true} )
		}


	},

	/**
	 * @param {Object} params
	 * @param {string} params.account - Account number to enable autopay for
	 * @param {string} params.paymentType - The type of the account (taxes, public_utilities, etc)
	 * @param {string} params.methodType - The payment method type to use for autopay for this account (credit, echeck )
	 * @param {string} params.vaultId - Vault id to use for autopay
	 * @param {function} params.successCallback - Optional success callback function
	 * @param {function} params.errorCallback - Optional error callback function
	 */
	saveAutopay: async function ( {dispatch, rootGetters}, params ) {
		//console.log( 'saveAutopay' );

		//VALIDATION
		try {
			if( isEmpty( params ) ) {
				throw new Error( 'Cannot save autopay without providing the parameters to save' );
			}
			else if( typeof ( params.paymentType ) != 'string' || params.paymentType === '' ) {
				throw new Error( 'Payment type is required' );
			}
			else if( typeof ( params.methodType ) != 'string' || params.methodType === '' ) {
				throw new Error( 'Payment method is required' );
			}
			else if( (typeof ( params.vaultId ) != 'string' && typeof ( params.vaultId ) != 'number') || params.vaultId === '' ) {
				throw new Error( 'Vault id is required' );
			}
			else if( typeof ( params.account ) != 'string' || params.account === '' ) {
				throw new Error( 'Account is required' );
			}
		}
		catch( e ) {
			dispatch( 'sendErrorSnackbar', e, {root:true} );
			return;
		}

		//SAVE
		try {
			let apiResponse = await CitizenConnectApiService.postForm( 'v2/payment/save_autopay/' + rootGetters['citizenMember/userId'] + '/' + params.paymentType + '/' + params.account, {
				methodType: params.methodType,
				vaultId: params.vaultId
			} );

			if( apiResponse.data.error ) {
				throw new Error( apiResponse.data.message );
			}

			if( typeof ( params.successCallback ) == 'function' ) {
				params.successCallback( apiResponse.data );
			}

			dispatch( 'sendSnackbar', {
				message: 'Saved autopay settings for ' + params.account,
				color: 'success',
			}, {root: true} )
		}
		catch( e ) {
			if( typeof ( params.errorCallback ) == 'function' ) {
				params.errorCallback( e );
			}

			dispatch( 'sendErrorSnackbar', e, {root: true} )
		}


	},

	/**
	 * @param {Object} params
	 * @param {string} params.account - Account number to enable autopay for
	 * @param {string} params.paymentType - The payment type (taxes, public_utilities, etc)
	 * @param {function} params.successCallback - Optional success callback function
	 * @param {function} params.errorCallback - Optional error callback function
	 */
	disableAutopay: async function ( {dispatch,rootGetters}, params ) {

		//console.log( 'disableAutopay' );

		//VALIDATION
		try {
			if( isEmpty( params ) ) {
				throw new Error( 'Cannot disable autopay without providing parameters' );
			}
			else if( typeof ( params.paymentType ) != 'string' || params.paymentType === '' ) {
				throw new Error( 'Payment type is required' );
			}
			else if( typeof ( params.account ) != 'string' || params.account === '' ) {
				throw new Error( 'Account is required' );
			}
		}
		catch( e ) {
			dispatch( 'sendErrorSnackbar', e );
			return;
		}

		//DISABLE
		try {
			let apiResponse = await CitizenConnectApiService.delete( 'v2/payment/disable_autopay/' + rootGetters['citizenMember/userId'] + '/' + params.paymentType + '/' + params.account );

			if( apiResponse.data.error ) {
				throw new Error( apiResponse.data.message );
			}

			if( typeof ( params.successCallback ) == 'function' ) {
				params.successCallback( apiResponse.data );
			}

			dispatch( 'sendSnackbar', {
				message: 'Disabled autopay for ' + params.account,
				color: 'success',
			}, {root: true} )
		}
		catch( e ) {
			if( typeof ( params.errorCallback ) == 'function' ) {
				params.errorCallback( e );
			}

			dispatch( 'sendErrorSnackbar', e, {root: true} )
		}
	},

	shellTransactionIfItDoesNotExist: async function({commit}, transactionUiId ) {
		commit('defineTransactionShells', [ transactionUiId ]);
	},

	getTransaction: async function ( {commit,rootGetters}, transactionUiId ) {

		//shell the account in case it's not saved yet
		commit('defineTransactionShells', [ transactionUiId ]);

		//set loading state
		commit('setTransactionLoadingTrue', transactionUiId);
		commit('setTransactionFetchingTrue', transactionUiId);

		let isScheduledTransaction = transactionUiId.startsWith('S-');

		//get data from api
		let apiResponse = null;
		try {
			let urlKey = 'transaction';
			if(isScheduledTransaction) {
				urlKey = 'scheduled_transaction';
			}
			apiResponse = await CitizenConnectApiService.get( 'v2/payment/'+ urlKey +'/' + rootGetters['citizenMember/userId'] + '/' + transactionUiId );
		}
		catch( e ) {
			commit('setTransactionLoadingFalse', transactionUiId);
			commit('setTransactionFetchingFalse', transactionUiId);
			throw new CitizenApiError( e.message, e.code, {}, '518AA803-3833-442E-903E-5CC98FD9E114' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setTransactionLoadingFalse', transactionUiId);
			commit('setTransactionFetchingFalse', transactionUiId);
			throw new CitizenApiError( 'The server didn\'t return the transaction or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, '611881BC-E192-4A54-BADC-A5EC9A56F0A3' );
		}
		else if( apiResponse.data.error && apiResponse.data.status=== 503) {
			commit('setTransactionLoadingFalse', transactionUiId);
			commit('setTransactionFetchingFalse', transactionUiId);
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '606FCC93-60E2-4EB7-A76E-6026376F2CDD' );
		}
		else if( apiResponse.data.error ) {
			commit('setTransactionLoadingFalse', transactionUiId);
			commit('setTransactionFetchingFalse', transactionUiId);
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '05E983B3-BF38-4EF8-8B71-4F192FAC7FCE' );
		}

		//set data
		apiResponse.data.data.transactionUiId = transactionUiId;
		commit( 'updateAppendTransaction', apiResponse.data.data );

		//set loading state
		commit('setTransactionLoadingFalse', transactionUiId);
		commit('setTransactionFetchingFalse', transactionUiId);
	},


	fetchScheduledTransactions: async function({ commit, rootGetters }) {
		commit('setFetchingScheduledTransactions', true);

		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/payment/scheduled_transactions/' + rootGetters['citizenMember/userId'] );
			//console.log( apiResponse )
			await commit( 'setScheduledTransactions', apiResponse.data.data )
		}
		catch( e ) {
			//console.log(e);
			throw new CitizenApiError( e.message, e.code, {}, '99C2B263-89C5-4DA1-BEF7-D0207E15D00E' );
		}

		commit('setFetchingScheduledTransactions', false);
	},

	fetchHistoricalTransactions: async function({ commit, rootGetters }) {
		commit('setFetchingHistoricalTransactions', true);

		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/payment/transactions/' + rootGetters['citizenMember/userId'] );
			await commit( 'setHistoricalTransactions', apiResponse.data.data )
		}
		catch( e ) {
			throw new CitizenApiError( e.message, e.code, {}, '99C2B263-89C5-4DA1-BEF7-D0207E15D00E' );
		}

		commit('setFetchingHistoricalTransactions', false);
	},

	cancelScheduledTransaction: async function({ commit, rootGetters }, transactionId) {
		try {
			await CitizenConnectApiService.delete( 'v2/payment/scheduled_transactions/' + rootGetters['citizenMember/userId'] + '/' + transactionId );
			await commit( 'removeScheduledTransaction', transactionId )
		}
		catch( e ) {
			throw new CitizenApiError( e.message, e.code, {}, '34861FBA-C297-4B7F-B3D1-815292F528F1' );
		}
	}
}

const mutations = {

	reset:function(state) {
		Object.assign(state, getDefaultState())
	},

	updateVault:function( state, vault ) {
		for(let i in state.vaults) {
			if(state.vaults[i].id==vault.id) {
				state.vaults[i]= vault;
				break;
			}
		}
	},

	removeVault:function( state, vaultId ) {
		for(let i in state.vaults) {
			if(state.vaults[i].id==vaultId) {
				state.vaults.splice(i, 1);
				break;
			}
		}
	},

	setTransactionLoadingTrue: function ( state, transactionUiIds ) {
		if(!Array.isArray(transactionUiIds)) {
			transactionUiIds = [ transactionUiIds ];
		}
		for( let i in transactionUiIds ) {
			let transactionUiId = transactionUiIds[i];
			if( Object.prototype.hasOwnProperty.call(state.transactions, transactionUiId ) ) {
				state.transactions[ transactionUiId ]._meta.loading = true;
			}
		}
	},
	setTransactionLoadingFalse: function ( state, transactionUiIds ) {
		if(!Array.isArray(transactionUiIds)) {
			transactionUiIds = [ transactionUiIds ];
		}
		for( let i in transactionUiIds ) {
			let transactionUiId = transactionUiIds[i];
			if( Object.prototype.hasOwnProperty.call(state.transactions, transactionUiId ) ) {
				state.transactions[ transactionUiId ]._meta.loading = false;
			}
		}
	},

	setTransactionFetchingTrue: function ( state, transactionUiIds ) {
		if(!Array.isArray(transactionUiIds)) {
			transactionUiIds = [ transactionUiIds ];
		}
		for( let i in transactionUiIds ) {
			let transactionUiId = transactionUiIds[i];
			if( Object.prototype.hasOwnProperty.call(state.transactions, transactionUiId ) ) {
				state.transactions[ transactionUiId ]._meta.fetching = true;
			}
		}
	},
	setTransactionFetchingFalse: function ( state, transactionUiIds ) {
		if(!Array.isArray(transactionUiIds)) {
			transactionUiIds = [ transactionUiIds ];
		}
		for( let i in transactionUiIds ) {
			let transactionUiId = transactionUiIds[i];
			if( Object.prototype.hasOwnProperty.call(state.transactions, transactionUiId ) ) {
				state.transactions[ transactionUiId ]._meta.fetching = false;
			}
		}
	},

	setTransactionRemovingTrue: function ( state, transactionUiIds ) {
		if(!Array.isArray(transactionUiIds)) {
			transactionUiIds = [ transactionUiIds ];
		}
		for( let i in transactionUiIds ) {
			let transactionUiId = transactionUiIds[i];
			if( Object.prototype.hasOwnProperty.call(state.transactions, transactionUiId ) ) {
				state.transactions[ transactionUiId ]._meta.removing = true;
			}
		}
	},
	setTransactionRemovingFalse: function ( state, transactionUiIds ) {
		if(!Array.isArray(transactionUiIds)) {
			transactionUiIds = [ transactionUiIds ];
		}
		for( let i in transactionUiIds ) {
			let transactionUiId = transactionUiIds[i];
			if( Object.prototype.hasOwnProperty.call(state.transactions, transactionUiId ) ) {
				state.transactions[ transactionUiId ]._meta.removing = false;
			}
		}
	},

	updateAppendTransaction: function ( state, transaction ) {
		if( Object.prototype.hasOwnProperty.call(state.transactions, transaction.publicIdentifier)) {
			Object.assign(state.transactions[ transaction.transactionUiId ], transaction)
		}
		else {
			Vue.set(state.transactions, transaction.transactionUiId, transaction)
		}
	},

	defineTransactionShells: function ( state, transactionUiIds ) {
		for( let i in transactionUiIds) {
			let transactionUiId = transactionUiIds[i];
			if( !Object.prototype.hasOwnProperty.call(state.transactions, transactionUiId)) {
				let transactionShell = {
					publicIdentifier:transactionUiId,
					_meta:{
						loading:true,
						removing:false,
						fetched:null,
						fetching:false
					}
				};
				Vue.set(state.transactions, transactionUiId, transactionShell)
			}
		}

	},

	setFetchingVaults: function ( state, val ) {
		state.fetchingVaults = val;
	},

	setVaults:function(state, vaults) {
		state.vaults = vaults;
	},

	setFetchingBlankPaymentClient: function ( state, val ) {
		state.fetchingBlankPaymentClient = val;
	},

	setBlankPaymentClient:function(state, blankPaymentClient) {
		state.blankPaymentClient = blankPaymentClient;
	},

	setFetchingHistoricalTransactions: function ( state, val ) {
		state.fetchingHistoricalTransactions = val;
	},

	setHistoricalTransactions:function(state, transactions) {
		state.historicalTransactions = transactions;
	},

	setFetchingScheduledTransactions: function ( state, val ) {
		state.fetchingScheduledTransactions = val;
	},

	setScheduledTransactions:function(state, transactions) {
		state.scheduledTransactions = transactions;
	},

	removeScheduledTransaction:function(state, transactionId) {
		let index = null;
		for(let i in state.scheduledTransactions) {
			if(state.scheduledTransactions[i].id==transactionId) {
				index = i;
				break;
			}
		}
		if(index!==null) {
			state.scheduledTransactions.splice( index, 1 )
		}
	}

}


export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
