import { padStart } from "lodash"

export function standardizeParcelId( parcelId ) {
	return padStart( parcelId, 8, '0' );
}

export function sdatLink( parcelId ) {
	parcelId = standardizeParcelId( parcelId );
	//https://sdat.dat.maryland.gov/RealProperty/Pages/viewdetails.aspx?County=12&SearchType=ACCT&District=01&AccountNumber=001612
	let link = 'https://sdat.dat.maryland.gov/RealProperty/Pages/viewdetails.aspx?County=12&SearchType=ACCT&District=';
	link += parcelId.substring( 0, 2 );
	link += '&AccountNumber=' + parcelId.substring( 2 );
	return link;
}