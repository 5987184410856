import Vue                      from 'vue';

import * as constants           from '@/constants';
import CitizenApiError          from '@/errors/citizen/CitizenApiError';
import CitizenMaintenanceError  from '@/errors/citizen/CitizenMaintenanceError';
import CitizenConnectApiService from '@/services/citizen/CitizenConnectApiService';

const storeKey = 'citizen.store.landfillCommercialWaste';

const getDefaultState = () => {
	let now = new Date();
	return {
		accounts:                 {},
		paymentClient:            null,
		currentFiscalYear:        now.getMonth()<=5 ? now.getFullYear() : now.getFullYear() + 1,
		runningGetMemberAccounts: false
	}
}

const state = getDefaultState()

const getters = {

	currentFiscalYear:function(state) {
		return state.currentFiscalYear;
	},

	accounts:function(state){
		return state.accounts;
	},

	memberAccounts: function ( state, getters, rootState, rootGetters ) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_CODE);
		let memberAccounts = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) ) {
				memberAccounts.push( state.accounts[ accountNumbers[ i ] ] );
			}
		}
		return memberAccounts;
	},

	memberNotices:function(state, getters, rootState, rootGetters) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_CODE);
		let memberNotices = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) && !state.accounts[ accountNumbers[ i ] ]._meta.loading ) {
				memberNotices = memberNotices.concat( state.accounts[ accountNumbers[ i ] ].overview?.messages?.notices );
			}
		}
		return memberNotices;
	},

	memberWarnings:function(state, getters, rootState, rootGetters) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_CODE);
		let memberWarnings = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) && !state.accounts[ accountNumbers[ i ] ]._meta.loading ) {
				memberWarnings = memberWarnings.concat( state.accounts[ accountNumbers[ i ] ].overview?.messages?.warnings );
			}
		}
		return memberWarnings;
	},


}

const actions = {

	initialize: async function ( { commit, rootGetters } ) {
		//console.log( 'citizen landfill residential initialize' )
		await commit( 'setModuleStateFromLocalStorage' );
		commit( 'defineAccountShells', rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_CODE) );
	},

	reset: async function ( {commit} ) {
		//console.log( 'citizen landfill residential reset' )
		await commit( 'reset' );
		if( localStorage.getItem( storeKey ) ) {
			localStorage.removeItem( storeKey );
		}
	},

	getMemberAccounts: async function ( { state, commit, dispatch, rootState, rootGetters } ) {
		if(state.runningGetMemberAccounts) {
			return;
		}

		//set the running state so that we don't double fetch on navigation
		commit('setRunningGetMemberAccounts', true );

		let startingMemberAccountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_CODE);

		//set loading state
		commit('setAccountLoadingTrue', startingMemberAccountNumbers );
		commit('setAccountFetchingTrue', startingMemberAccountNumbers);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/wasteworks/' + constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_API_CODE + '/accounts/' + rootState.citizenMember.user.userId );
		}
		catch( e ) {
			commit( 'setAccountLoadingFalse', startingMemberAccountNumbers );
			commit( 'setAccountFetchingFalse', startingMemberAccountNumbers );
			commit( 'setRunningGetMemberAccounts', false );
			if( e.code==503 ) {
				throw new CitizenMaintenanceError( e.message, e.code, {}, '3B619248-7BF2-48A8-A8D9-D4E0A304B097' );
			}
			throw new CitizenApiError( e.message, e.code, {}, '040EEC53-C379-4F6F-9D2B-2FBFFCBB7483' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', startingMemberAccountNumbers);
			commit('setAccountFetchingFalse', startingMemberAccountNumbers);
			commit('setRunningGetMemberAccounts', false );
			throw new CitizenApiError( 'The server didn\'t return the accounts or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, 'C4DE6DB9-50F4-4BE4-B697-16771FF32803' );
		}

		//set data
		let updatedMemberAccountNumbers = [];
		for( let i in apiResponse.data.data.accounts ) {
			if(apiResponse.data.data.accounts[ i ].account) {
				updatedMemberAccountNumbers.push( apiResponse.data.data.accounts[ i ].account.customerId );
				commit( 'updateAppendAccount', apiResponse.data.data.accounts[ i ] );
			}
		}
		dispatch('citizenMember/setAccounts', { accounts:updatedMemberAccountNumbers, type:constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_CODE }, { root:true } );

		commit('setPaymentClient', apiResponse.data.data.payment );

		//set loading state of the accounts we touched
		commit('setAccountLoadingFalse', startingMemberAccountNumbers);
		commit('setAccountFetchingFalse', startingMemberAccountNumbers);

		//end the running state to allow fetching again
		commit('setRunningGetMemberAccounts', false );
	},

	getAccount: async function ( {commit}, customerId ) {
		//shell the account in case it's not saved yet
		commit('defineAccountShells', [ customerId ]);

		//set loading state
		commit('setAccountLoadingTrue', customerId);
		commit('setAccountFetchingTrue', customerId);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/wasteworks/' + constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_API_CODE + '/detail/' + customerId );
		}
		catch( e ) {
			commit( 'setAccountLoadingFalse', customerId );
			commit( 'setAccountFetchingFalse', customerId );
			if( e.code==503 ) {
				throw new CitizenMaintenanceError( e.message, e.code, {}, '099C8454-47F9-4519-A1B6-87E829785EB7' );
			}
			throw new CitizenApiError( e.message, e.code, {}, 'A550E15B-2726-4ADB-B4AA-DA02BE737D26' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', customerId);
			commit('setAccountFetchingFalse', customerId);
			throw new CitizenApiError( 'The server didn\'t return the account or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, 'B164EA38-39C8-4D96-8396-018E07B949E8' );
		}

		//set data
		commit( 'updateAppendAccount', apiResponse.data.data );

		//set loading state
		commit('setAccountLoadingFalse', customerId);
		commit('setAccountFetchingFalse', customerId);
	},

}

const mutations = {

	setModuleStateFromLocalStorage: function ( state ) {
		if( localStorage.getItem( storeKey ) ) {
			// Replace the state object with the stored item
			let data = JSON.parse( localStorage.getItem( storeKey ) );
			if( data !== null ) {
				Object.assign( state, data )

				//this is a page refresh so force the accounts to not be loading (in case a refresh started when the account was loading)
				state.runningGetMemberAccounts = false;
				for(let i in state.accounts) {
					state.accounts[i]._meta.fetching = false;
					state.accounts[i]._meta.loading = false;
				}
			}
		}
	},

	reset: function ( state ) {
		Object.assign(state, getDefaultState())
	},

	setRunningGetMemberAccounts:function( state, running ) {
		state.runningGetMemberAccounts = running;
	},

	setAccountLoadingTrue: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.loading = true;
			}
		}
	},
	setAccountLoadingFalse: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.loading = false;
			}
		}
	},

	setAccountFetchingTrue: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.fetching = true;
			}
		}
	},
	setAccountFetchingFalse: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.fetching = false;
			}
		}
	},

	setAccountRemovingTrue: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.removing = true;
			}
		}
	},
	setAccountRemovingFalse: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.removing = false;
			}
		}
	},

	setPaymentClient:function( state, paymentClient) {
		state.paymentClient = paymentClient;
	},

	updateAppendAccount: function ( state, newAccount ) {
		if( Object.prototype.hasOwnProperty.call(state.accounts, newAccount.account.customerId)) {
			Object.assign(state.accounts[ newAccount.account.customerId ], newAccount)
		}
		else {
			Vue.set(state.accounts, newAccount.account.customerId, newAccount)
		}
	},

	defineAccountShells: function ( state, customerIds ) {
		for( let i in customerIds) {
			let customerId = customerIds[i];
			if( !Object.prototype.hasOwnProperty.call(state.accounts, customerId)) {
				let accountShell = {
					account:{
						customerId:customerId
					},
					customer:{
						id:customerId
					},
					tickets:[],
					overview:{
						status:-1,
						messages:{
							warnings:[],
							notices:[],
						}
					},
					_meta:{
						loading:true,
						removing:false,
						fetched:null,
						fetching:false
					}
				};
				Vue.set(state.accounts, customerId, accountShell)
			}
		}

	},


}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
