const state = () => ( {
	routing:            false,
	to:                 {},
	_warnOnRouteChange: []
} )

const getters = {

	routing: function( state ) {
		return state.routing;
	},

	to: function( state ) {
		return state.to;
	},

	warnOnRouteChange: function( state ) {
		return state._warnOnRouteChange.length > 0;
	}

}

const actions = {

	/**
	 *
	 * @param commit
	 * @returns {string} Unique Id that needs to be provided to removeWarnOnRouteChanges to free up your claim to warning on route changes
	 */
	addWarnOnRouteChange: function( { commit } ) {
		let uniqueId = '_' + Math.random().toString( 36 ).substr( 2, 9 );
		commit( '_addWarnOnRouteChange', uniqueId );
		return uniqueId
	},

	removeWarnOnRouteChange: async function( { commit }, uniqueId ) {
		await commit( '_removeWarnOnRouteChange', uniqueId );
	}


}

const mutations = {

	//DATA STATE
	routing: function( state, routing ) {
		state.routing = routing;
	},
	to:      function( state, to ) {
		state.to = to;
	},

	_addWarnOnRouteChange: function( state, uniqueId ) {
		state._warnOnRouteChange.push( uniqueId );
	},

	_removeWarnOnRouteChange: async function( state, uniqueId ) {
		let index = state._warnOnRouteChange.findIndex( x => x === uniqueId );
		if( index > -1 ) {
			state._warnOnRouteChange.splice( index, 1 );
		}

	},

	_removeAllWarningRouteChanges: function( state ) {
		state._warnOnRouteChange = [];
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}