<template>
	<div>
		<v-system-bar height="30" dark color="pink darken-2" style="position: sticky;top:0;z-index:10" lights-out v-if="$store.state.citizenMember.impersonating">
			Impersonating <v-chip x-small color="pink darken-4" class="ml-2" >{{ $store.state.citizenMember.user.email }}</v-chip>
			<v-spacer></v-spacer>
			<v-btn x-small outlined @click="$store.dispatch('citizenMember/exitImpersonate')">Exit & Return to Self</v-btn>
		</v-system-bar>
		<router-view></router-view>
	</div>
</template>

<script>
import { VBtn, VChip, VSpacer, VSystemBar } from 'vuetify/lib';

export default {
	name: "citizenRouterViewWrapper",
	components:{ VSystemBar, VChip, VBtn, VSpacer },
	metaInfo: {
		title: 'Citizen Connect - Garrett County Government',
		titleTemplate: '%s - Citizen Connect - Garrett County Government'
	}
}
</script>
