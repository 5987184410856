import CitizenApiError          from '@/errors/citizen/CitizenApiError';
import CitizenMaintenanceError  from '@/errors/citizen/CitizenMaintenanceError';
import CitizenConnectApiService from '@/services/citizen/CitizenConnectApiService';
import { standardizeParcelId }  from '@/services/citizen/citizenHelper';

const storeKey = 'citizen.store.search';

const getDefaultState = () => {
	return {
		accountNumber:            '',
		parcelId:                 '',
		ownerName:                '',
		streetNumber:             '',
		streetName:               '',
		runningTaxSearch:         false,
		taxSearchResultParcelIds: [],
		runningUtilitySearch:         false,
		utilitySearchResultAccountNumbers: [],
	};
};

const state = getDefaultState();

const getters = {};

const actions = {

	initialize: async function( { commit } ) {
		//console.log( 'citizen search initialize' )
		await commit( 'setModuleStateFromLocalStorage' );
	},

	reset: async function( { commit } ) {
		//console.log( 'citizen search reset' )
		await commit( 'reset' );
		if( localStorage.getItem( storeKey ) ) {
			localStorage.removeItem( storeKey );
		}
	},

	taxSearch: async function( { state, commit, rootGetters, dispatch } ) {
		if( state.runningTaxSearch ) {
			return;
		}

		if( state.parcelId.trim()==='' && state.ownerName.trim()==='' && state.streetNumber.trim()==='' && state.streetName.trim()==='' ) {
			dispatch( 'sendSnackbar', {
				message: 'At least one search field must be filled out',
				color:   'warning',
			}, {root:true} );
			return;
		}

		//set the running state so that we don't double fetch on navigation
		await commit( 'setRunningTaxSearch', true );
		await dispatch( 'citizenTax/clearNonMemberAccountsFromStore', null, { root: true } );
		commit( 'setTaxSearchResultParcelIds', [] );

		let searchParams = {
			parcelId:     state.parcelId,
			ownerName:    state.ownerName,
			streetNumber: state.streetNumber,
			streetName:   state.streetName,
		};

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v3/taxes/search/?' + new URLSearchParams( searchParams ).toString() );
		}
		catch( e ) {
			commit( 'setRunningTaxSearch', false );
			if( e.code==503 ) {
				throw new CitizenMaintenanceError( e.message, e.code, {}, '8F19D5AF-4C4C-4331-868D-6B84BE3332A5' );
			}
			throw new CitizenApiError( e.message, e.code, {}, 'AB7C0A8D-56CE-4C36-89B1-A83EED520483' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit( 'setRunningTaxSearch', false );
			throw new CitizenApiError( 'The server didn\'t respond normally. Try again, then try signing out and back in.', 500, {}, 'D7AE8BFA-3C17-4D23-BDFB-B23283517788' );
		}

		//set data
		let parcelIds = [];
		for( let i in apiResponse.data.data.accounts ) {
			let simpleAccountData = apiResponse.data.data.accounts[ i ];
			await commit( 'citizenTax/defineAccountShells', simpleAccountData.parcelId, { root: true } );
			let accountShell = rootGetters[ 'citizenTax/accounts' ][ standardizeParcelId( simpleAccountData.parcelId ) ];
			if( !accountShell._meta.fetched ) {
				accountShell.account.customerId = simpleAccountData.customerId;
				accountShell.customer           = {
					name1:   simpleAccountData.ownerName,
					address: {
						address: simpleAccountData.address,
					},
				};
				accountShell.overview.status    = 1;
			}
			if( simpleAccountData.pulled ) {
				accountShell.overview.status = 3;
			}
			else if( simpleAccountData.paid ) {
				accountShell.overview.status = 0;
			}

			await commit( 'citizenTax/updateAppendAccount', accountShell, { root: true } );
			parcelIds.push( simpleAccountData.parcelId );
		}
		commit( 'setTaxSearchResultParcelIds', parcelIds );

		//end the running state to allow fetching again
		commit( 'setRunningTaxSearch', false );
	},


	utilitySearch: async function( { state, commit, rootGetters, dispatch } ) {
		if( state.runningUtilitySearch ) {
			return;
		}

		if( state.parcelId.trim()==='' && state.accountNumber.trim()==='' && state.ownerName.trim()==='' && state.streetNumber.trim()==='' && state.streetName.trim()==='' ) {
			dispatch( 'sendSnackbar', {
				message: 'At least one search field must be filled out',
				color:   'warning',
			}, {root:true} );
			return;
		}

		//set the running state so that we don't double fetch on navigation
		await commit( 'setRunningUtilitySearch', true );
		await dispatch( 'citizenUtility/clearNonMemberAccountsFromStore', null, { root: true } );
		commit( 'setUtilitySearchResultAccountNumbers', [] );

		let searchParams = {
			parcelId:     state.parcelId,
			accountNumber:state.accountNumber
		};

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v3/public_utilities/search/?' + new URLSearchParams( searchParams ).toString() );
		}
		catch( e ) {
			commit( 'setRunningUtilitySearch', false );
			if( e.code==503 ) {
				throw new CitizenMaintenanceError( e.message, e.code, {}, '35A3D8F8-465D-4D73-9AFF-721BC58541BB' );
			}
			throw new CitizenApiError( e.message, e.code, {}, '906654AB-251F-4AD8-82E4-496DCFCFE766' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit( 'setRunningUtilitySearch', false );
			throw new CitizenApiError( 'The server didn\'t respond normally. Try again, then try signing out and back in.', 500, {}, '8154D12C-62BF-4DC6-833D-22C23BD21BEB' );
		}

		console.log(apiResponse)

		//set data
		let accountNumbers = [];
		for( let i in apiResponse.data.data.accounts ) {
			let simpleAccountData = apiResponse.data.data.accounts[ i ];
			await commit( 'citizenUtility/defineAccountShells', simpleAccountData.account, { root: true } );
			let accountShell = rootGetters[ 'citizenUtility/accounts' ][ simpleAccountData.account ];
			if( !accountShell._meta.fetched ) {
				accountShell.account.accountNumber = simpleAccountData.account;
				accountShell.account.parcelId = simpleAccountData.parcelId;
				accountShell.account.customerId = simpleAccountData.customerId;
				accountShell.account.serviceAddress = {
					address: simpleAccountData.serviceAddress,
				}
				accountShell.customer           = {
					name1:   simpleAccountData.ownerName,
					address:{
						address: simpleAccountData.serviceAddress
					}
				};
				accountShell.overview.status    = 1;
			}
			if( simpleAccountData.pulled ) {
				accountShell.overview.status = 3;
			}
			else if( simpleAccountData.paid ) {
				accountShell.overview.status = 0;
			}

			await commit( 'citizenUtility/updateAppendAccount', accountShell, { root: true } );
			accountNumbers.push( simpleAccountData.account );
		}
		commit( 'setUtilitySearchResultAccountNumbers', accountNumbers );

		//end the running state to allow fetching again
		commit( 'setRunningUtilitySearch', false );
	},

	clearFormAndResults: async function( { commit, dispatch } ) {
		dispatch( 'citizenTax/clearNonMemberAccountsFromStore', null, { root: true } );
		commit( 'reset' );
	},

};

const mutations = {

	setModuleStateFromLocalStorage: function( state ) {
		if( localStorage.getItem( storeKey ) ) {
			// Replace the state object with the stored item
			let data = JSON.parse( localStorage.getItem( storeKey ) );
			if( data!==null ) {
				Object.assign( state, data );

				state.runningTaxSearch = false;
			}
		}
	},

	reset: function( state ) {
		Object.assign( state, getDefaultState() );
	},

	setTaxSearchResultParcelIds: function( state, parcelIds ) {
		state.taxSearchResultParcelIds = parcelIds;
	},

	setRunningTaxSearch: function( state, running ) {
		state.runningTaxSearch = running;
	},

	setUtilitySearchResultAccountNumbers: function( state, accountNumbers ) {
		state.utilitySearchResultAccountNumbers = accountNumbers;
	},

	setRunningUtilitySearch: function( state, running ) {
		state.runningUtilitySearch = running;
	},

	updateParcelId( state, parcelId ) {
		state.parcelId = parcelId;
	},

	updateAccountNumber( state, accountNumber ) {
		state.accountNumber = accountNumber;
	},

	updateOwnerName( state, ownerName ) {
		state.ownerName = ownerName;
	},

	updateStreetNumber( state, streetNumber ) {
		state.streetNumber = streetNumber;
	},

	updateStreetName( state, streetName ) {
		state.streetName = streetName;
	},

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
