import CustomError from "@/errors/CustomError";


class AuthError
	extends CustomError {

	constructor(message, code=0, data={}, guid='') {
		// Pass remaining arguments (including vendor specific ones) to parent constructor
		super( message, code, data, guid )
	}

}
export default AuthError;