import axios       from 'axios';
import { isEmpty } from 'lodash';

import ApiError    from '@/errors/ApiError';

import store from '../../store';

class CitizenConnectApiService {
	constructor() {
		if( !CitizenConnectApiService.instance ) {
			CitizenConnectApiService.instance = this;
		}
		if( !CitizenConnectApiService.axiosAbortController ) {
			CitizenConnectApiService.axiosAbortController = new AbortController();
		}

		return CitizenConnectApiService.instance;
	}

	static getBaseUrl() {
		return process.env.VUE_APP_PAYMENTS_API_URL
	}

	static getUrlParams() {
		return process.env.VUE_APP_PAYMENTS_API_URL_PARAMS
	}

	static urlBuild( urlPath ) {
		if( urlPath.substr( 0, 4 )==='http' ) {
			return urlPath;
		}

		let cleanUrlPath = urlPath.replace( /^\/+|\/+$/g, '' );
		let append       = '';
		if( CitizenConnectApiService.getUrlParams()!=='' ) {
			append = '?';
			if( cleanUrlPath.indexOf( '?' )> -1 ) {
				append = '&';
			}
			append += CitizenConnectApiService.getUrlParams();
		}

		return CitizenConnectApiService.getBaseUrl() + cleanUrlPath + append;
	}

	/**
	 *
	 * @param options
	 * @param authentication
	 * @returns {Promise<{headers: {Authorization: string}}>}
	 * @throws {AuthError}
	 */
	static buildAxiosConfig = async function( options = {}, authentication = true ) {

		let config = {
			signal: CitizenConnectApiService.axiosAbortController.signal,
			...options
		};

		if( authentication ) {
			let citizenConnectApiAccessToken = await store.dispatch( 'citizenMember/getAccessToken' );
			if( citizenConnectApiAccessToken=='' ) {
				//console.log( 'empty access token' );
				throw new ApiError( 'Not authenticated', 401 );
			}

			if( typeof ( config.headers )==='undefined' ) {
				config.headers = {};
			}

			config.headers[ 'Authorization' ] = 'Bearer ' + citizenConnectApiAccessToken;
		}

		return config;
	}

	/**
	 * Standardize error reporting up the stack to our ApiError
	 * @param {Error} e
	 * @throws {ApiError}
	 */
	static apiErrorCatch = async function( e ) {
		if( axios.isCancel( e ) ) {
			//console.log( 'Request canceled', e.message );
		}

		//if it is a blob (file download)
		if( e.request.responseType==='blob' && e.response.data instanceof Blob && e.response.data.type && e.response.data.type.toLowerCase().indexOf( 'json' )!= -1 ) {
			let resolvedResponse = JSON.parse( await e.response.data.text() );
			if( resolvedResponse.message ) {
				throw new ApiError( resolvedResponse.message, resolvedResponse.status, resolvedResponse.data );
			}
		}

		//api returned a structured error
		if( e.response && e.response.data && e.response.data.data ) {
			throw new ApiError( e.response.data.message, e.response.status, e.response.data.data );
		}
		//axios error - presumably a network error
		else {
			throw e
		}
	}

	cancel = async function( cancelMessage = '' ) {
		CitizenConnectApiService.axiosAbortController.abort( cancelMessage );
		CitizenConnectApiService.axiosAbortController = new AbortController();
	}

	/**
	 *
	 * @param {string} url
	 * @param {object} options
	 * @param authentication
	 * @returns {Promise<AxiosResponse<any>>}
	 * @throws {AuthError}
	 */
	get = async function( url, options = {}, authentication = true ) {
		let config = await CitizenConnectApiService.buildAxiosConfig( options, authentication );

		return axios.get( CitizenConnectApiService.urlBuild( url ), config ).catch( await CitizenConnectApiService.apiErrorCatch );
	}

	/**
	 *
	 * @param {string} url
	 * @param {object} data
	 * @param {object} options
	 * @returns {Promise<AxiosResponse<any>>}
	 * @throws {AuthError}
	 */
	post = async function( url, data, options = {}, authentication = true ) {
		let config = await CitizenConnectApiService.buildAxiosConfig( options, authentication );

		return axios.post( CitizenConnectApiService.urlBuild( url ), data, config ).catch( await CitizenConnectApiService.apiErrorCatch );
	}

	/**
	 *
	 * @param {string} url
	 * @param {object} data
	 * @param {object} options
	 * @returns {Promise<AxiosResponse<any>>}
	 * @throws {AuthError}
	 */
	postForm = async function( url, data, options = {}, authentication = true ) {
		if( isEmpty( options.headers ) ) {
			options.headers = {};
		}
		options.headers[ 'Content-Type' ] = 'multipart/form-data';

		let config = await CitizenConnectApiService.buildAxiosConfig( options, authentication );

		return axios.post( CitizenConnectApiService.urlBuild( url ), data, config ).catch( await CitizenConnectApiService.apiErrorCatch );
	}

	/**
	 *
	 * @param {string} url
	 * @param {object} data
	 * @param {object} options
	 * @returns {Promise<AxiosResponse<any>>}
	 * @throws {AuthError}
	 */
	put = async function( url, data, options = {}, authentication = true ) {
		let config = await CitizenConnectApiService.buildAxiosConfig( options, authentication );

		return axios.put( CitizenConnectApiService.urlBuild( url ), data, config ).catch( await CitizenConnectApiService.apiErrorCatch );
	}

	/**
	 *
	 * @param {string} url
	 * @param {object} options
	 * @returns {Promise<AxiosResponse<any>>}
	 * @throws {AuthError}
	 */
	delete = async function( url, options = {}, authentication = true ) {
		let config = await CitizenConnectApiService.buildAxiosConfig( options, authentication );

		return axios.delete( CitizenConnectApiService.urlBuild( url ), config ).catch( await CitizenConnectApiService.apiErrorCatch );
	}


	postDownload = async function( url, data, options = {}, authentication = true ) {
		let fullOptions = {
			...options,
			responseType: 'blob'
		};
		let config      = await CitizenConnectApiService.buildAxiosConfig( fullOptions, authentication );

		let response = null
		try {
			response = await axios.post( CitizenConnectApiService.urlBuild( url ), data, config )
		}
		catch( e ) {
			await CitizenConnectApiService.apiErrorCatch( e )
		}

		if( response===null ) {
			throw new ApiError( 'Getting download failed', 0 );
		}

		let downloadUrl = window.URL.createObjectURL( new Blob( [ response.data ] ) );
		let link        = document.createElement( 'a' );
		link.href       = downloadUrl;
		let fileName    = 'file';
		if( response.headers[ 'content-disposition' ] ) {
			let fileNameMatch = response.headers[ 'content-disposition' ].match( /filename=(.+)/ );
			if( fileNameMatch && fileNameMatch.length===2 ) {
				fileName = fileNameMatch[ 1 ].replaceAll( /["']/gi, '' );
			}
			else if( fileNameMatch && fileNameMatch.length===1 ) {
				fileName = fileNameMatch[ 0 ].replaceAll( /["']/gi, '' );
			}
		}
		link.setAttribute( 'download', fileName );
		document.body.appendChild( link );
		link.click();
		link.remove();
	}


	getDownload = async function( url, options = {}, authentication = true ) {
		let fullOptions = {
			...options,
			responseType: 'blob'
		};
		let config      = await CitizenConnectApiService.buildAxiosConfig( fullOptions, authentication );

		let response = null
		try {
			response = await axios.get( CitizenConnectApiService.urlBuild( url ), config )
		}
		catch( e ) {
			await CitizenConnectApiService.apiErrorCatch( e )
		}

		if( response===null ) {
			throw new ApiError( 'Getting download failed', 0 );
		}

		let downloadUrl = window.URL.createObjectURL( new Blob( [ response.data ] ) );
		let link        = document.createElement( 'a' );
		link.href       = downloadUrl;
		let fileName    = 'file';
		if( response.headers[ 'content-disposition' ] ) {
			let fileNameMatch = response.headers[ 'content-disposition' ].match( /filename=(.+)/ );
			if( fileNameMatch && fileNameMatch.length===2 ) {
				fileName = fileNameMatch[ 1 ].replaceAll( /["']/gi, '' );
			}
			else if( fileNameMatch && fileNameMatch.length===1 ) {
				fileName = fileNameMatch[ 0 ].replaceAll( /["']/gi, '' );
			}
		}
		link.setAttribute( 'download', fileName );
		document.body.appendChild( link );
		link.click();
		link.remove();
	}

	url = function( url ) {
		return CitizenConnectApiService.urlBuild( url )
	}

}

const instance = new CitizenConnectApiService()

Object.freeze( instance );

export default instance;
