import { isEqual } from 'lodash';

const getDefaultState = () => {
	return {
		breadcrumbs:[
			{
				text:     'Citizen Connect',
				disabled: false,
				exact:    true,
				to:       {
					name: 'citizen.dashboard'
				},
			}
		]
	}
}

const state = getDefaultState()

const getters = {
	breadcrumbs:function ( state ) {
		return state.breadcrumbs;
	}
}


const actions = {
	reset:function( { commit } ) {
		commit( 'reset' )
	},

	new:function( { commit }, breadcrumbs ) {
		commit( 'new', breadcrumbs )
	},

	push:function( { commit }, breadcrumbs ) {
		commit( 'push', breadcrumbs )
	},

	pop:function( { commit }, breadcrumbs ) {
		commit( 'pop', breadcrumbs )
	},
}

const mutations = {

	reset:function ( state ) {
		Object.assign( state, getDefaultState() )
	},

	new: function ( state, breadcrumbs ) {
		if( !Array.isArray( breadcrumbs )) {
			breadcrumbs = [ breadcrumbs ];
		}
		Object.assign( state, getDefaultState() );
		state.breadcrumbs = state.breadcrumbs.concat( breadcrumbs );
	},

	push: function ( state, breadcrumbs ) {
		if( !Array.isArray( breadcrumbs )) {
			breadcrumbs = [ breadcrumbs ];
		}

		stateLoop:
		for( let i in state.breadcrumbs ) {
			for( let j in breadcrumbs ) {
				if( breadcrumbs[j].to.name===state.breadcrumbs[i].to.name && isEqual(breadcrumbs[j].to.params, state.breadcrumbs[i].to.params)) {
					state.breadcrumbs.splice( i );
					break stateLoop;
				}
			}
		}
		state.breadcrumbs = state.breadcrumbs.concat( breadcrumbs );
	},

	pop: function ( state ) {
		state.breadcrumbs.pop();
	}

}


export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
