import { standardizeParcelId }  from '@/services/citizen/citizenHelper';
import citizenRouterViewWrapper from '@/views/citizen/_shared/citizenRouterViewWrapper';

const routes = [
	{
		path: '/citizen',
		component: citizenRouterViewWrapper,
		props: true,
		meta: {
			citizenAuth: {
				required: false,
				roles: []
			}
		},
		children: [
			{
				path:      '',
				name:      'citizen.dashboard',
				component: () => import(/* webpackChunkName: "citizen.dashboard" */ '@/views/citizen/dashboard'),
				props:     true,
				meta:      {
					citizenAuth: {
						required: true,
						roles:    []
					}
				}
			},

			//AUTH
			{
				path:      'auth/sign-in',
				name:      'citizen.auth.signIn',
				component: () => import(/* webpackChunkName: "citizen.auth.signIn" */ '@/views/citizen/auth/signIn'),
				props:     ( route ) => ( {
					code:               route.query.code,
					state:              route.query.state,
					showAccountCreated: route.params.showAccountCreated,
					verifyEmailAddress: route.params.verifyEmailAddress
				} ),
				meta:      {
					citizenAuth: {
						required: false,
						roles:    []
					}
				}
			},
			{
				path: 'auth/sign-out',
				name: 'citizen.auth.signOut',
				component: () => import(/* webpackChunkName: "citizen.auth.signOut" */ '@/views/citizen/auth/signOut.vue'),
				props: true,
				meta: {
					citizenAuth: {
						required: false,
						roles: []
					}
				}
			},
			{
				path:      'auth/forgot-password',
				name:      'citizen.auth.forgotPassword',
				component: () => import(/* webpackChunkName: "citizen.auth.forgotPassword" */ '@/views/citizen/auth/forgotPassword.vue'),
				props:     true,
				meta:      {
					citizenAuth: {
						required: false,
						roles:    []
					}
				}
			},
			{
				path:      'auth/create-account',
				name:      'citizen.auth.createAccount',
				component: () => import(/* webpackChunkName: "citizen.auth.createAccount" */ '@/views/citizen/auth/createAccount.vue'),
				props:     true,
				meta:      {
					citizenAuth: {
						required: false,
						roles:    []
					}
				}
			},
			{
				path:      'auth/error',
				name:      'citizen.auth.error',
				component: () => import(/* webpackChunkName: "citizen.auth.error" */ '@/views/citizen/auth/error.vue'),
				props:     ( route ) => ( { code: route.query.code, message: route.query.message } ),
				meta:      {
					citizenAuth: {
						required: false,
						roles:    []
					}
				}
			},

			//VERIFICATION URLs
			{
				path:      'verify/changeEmail/:memberId/:changeRequestId/:requestVerificationCode',
				name:      'citizen.verify.changeEmail',
				component: () => import(/* webpackChunkName: "citizen.verify.changeEmail" */ '@/views/citizen/verify/page.changeEmail.vue'),
				props:     true,
				meta:      {
					citizenAuth: {
						required: false,
						roles:    []
					}
				}
			},
			{
				path:      'verify/account/:memberId/:verificationCode',
				name:      'citizen.verify.account',
				component: () => import(/* webpackChunkName: "citizen.verify.account" */ '@/views/citizen/verify/page.verifyAccount.vue'),
				props:     true,
				meta:      {
					citizenAuth: {
						required: false,
						roles:    []
					}
				}
			},

			//ADMIN
			{
				path:      'admin/status',
				name:      'citizen.admin.status',
				component: () => import(/* webpackChunkName: "citizen.admin" */ '@/views/citizen/admin/page.adminStatus.vue'),
				props:     false,
				meta:      {
					citizenAuth: {
						required: true,
						roles:    [ 'permission_manage_status' ]
					}
				}
			},
			{
				path:      'admin/member',
				name:      'citizen.admin.member',
				component: () => import(/* webpackChunkName: "citizen.admin" */ '@/views/citizen/admin/page.adminMember.vue'),
				props: route => ({account: route.query.account ?? '', email:route.query.email ?? '', id:route.query.id ?? null }),
				meta:      {
					citizenAuth: {
						required: true,
						roles:    [ 'permission_impersonate' ]
					}
				}
			},

			//ACCOUNT SEARCH
			{
				path:      'search',
				component: citizenRouterViewWrapper,
				props:     false,
				meta:      {
					citizenAuth: {
						required: true,
						roles:    []
					}
				},
				children: [
					{
						path:      '',
						name:      'citizen.search',
						component: () => import(/* webpackChunkName: "citizen.search" */ '@/views/citizen/search/page.taxsearch.vue'),
						props:     false,
						meta:      {
							citizenAuth: {
								required: true,
								roles:    []
							}
						}
					},
					{
						path:      'tax',
						name:      'citizen.search.tax',
						component: () => import(/* webpackChunkName: "citizen.search" */ '@/views/citizen/search/page.taxsearch.vue'),
						props:     false,
						meta:      {
							citizenAuth: {
								required: true,
								roles:    []
							}
						}
					},
					{
						path:      'utilities',
						name:      'citizen.search.utility',
						component: () => import(/* webpackChunkName: "citizen.search" */ '@/views/citizen/search/page.utilitiessearch.vue'),
						props:     false,
						meta:      {
							citizenAuth: {
								required: true,
								roles:    []
							}
						}
					}
				]
			},

			//TAX
			{
				path: 'tax',
				name: 'citizen.tax.overview',
				component: () => import(/* webpackChunkName: "citizen.tax" */ '@/views/citizen/tax/page.taxOverview.vue'),
				props: false,
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},
			//Tax Open Accounts
			{
				path:      'tax/open-accounts',
				name:      'citizen.tax.openAccounts',
				component: () => import(/* webpackChunkName: "citizen.taxOpenAccounts" */ '@/views/citizen/tax/page.taxOpenAccounts.vue'),
				props:     false,
				meta:      {
					citizenAuth: {
						required: true,
						roles:    [ 'taxingservice' ]
					}
				}
			},
			{
				path: 'tax/:accountNumber',
				name: 'citizen.tax.detail',
				component: () => import(/* webpackChunkName: "citizen.tax" */ '@/views/citizen/tax/page.taxDetail.vue'),
				props: route => ({accountNumber:standardizeParcelId( route.params.accountNumber ), search:route.query.search==='true' || route.query.search==1 }),
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},

			//UTILITY
			{
				path: 'utility',
				name: 'citizen.utility.overview',
				component: () => import(/* webpackChunkName: "citizen.utility" */ '@/views/citizen/utility/page.utilityOverview.vue'),
				props: false,
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},
			{
				path: 'utility/:accountNumber',
				name: 'citizen.utility.detail',
				component: () => import(/* webpackChunkName: "citizen.utility" */ '@/views/citizen/utility/page.utilityDetail.vue'),
				props: route => ({ accountNumber:route.params.accountNumber }),
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},

			//Landfill RESIDENTIAL
			{
				path: 'trash',
				name: 'citizen.landfillResidential.overview',
				component: () => import(/* webpackChunkName: "citizen.landfillResidential" */ '@/views/citizen/landfillResidential/page.landfillResidentialOverview.vue'),
				props: false,
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},
			{
				path: 'trash/:customerId',
				name: 'citizen.landfillResidential.detail',
				component: () => import(/* webpackChunkName: "citizen.landfillResidential" */ '@/views/citizen/landfillResidential/page.landfillResidentialDetail.vue'),
				props: route => ({ customerId:route.params.customerId+'' }),
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},

			//Landfill COMMERCIAL
			{
				path: 'landfill-commercial/request-access/:type',
				name: 'citizen.landfillCommercial.requestAccess',
				component: () => import(/* webpackChunkName: "citizen.landfillCommercial" */ '@/views/citizen/landfillCommercial/page.landfillCommercialRequestAccess.vue'),
				props: route => ({ type:route.params.type }),
				meta: {
					citizenAuth: {
						required: true,
						roles: [ ]
					}
				}
			},
			{
				path: 'landfill-commercial/:type',
				name: 'citizen.landfillCommercial.overview',
				component: () => import(/* webpackChunkName: "citizen.landfillCommercial" */ '@/views/citizen/landfillCommercial/page.landfillCommercialOverview.vue'),
				props: route => ({ type:route.params.type }),
				meta: {
					citizenAuth: {
						required: true,
						roles: [ 'wasteworks' ]
					}
				}
			},
			{
				path: 'landfill-commercial/:type/:customerId',
				name: 'citizen.landfillCommercial.detail',
				component: () => import(/* webpackChunkName: "citizen.landfillCommercial" */ '@/views/citizen/landfillCommercial/page.landfillCommercialDetail.vue'),
				props: route => ({ customerId:route.params.customerId+'', type:route.params.type }),
				meta: {
					citizenAuth: {
						required: true,
						roles: [ 'wasteworks' ]
					}
				}
			},


			//ATV
			{
				path: 'atv',
				name: 'citizen.atv.overview',
				component: () => import(/* webpackChunkName: "citizen.atv" */ '@/views/citizen/atv/page.atvOverview.vue'),
				props: false,
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},
			{
				path: 'atv/:customerId/:editType?/:vehicleId?',
				name: 'citizen.atv.detail',
				component: () => import(/* webpackChunkName: "citizen.atv" */ '@/views/citizen/atv/page.atvDetail.vue'),
				props: route => ({ customerId:route.params.customerId+'', editType:route.params.editType, vehicleId:route.params.vehicleId!==null ? route.params.vehicleId+'' : null }),
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},


			//PERMIT
			{
				path:      'permit',
				name:      'citizen.permit.overview',
				component: () => import(/* webpackChunkName: "citizen.permit" */ '@/views/citizen/permit/page.permitOverview.vue'),
				props:     false,
				meta:      {
					citizenAuth: {
						required: true,
						roles:    []
					}
				}
			},
			{
				path:      'permit/apply/:projectRequestId?',
				component: () => import(/* webpackChunkName: "citizen.permit.new" */ '@/views/citizen/permit/page.permitApply.vue'),
				props:     route => ( { projectRequestId: route.params.projectRequestId ? route.params.projectRequestId : 'new' } ),
				meta:      {
					citizenAuth: {
						required: true,
						roles:    []
					}
				},
				children: [
					{
						path: '',
						name:      'citizen.permit.apply',
						component: () => import(/* webpackChunkName: "citizen.permit.new" */ '@/views/citizen/permit/requestDetailViews/requestDetail.dashboard.vue'),
						props:     route => ( { projectRequestId: route.params.projectRequestId ? route.params.projectRequestId : 'new' } ),
						meta: {
							citizenAuth: {
								required: true,
								roles: []
							}
						}
					}
				]
			},
			{
				path:      'permit/:projectId',
				component: () => import(/* webpackChunkName: "citizen.permit" */ '@/views/citizen/permit/page.permitDetail.vue'),
				props:     route => ( { projectId: route.params.projectId } ),
				meta:      {
					citizenAuth: {
						required: true,
						roles:    []
					}
				},
				children: [
					{
						path: '',
						name: 'citizen.permit.detail',
						component: () => import(/* webpackChunkName: "citizen.permit" */ '@/views/citizen/permit/detailViews/permitDetail.dashboard.vue'),
						props: (route)=>({ projectId:route.params.projectId }),
						meta: {
							citizenAuth: {
								required: true,
								roles: []
							}
						}
					},
					{
						path: ':permitType/:permitId',
						name: 'citizen.permit.detail.permit',
						component: () => import(/* webpackChunkName: "citizen.permit" */ '@/views/citizen/permit/detailViews/permitDetail.permit.vue'),
						props: (route)=>({ projectId:route.params.projectId, permitType:route.params.permitType, permitId:route.params.permitId }),
						meta: {
							citizenAuth: {
								required: true,
								roles: []
							}
						}
					},
					{
						path: ':permitType/:permitId/inspections/:inspectionGroupId/:inspectionId',
						name: 'citizen.permit.detail.inspection',
						component: () => import(/* webpackChunkName: "citizen.permit" */ '@/views/citizen/permit/detailViews/permitDetail.inspection.vue'),
						props: (route)=>({ projectId:route.params.projectId, permitType:route.params.permitType, permitId:route.params.permitId, inspectionGroupId:route.params.inspectionGroupId, inspectionId:route.params.inspectionId }),
						meta: {
							citizenAuth: {
								required: true,
								roles: []
							}
						}
					},

				]
			},

			//ACCOUNT
			{
				path: 'account',
				name: 'citizen.account',
				component: () => import(/* webpackChunkName: "citizen.account" */ '@/views/citizen/account/page.account.vue'),
				props: true,
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},
			{
				path: 'account/historical-transactions',
				name: 'citizen.account.historicalTransactions',
				component: () => import(/* webpackChunkName: "citizen.account" */ '@/views/citizen/account/page.account.historicalTransactions.vue'),
				props: true,
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},
			{
				path: 'account/scheduled-transactions',
				name: 'citizen.account.scheduledTransactions',
				component: () => import(/* webpackChunkName: "citizen.account" */ '@/views/citizen/account/page.account.scheduledTransactions.vue'),
				props: true,
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},
			{
				path: 'account/autopay',
				name: 'citizen.account.autopay',
				component: () => import(/* webpackChunkName: "citizen.account" */ '@/views/citizen/account/page.account.autopay.vue'),
				props: true,
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},
			{
				path: 'account/saved-payment-methods',
				name: 'citizen.account.paymentMethods',
				component: () => import(/* webpackChunkName: "citizen.account" */ '@/views/citizen/account/page.account.paymentMethods.vue'),
				props: true,
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},
			{
				path: 'account/change-password',
				name: 'citizen.account.changePassword',
				component: () => import(/* webpackChunkName: "citizen.account" */ '@/views/citizen/account/page.account.password.vue'),
				props: true,
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},
			{
				path: 'account/change-email',
				name: 'citizen.account.changeEmail',
				component: () => import(/* webpackChunkName: "citizen.account" */ '@/views/citizen/account/page.account.email.vue'),
				props: true,
				meta: {
					citizenAuth: {
						required: true,
						roles: []
					}
				}
			},

			//REVERSE 911
			{
				path:      'notifications-emergency-alerts',
				name:      'citizen.reverse911',
				component: () => import(/* webpackChunkName: "citizen.reverse911" */ '@/views/citizen/reverse911/page.reverse911.vue'),
				props:     false,
				meta:      {
					citizenAuth: {
						required: true,
						roles:    []
					}
				}
			},

			//PAYMENT
			{
				path:      'payment',
				name:      'citizen.payment',
				component: () => import(/* webpackChunkName: "citizen.payment" */ '@/views/citizen/payment.vue'),
				props:     true,
				meta:      {
					citizenAuth: {
						required: true,
						roles:    []
					}
				}
			},
			{
				path:      'transaction/:transactionUiId',
				name:      'citizen.transaction',
				component: () => import(/* webpackChunkName: "citizen.payment" */ '@/views/citizen/transaction.vue'),
				props:     true,
				meta:      {
					citizenAuth: {
						required: true,
						roles:    []
					}
				}
			},
			{
				path:      'feedback',
				name:      'citizen.feedback',
				component: () => import(/* webpackChunkName: "citizen.feedback" */ '@/views/citizen/feedback.vue'),
				props:     true,
				meta:      {
					citizenAuth: {
						required: true,
						roles:    []
					}
				}
			},


			{
				path:      '*',
				name:      'citizen.generic.error',
				component: () => import(/* webpackChunkName: "citizen.error" */ '@/views/errorPage.vue'),
				meta:      {
					citizenAuth: {
						required: false,
						roles:    []
					}
				}
			},
		]
	},

	{
		path:      '*',
		name:      'generic.error',
		component: () => import(/* webpackChunkName: "error" */ '@/views/errorPage.vue'),
		meta:      {
			citizenAuth: {
				required: false,
				roles:    []
			}
		}
	}

]

export default routes;
