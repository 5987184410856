export default {
	data: function () {
		return {
			ui: {
				loading: false,
				loadDialog: false,

				adding: false,
				addDialog: false,

				saving: false,
				saveDialog: false,

				editing: false,
				editDialog: false,

				removing: false,
				removeDialog: false,

				maintenance: false,
				maintenanceCode: '',
				maintenanceMessage: '',
				maintenanceGuid: '',

				error: false,
				errorCode: '',
				errorMessage: '',
				errorGuid: '',

				tasks:{}
			}
		}
	},

	methods: {

		ui_setTaskStatus:function( taskKey, taskStatus ) {
			this.$set( this.ui.tasks, taskKey, taskStatus );
		},
		ui_getTaskStatus:function( taskKey ) {
			return this.ui.tasks[taskKey];
		},

		ui_Reset:function() {
			this.ui.loading= false;
			this.ui.loadDialog = false;

			this.ui.adding = false;
			this.ui.addDialog = false;

			this.ui.saving = false;
			this.ui.saveDialog = false;

			this.ui.editing = false;
			this.ui.editDialog = false;

			this.ui.removing = false;
			this.ui.removeDialog = false;

			this.ui.maintenance = false;
			this.ui.maintenanceCode = '';
			this.ui.maintenanceMessage = '';

			this.ui.error = false;
			this.ui.errorCode = '';
			this.ui.errorMessage = '';
			this.ui.errorGuid = '';
		},

		ui_SetError: function ( e ) {
			this.ui.error = true;
			this.ui.errorCode = String( e.code );
			this.ui.errorMessage = e.message;
			this.ui.errorGuid = e.guid;
		},

		ui_SetMaintenance: function ( e ) {
			this.ui.maintenance = true;
			this.ui.maintenanceCode = String( e.code );
			this.ui.maintenanceMessage = e.message;
			this.ui.maintenanceGuid = e.guid;
		},

		ui_ClearMaintenance: function () {
			this.ui.maintenance = false;
			this.ui.maintenanceCode = '';
			this.ui.maintenanceMessage = '';
			this.ui.maintenanceGuid = '';
		},

		ui_ClearError: function () {
			this.ui.error = false;
			this.ui.errorCode = '';
			this.ui.errorMessage = '';
			this.ui.errorGuid = '';
		}

	}

}