import ApiService, {ApiConfig, ApiError} from '@gcgov/apiservice';

import store      from '@/store/index';

/**
 * Example usage:
 * import BulkEmailApiService from "@/services/BulkEmailApiService"
 * try {
 *     apiResponse = await BulkEmailApiService.get( 'widget/' + widgetId )
 * }
 * catch( e ) {
 *     //console.log(e)
 * }
 */
const config = new ApiConfig(
	process.env.VUE_APP_PERMITS_API_URL,      //base url for the api
	process.env.VUE_APP_PERMITS_API_URL_PARAMS,     //url parameters to be added to every request
	true,                          //api requires authentication
	async () => {                  //method to get and auto refresh the api access token
		let apiAccessToken = await store.dispatch( 'citizenPermit/getAccessToken' );
		if( apiAccessToken==='' ) {
			//console.log( 'empty access token' );
			throw new ApiError( 'Not authenticated', 401 );
		}

		return apiAccessToken
	}
)
class PermitsApiService extends ApiService {
	uploadForProject = async ( projectId, permitId, inspectionId, files) => {
		let url = 'file/upload/' + projectId;
		if( typeof ( permitId )!='undefined' && permitId!=null ) {
			url += '/' + permitId;
		}
		if( typeof ( inspectionId )!='undefined' && inspectionId!=null ) {
			url += '/' + inspectionId;
		}
		return this.upload( url, files )
	}

	uploadForExternal = async ( externalProjectRequestId, files) => {
		return this.upload( 'externalProjectRequestFile/upload/' + externalProjectRequestId, files )
	}


	exchangeCitizenConnectTokenForPermitToken = async ()=> {
		let citizenConnectApiAccessToken = await store.dispatch( 'citizenMember/getAccessToken' );
		if( citizenConnectApiAccessToken==='' ) {
			//console.log( 'empty citizen connect access token' );
			throw new ApiError( 'Not authenticated', 401 );
		}
		let options = {
			headers: {
				'Authorization': 'Bearer ' + citizenConnectApiAccessToken
			}
		}
		return this.get( 'auth/citizenConnect', options, false );
	}
}



export default new PermitsApiService( config )
