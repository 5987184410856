import Vue                      from 'vue';

import * as constants           from '@/constants';
import CitizenApiError          from '@/errors/citizen/CitizenApiError';
import CitizenMaintenanceError  from '@/errors/citizen/CitizenMaintenanceError';
import CitizenConnectApiService from '@/services/citizen/CitizenConnectApiService';

const storeKey = 'citizen.store.landfillResidential';

const getDefaultState = () => {
	let now = new Date();
	return {
		accounts:                 {},
		paymentClient:            null,
		currentFiscalYear:        now.getMonth()<=5 ? now.getFullYear() : now.getFullYear() + 1,
		currentPermitYear:        now.getMonth()<9 ? now.getFullYear() : now.getFullYear() + 1,
		runningGetMemberAccounts: false
	}
}

const state = getDefaultState()

const getters = {

	currentFiscalYear:function(state) {
		return state.currentFiscalYear;
	},

	accounts:function(state){
		return state.accounts;
	},

	memberAccounts: function ( state, getters, rootState, rootGetters ) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_LANDFILL_CODE);
		let memberAccounts = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) ) {
				memberAccounts.push( state.accounts[ accountNumbers[ i ] ] );
			}
		}
		return memberAccounts;
	},

	memberNotices:function(state, getters, rootState, rootGetters) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_LANDFILL_CODE);
		let memberNotices = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) && !state.accounts[ accountNumbers[ i ] ]._meta.loading ) {
				memberNotices = memberNotices.concat( state.accounts[ accountNumbers[ i ] ].overview?.messages?.notices );
			}
		}
		return memberNotices;
	},

	memberWarnings:function(state, getters, rootState, rootGetters) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_LANDFILL_CODE);
		let memberWarnings = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) && !state.accounts[ accountNumbers[ i ] ]._meta.loading ) {
				memberWarnings = memberWarnings.concat( state.accounts[ accountNumbers[ i ] ].overview?.messages?.warnings );
			}
		}
		return memberWarnings;
	},


}

const actions = {

	initialize: async function ( { commit, rootGetters } ) {
		//console.log( 'citizen landfill residential initialize' )
		await commit( 'setModuleStateFromLocalStorage' );
		commit( 'defineAccountShells', rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_LANDFILL_CODE) );
	},

	reset: async function ( {commit} ) {
		//console.log( 'citizen landfill residential reset' )
		await commit( 'reset' );
		if( localStorage.getItem( storeKey ) ) {
			localStorage.removeItem( storeKey );
		}
	},

	getMemberAccounts: async function ( { state, commit, dispatch, rootState, rootGetters } ) {
		if(state.runningGetMemberAccounts) {
			return;
		}

		//set the running state so that we don't double fetch on navigation
		commit('setRunningGetMemberAccounts', true );

		let startingMemberAccountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_LANDFILL_CODE);

		//set loading state
		commit('setAccountLoadingTrue', startingMemberAccountNumbers );
		commit('setAccountFetchingTrue', startingMemberAccountNumbers);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/landfill/accounts/' + rootState.citizenMember.user.userId );
		}
		catch( e ) {
			commit( 'setAccountLoadingFalse', startingMemberAccountNumbers );
			commit( 'setAccountFetchingFalse', startingMemberAccountNumbers );
			commit( 'setRunningGetMemberAccounts', false );
			if( e.code==503 ) {
				throw new CitizenMaintenanceError( e.message, e.code, {}, '7823A677-AAC3-4577-8079-3911115A3D45' );
			}
			throw new CitizenApiError( e.message, e.code, {}, '1D173C10-5A02-4D24-9BB2-93525BFFF5D9' );
		}

		//handle non standard errors
		if( apiResponse==null || apiResponse=='' ) {
			commit( 'setAccountLoadingFalse', startingMemberAccountNumbers );
			commit( 'setAccountFetchingFalse', startingMemberAccountNumbers );
			commit( 'setRunningGetMemberAccounts', false );
			throw new CitizenApiError( 'The server didn\'t return the accounts or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, '985ED2F2-866A-465B-8A81-C16C50892866' );
		}

		//set data
		let updatedMemberAccountNumbers = [];
		for( let i in apiResponse.data.data.accounts ) {
			if(apiResponse.data.data.accounts[ i ].account) {
				updatedMemberAccountNumbers.push( apiResponse.data.data.accounts[ i ].account.customerId );
				commit( 'updateAppendAccount', apiResponse.data.data.accounts[ i ] );
			}
		}
		dispatch('citizenMember/setAccounts', { accounts:updatedMemberAccountNumbers, type:constants.PAYMENT_TYPE_LANDFILL_CODE }, { root:true } );

		commit('setPaymentClient', apiResponse.data.data.payment );

		//set loading state of the accounts we touched
		commit('setAccountLoadingFalse', startingMemberAccountNumbers);
		commit('setAccountFetchingFalse', startingMemberAccountNumbers);

		//end the running state to allow fetching again
		commit('setRunningGetMemberAccounts', false );
	},

	getAccount: async function ( {commit}, customerId ) {
		//shell the account in case it's not saved yet
		commit('defineAccountShells', [ customerId ]);

		//set loading state
		commit('setAccountLoadingTrue', customerId);
		commit('setAccountFetchingTrue', customerId);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/landfill/detail/' + customerId );
		}
		catch( e ) {
			commit('setAccountLoadingFalse', customerId);
			commit('setAccountFetchingFalse', customerId);
			throw new CitizenApiError( e.message, e.code, {}, '3FAC010B-EEDA-4897-AD07-833CAB24BA28' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', customerId);
			commit('setAccountFetchingFalse', customerId);
			throw new CitizenApiError( 'The server didn\'t return the account or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, '7488bd93-3fa6-4f66-917a-6513d3f6820d' );
		}
		else if( apiResponse.data.error && apiResponse.data.status=== 503) {
			commit('setAccountLoadingFalse', customerId);
			commit('setAccountFetchingFalse', customerId);
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'E63AF55A-D882-4B0F-9F47-F6B77956656F' );
		}
		else if( apiResponse.data.error ) {
			commit('setAccountLoadingFalse', customerId);
			commit('setAccountFetchingFalse', customerId);
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'FE8913C6-4C7F-4622-B07D-4193627D252E' );
		}

		//set data
		commit( 'updateAppendAccount', apiResponse.data.data );

		//set loading state
		commit('setAccountLoadingFalse', customerId);
		commit('setAccountFetchingFalse', customerId);
	},

	addAccount: async function ( { commit, dispatch, rootState }, customerId ) {
		//set loading state
		commit('setAccountLoadingTrue', customerId);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.post( 'v2/landfill/add/'+rootState.citizenMember.user.userId+'/'+customerId.trim(), {} );
		}
		catch( e ) {
			commit('setAccountLoadingFalse', customerId);
			throw new CitizenApiError( e.message, e.code, {}, '94E7180C-9B47-48AE-84F8-BCE2560A862E' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', customerId);
			throw new CitizenApiError( 'Failed to add account. Try again.', 404, {}, 'AA0FEB22-156E-4E38-A3A1-EDB8715276F1' );
		}
		else if( apiResponse.data.error && apiResponse.data.status=== 503) {
			commit('setAccountLoadingFalse', customerId);
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'AF74326B-EDF5-44AE-B79B-81BB2B22F249' );
		}
		else if( apiResponse.data.error ) {
			commit('setAccountLoadingFalse', customerId);
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '59B0DBB5-7278-4E1E-AD83-0E3E26DBD684' );
		}

		//add the type to member
		await dispatch( 'citizenMember/addAccount', { type:constants.PAYMENT_TYPE_LANDFILL_CODE, account:apiResponse.data.data.account }, { root:true } );

		commit('defineAccountShells', [ apiResponse.data.data.account ]);

		//get account data
		await dispatch('getAccount', apiResponse.data.data.account);

		//set loading state
		commit('setAccountLoadingFalse', customerId);

		//return the new account
		return apiResponse.data.data.account;

	},

	removeAccount: async function ( { dispatch, commit, rootState }, customerId ) {
		//set removing state
		commit('setAccountRemovingTrue', customerId);

		//tell api to remove account
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.delete( 'v2/landfill/remove/'+rootState.citizenMember.user.userId+'/'+customerId );
		}
		catch( e ) {
			commit('setAccountRemovingFalse', customerId);
			throw new CitizenApiError( e.message, e.code, {}, '6E4174A7-CB8A-4C02-AF82-414FE5FB2157' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountRemovingFalse', customerId);
			throw new CitizenApiError( 'Failed to remove account. Try again.', 404, {}, '209AB67B-E12C-4C1C-84F1-2518D9F656E9' );
		}
		else if( apiResponse.data.error && apiResponse.data.status=== 503) {
			commit('setAccountRemovingFalse', customerId);
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '60ED0961-D97C-40A7-B345-40FB03741FF7' );
		}
		else if( apiResponse.data.error ) {
			commit('setAccountRemovingFalse', customerId);
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '5A334837-AFB5-4F06-8BB9-1FBC451BB635' );
		}

		//remove the type from member store
		await dispatch( 'citizenMember/removeAccount', { type:constants.PAYMENT_TYPE_LANDFILL_CODE, account:apiResponse.data.data.account }, { root:true } );

		//set removing state
		commit('setAccountRemovingFalse', customerId);
	}

}

const mutations = {

	setModuleStateFromLocalStorage: function ( state ) {
		if( localStorage.getItem( storeKey ) ) {
			let now = new Date();
			// Replace the state object with the stored item
			let data = JSON.parse( localStorage.getItem( storeKey ) );
			if( data !== null ) {
				Object.assign( state, data )

				state.currentFiscalYear = now.getMonth()<=5 ? now.getFullYear() : now.getFullYear() + 1
				state.currentPermitYear = now.getMonth()<9 ? now.getFullYear() : now.getFullYear() + 1

				//this is a page refresh so force the accounts to not be loading (in case a refresh started when the account was loading)
				state.runningGetMemberAccounts = false;
				for( let i in state.accounts ) {
					state.accounts[ i ]._meta.fetching = false;
					state.accounts[ i ]._meta.loading  = false;
				}
			}
		}
	},

	reset: function ( state ) {
		Object.assign(state, getDefaultState())
	},

	setRunningGetMemberAccounts:function( state, running ) {
		state.runningGetMemberAccounts = running;
	},

	setAccountLoadingTrue: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.loading = true;
			}
		}
	},
	setAccountLoadingFalse: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.loading = false;
			}
		}
	},

	setAccountFetchingTrue: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.fetching = true;
			}
		}
	},
	setAccountFetchingFalse: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.fetching = false;
			}
		}
	},

	setAccountRemovingTrue: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.removing = true;
			}
		}
	},
	setAccountRemovingFalse: function ( state, customerIds ) {
		if(!Array.isArray(customerIds)) {
			customerIds = [ customerIds ];
		}
		for( let i in customerIds ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, customerIds[i] ) ) {
				state.accounts[ customerIds[i] ]._meta.removing = false;
			}
		}
	},

	setPaymentClient:function( state, paymentClient) {
		state.paymentClient = paymentClient;
	},

	updateAppendAccount: function ( state, newAccount ) {
		if( Object.prototype.hasOwnProperty.call(state.accounts, newAccount.account.customerId)) {
			Object.assign(state.accounts[ newAccount.account.customerId ], newAccount)
		}
		else {
			Vue.set(state.accounts, newAccount.account.customerId, newAccount)
		}
	},

	defineAccountShells: function ( state, customerIds ) {
		let now = new Date();
		for( let i in customerIds) {
			let customerId = customerIds[i];
			if( !Object.prototype.hasOwnProperty.call(state.accounts, customerId)) {
				let accountShell = {
					account:{
						customerId:customerId
					},
					customer:{},
					permits:[],
					overview:{
						status:-1
					},
					_meta:{
						loading:true,
						removing:false,
						fetched:null,
						fetching:false,
						year:{
							current:now.getMonth()<10 ? now.getFullYear() : now.getFullYear()+1,
							landfill:now.getMonth()<10 ? now.getFullYear()-1 : now.getFullYear(),
							next:now.getFullYear()+1
						}
					}
				};
				Vue.set(state.accounts, customerId, accountShell)
			}
		}

	},


}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
