import Vue                      from 'vue';

import * as constants           from '@/constants';
import CitizenApiError          from '@/errors/citizen/CitizenApiError';
import CitizenMaintenanceError  from '@/errors/citizen/CitizenMaintenanceError';
import CitizenConnectApiService from '@/services/citizen/CitizenConnectApiService';

const storeKey = 'citizen.store.utility';

const getDefaultState = () => {
	let now = new Date();
	return {
		accounts:                 {},
		paymentClient:            null,
		currentFiscalYear:        now.getMonth()<=5 ? now.getFullYear() : now.getFullYear() + 1,
		runningGetMemberAccounts: false
	}
}

const state = getDefaultState()

const getters = {

	currentFiscalYear:function(state) {
		return state.currentFiscalYear;
	},

	accounts:function(state){
		return state.accounts;
	},

	memberAccounts: function ( state, getters, rootState, rootGetters ) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_UTILITY_CODE);
		let memberAccounts = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) ) {
				memberAccounts.push( state.accounts[ accountNumbers[ i ] ] );
			}
		}
		return memberAccounts;
	},

	memberNotices:function(state, getters, rootState, rootGetters) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_UTILITY_CODE);
		let memberNotices = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) && !state.accounts[ accountNumbers[ i ] ]._meta.loading ) {
				memberNotices = memberNotices.concat( state.accounts[ accountNumbers[ i ] ].overview?.messages?.notices );
			}
		}
		return memberNotices;
	},

	memberWarnings:function(state, getters, rootState, rootGetters) {
		let accountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_UTILITY_CODE);
		let memberWarnings = [];
		for( let i in accountNumbers) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) && !state.accounts[ accountNumbers[ i ] ]._meta.loading ) {
				memberWarnings = memberWarnings.concat( state.accounts[ accountNumbers[ i ] ].overview?.messages?.warnings );
			}
		}
		return memberWarnings;
	},


}

const actions = {

	initialize: async function ( { commit, rootGetters } ) {
		//console.log( 'citizen utility initialize' )
		await commit( 'setModuleStateFromLocalStorage' );
		commit( 'defineAccountShells', rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_UTILITY_CODE) );
	},

	reset: async function ( {commit} ) {
		//console.log( 'citizen utility reset' )
		await commit( 'reset' );
		if( localStorage.getItem( storeKey ) ) {
			localStorage.removeItem( storeKey );
		}
	},

	getMemberAccounts: async function ( { state, commit, dispatch, rootState, rootGetters } ) {
		if(state.runningGetMemberAccounts) {
			return;
		}

		//set the running state so that we don't double fetch on navigation
		commit('setRunningGetMemberAccounts', true );

		let startingMemberAccountNumbers = rootGetters["citizenMember/accountNumbers"](constants.PAYMENT_TYPE_UTILITY_CODE);

		//set loading state
		commit('setAccountLoadingTrue', startingMemberAccountNumbers );
		commit('setAccountFetchingTrue', startingMemberAccountNumbers);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v3/public_utilities/accounts/' + rootState.citizenMember.user.userId );
		}
		catch( e ) {
			commit( 'setAccountLoadingFalse', startingMemberAccountNumbers );
			commit( 'setAccountFetchingFalse', startingMemberAccountNumbers );
			commit( 'setRunningGetMemberAccounts', false );
			if( e.code==503 ) {
				throw new CitizenMaintenanceError( e.message, e.code, {}, '7289b251-5c9d-40b6-bc8f-955eee1e4cba' );
			}
			throw new CitizenApiError( e.message, e.code, {}, '7bb019da-7d17-4986-93d7-646f3d951087' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', startingMemberAccountNumbers);
			commit('setAccountFetchingFalse', startingMemberAccountNumbers);
			commit('setRunningGetMemberAccounts', false );
			throw new CitizenApiError( 'The server didn\'t return the accounts or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, 'abffefa6-5bbd-419c-97a9-9b3952674588' );
		}

		//set data
		let updatedMemberAccountNumbers = [];
		for( let i in apiResponse.data.data.accounts ) {
			updatedMemberAccountNumbers.push( apiResponse.data.data.accounts[i].account.accountNumber );
			commit( 'updateAppendAccount', apiResponse.data.data.accounts[i] );
		}
		dispatch('citizenMember/setAccounts', { accounts:updatedMemberAccountNumbers, type:constants.PAYMENT_TYPE_UTILITY_CODE }, { root:true } );

		commit('setPaymentClient', apiResponse.data.data.payment );

		//set loading state of the accounts we touched
		commit('setAccountLoadingFalse', startingMemberAccountNumbers);
		commit('setAccountFetchingFalse', startingMemberAccountNumbers);

		//end the running state to allow fetching again
		commit('setRunningGetMemberAccounts', false );
	},

	getAccount: async function ( {commit}, accountNumber ) {
		//shell the account in case it's not saved yet
		commit('defineAccountShells', [ accountNumber ]);

		//set loading state
		commit('setAccountLoadingTrue', accountNumber);
		commit('setAccountFetchingTrue', accountNumber);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v3/public_utilities/detail/' + accountNumber );
		}
		catch( e ) {
			commit( 'setAccountLoadingFalse', accountNumber );
			commit( 'setAccountFetchingFalse', accountNumber );
			if( e.code==503 ) {
				throw new CitizenMaintenanceError( e.message, e.code, {}, '7bdfeba0-81b9-4445-b53f-d27cbbe86228' );
			}
			throw new CitizenApiError( e.message, e.code, {}, 'b807b113-5ded-45ce-afe5-bc2e79137a51' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', accountNumber);
			commit('setAccountFetchingFalse', accountNumber);
			throw new CitizenApiError( 'The server didn\'t return the account or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, '7488bd93-3fa6-4f66-917a-6513d3f6820d' );
		}

		//set data
		commit( 'updateAppendAccount', apiResponse.data.data );

		//set loading state
		commit('setAccountLoadingFalse', accountNumber);
		commit('setAccountFetchingFalse', accountNumber);
	},

	addAccount: async function ( { commit, dispatch, rootState }, accountNumber ) {
		//set loading state
		commit('setAccountLoadingTrue', accountNumber);

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.post( 'v3/public_utilities/add/' + rootState.citizenMember.user.userId + '/' + accountNumber, {} );
		}
		catch( e ) {
			commit('setAccountLoadingFalse', accountNumber);
			throw new CitizenApiError( e.message, e.code, {}, 'af999289-c495-4082-bfc3-ccec94312dd9' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountLoadingFalse', accountNumber);
			throw new CitizenApiError( 'Failed to add account. Try again.', 404, {}, 'e80bce0c-55d8-4c07-823d-c9371a8dc219' );
		}
		else if( apiResponse.data.error && apiResponse.data.status=== 503) {
			commit('setAccountLoadingFalse', accountNumber);
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '827a2ba8-7ccf-4695-b9ce-be3da6a455d7' );
		}
		else if( apiResponse.data.error ) {
			commit('setAccountLoadingFalse', accountNumber);
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'a8881b6f-c079-4c53-b6b6-15ae3ca4e383' );
		}

		//add the type to member
		await dispatch( 'citizenMember/addAccount', { type:constants.PAYMENT_TYPE_UTILITY_CODE, account:apiResponse.data.data.account }, { root:true } );

		commit('defineAccountShells', [ apiResponse.data.data.account ]);

		//get account data
		await dispatch('getAccount', apiResponse.data.data.account);

		//set loading state
		commit('setAccountLoadingFalse', accountNumber);

		//return the new account
		return apiResponse.data.data.account;

	},

	removeAccount: async function ( { dispatch, commit, rootState }, accountNumber ) {
		//set removing state
		commit('setAccountRemovingTrue', accountNumber);

		//tell api to remove account
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.delete( 'v3/public_utilities/remove/' + rootState.citizenMember.user.userId + '/' + accountNumber );
		}
		catch( e ) {
			commit('setAccountRemovingFalse', accountNumber);
			throw new CitizenApiError( e.message, e.code, {}, '35756547-AC80-4620-91DA-2E4CFFEB0F18' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit('setAccountRemovingFalse', accountNumber);
			throw new CitizenApiError( 'Failed to remove account. Try again.', 404, {}, '73E913BA-9C95-4BBA-935E-8F3E20B209CD' );
		}
		else if( apiResponse.data.error && apiResponse.data.status=== 503) {
			commit('setAccountRemovingFalse', accountNumber);
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '7E409482-4353-47A4-B61F-ED0FC72F305D' );
		}
		else if( apiResponse.data.error ) {
			commit('setAccountRemovingFalse', accountNumber);
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '99A920FA-F789-48DE-8061-3854C95A450C' );
		}

		//remove the type from member store
		await dispatch( 'citizenMember/removeAccount', { type:constants.PAYMENT_TYPE_UTILITY_CODE, account:apiResponse.data.data.account }, { root:true } );

		//set removing state
		commit('setAccountRemovingFalse', accountNumber);
	},

	clearAccountFromStore: function( { commit }, parcelId ) {
		commit( 'clearAccountFromStore', parcelId )
	},

	clearAccountsFromStore: function( { commit }, parcelIds ) {
		commit( 'clearAccountsFromStore', parcelIds )
	},

	clearNonMemberAccountsFromStore: function( { commit, rootGetters, state } ) {
		let storeParcelIds     = Object.keys( state.accounts );
		let memberParcelIds    = rootGetters[ "citizenMember/accountNumbers" ]( constants.PAYMENT_TYPE_UTILITY_CODE );
		let nonMemberParcelIds = [];

		for( let i in storeParcelIds ) {
			let parcelId = storeParcelIds[ i ];
			if( !memberParcelIds.includes( parcelId ) ) {
				nonMemberParcelIds.push( parcelId );
			}
		}

		if( nonMemberParcelIds.length>0 ) {
			commit( 'clearAccountsFromStore', nonMemberParcelIds )
		}
	}

}

const mutations = {

	setModuleStateFromLocalStorage: function ( state ) {
		if( localStorage.getItem( storeKey ) ) {
			// Replace the state object with the stored item
			let data = JSON.parse( localStorage.getItem( storeKey ) );
			if( data !== null ) {
				Object.assign( state, data )

				//this is a page refresh so force the accounts to not be loading (in case a refresh started when the account was loading)
				state.runningGetMemberAccounts = false;
				for(let i in state.accounts) {
					state.accounts[i]._meta.fetching = false;
					state.accounts[i]._meta.loading = false;
				}
			}
		}
	},

	clearAccountFromStore: function( state, accountNumber ) {
		if( Object.prototype.hasOwnProperty.call( state.accounts, accountNumber ) ) {
			delete state.accounts[ accountNumber ]
		}
	},

	clearAccountsFromStore: function( state, accountNumbers ) {
		for( let i in accountNumbers ) {
			if( Object.prototype.hasOwnProperty.call( state.accounts, accountNumbers[ i ] ) ) {
				delete state.accounts[ accountNumbers[ i ] ]
			}
		}
	},

	reset: function ( state ) {
		Object.assign(state, getDefaultState())
	},

	setRunningGetMemberAccounts:function( state, running ) {
		state.runningGetMemberAccounts = running;
	},

	setAccountLoadingTrue: function ( state, accountNumbers ) {
		if(!Array.isArray(accountNumbers)) {
			accountNumbers = [ accountNumbers ];
		}
		for( let i in accountNumbers ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) ) {
				state.accounts[ accountNumbers[i] ]._meta.loading = true;
			}
		}
	},
	setAccountLoadingFalse: function ( state, accountNumbers ) {
		if(!Array.isArray(accountNumbers)) {
			accountNumbers = [ accountNumbers ];
		}
		for( let i in accountNumbers ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) ) {
				state.accounts[ accountNumbers[i] ]._meta.loading = false;
			}
		}
	},

	setAccountFetchingTrue: function ( state, accountNumbers ) {
		if(!Array.isArray(accountNumbers)) {
			accountNumbers = [ accountNumbers ];
		}
		for( let i in accountNumbers ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) ) {
				state.accounts[ accountNumbers[i] ]._meta.fetching = true;
			}
		}
	},
	setAccountFetchingFalse: function ( state, accountNumbers ) {
		if(!Array.isArray(accountNumbers)) {
			accountNumbers = [ accountNumbers ];
		}
		for( let i in accountNumbers ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) ) {
				state.accounts[ accountNumbers[i] ]._meta.fetching = false;
			}
		}
	},

	setAccountRemovingTrue: function ( state, accountNumbers ) {
		if(!Array.isArray(accountNumbers)) {
			accountNumbers = [ accountNumbers ];
		}
		for( let i in accountNumbers ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) ) {
				state.accounts[ accountNumbers[i] ]._meta.removing = true;
			}
		}
	},
	setAccountRemovingFalse: function ( state, accountNumbers ) {
		if(!Array.isArray(accountNumbers)) {
			accountNumbers = [ accountNumbers ];
		}
		for( let i in accountNumbers ) {
			if( Object.prototype.hasOwnProperty.call(state.accounts, accountNumbers[i] ) ) {
				state.accounts[ accountNumbers[i] ]._meta.removing = false;
			}
		}
	},

	setPaymentClient:function( state, paymentClient) {
		state.paymentClient = paymentClient;
	},

	updateAppendAccount: function ( state, newAccount ) {
		if( Object.prototype.hasOwnProperty.call(state.accounts, newAccount.account.accountNumber)) {
			Object.assign(state.accounts[ newAccount.account.accountNumber ], newAccount)
		}
		else {
			Vue.set(state.accounts, newAccount.account.accountNumber, newAccount)
		}
	},

	defineAccountShells: function ( state, accountNumbers ) {
		if(!Array.isArray(accountNumbers)) {
			accountNumbers = [ accountNumbers ];
		}
		for( let i in accountNumbers) {
			let accountNumber = accountNumbers[i];
			if( !Object.prototype.hasOwnProperty.call(state.accounts, accountNumber)) {
				let accountShell = {
					account:{
						accountNumber:accountNumber,
						parcelId:'',
						customerId:'',
						serviceAddress:{
							address:'',
							address2:'',
							city:'',
							state:'',
							zip:'',
						},
					},
					overview:{
						status:-1
					},
					_meta:{
						loading:true,
						removing:false,
						fetched:null,
						fetching:false
					}
				};
				Vue.set(state.accounts, accountNumber, accountShell)
			}
		}

	},


}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
