import Vue  from 'vue';
import Vuex from 'vuex';

import citizenAtvStore                         from './citizen/atv.store';
import citizenBreadcrumbStore                  from './citizen/breadcrumbs.store';
import citizenLandfillCommercialRecyclingStore from './citizen/landfillCommercialRecycling.store';
import citizenLandfillCommercialWasteStore     from './citizen/landfillCommercialWaste.store';
import citizenLandfillResidentialStore         from './citizen/landfillResidential.store';
import citizenMemberStore                      from './citizen/member.store';
import citizenPaymentStore                     from './citizen/payment.store';
import citizenPermitStore                      from './citizen/permit.store';
import citizenReverse911Store                  from './citizen/reverse911.store';
import citizenSearchStore                      from './citizen/search.store';
import citizenTaxStore                         from './citizen/tax.store';
import citizenUtilityStore                     from './citizen/utility.store';
import routeStore                              from './route.store';

Vue.use( Vuex )

export default new Vuex.Store( {
	state: {
		snackbars: []
	},
	getters: {

		snackbars: function ( state ) {
			return state.snackbars;
		},

		snackbarTimeout: function ( state ) {
			if( typeof ( state.snackbars[ 0 ] ) != 'undefined' && typeof ( state.snackbars[ 0 ].timeout ) == 'number' ) {
				return state.snackbars[ 0 ].timeout;
			}
			return 4000;
		},
	},
	mutations: {

		sendSnackbar: function ( state, snackbar ) {
			state.snackbars.push( snackbar );
		},

		removeSnackbar: function ( state, id ) {
			let index = state.snackbars.findIndex( item => item.id === id );
			if( index !== -1 ) {
				state.snackbars.splice( index, 1 );
			}
		},

		setStateFromLocalStorage:function(/*state*/) {
			// if ( localStorage.getItem( 'citizen.store.downloadReports' ) ) {
			// 	// Replace the state object with the stored item
			// 	let data = localStorage.getItem( 'citizen.store.downloadReports' );
			// 	if(data!==null) {
			// 		state.downloadReports = data;
			// 	}
			// }
		},


	},
	actions: {

		initialize: async function ({commit}) {
			await commit('setStateFromLocalStorage');
		},

		flushStoreCache:async function() {
			localStorage.removeItem('citizen.store.member')
		},

		sendSnackbar: function ( {commit}, payload ) {
			let snackbar = {
				id: "snackbar_" + crypto.randomUUID(),
				color: payload.color,
				message: payload.message,
			};

			if( 'timeout' in payload ) {
				snackbar.timeout = payload.timeout;
			}

			commit( 'sendSnackbar', snackbar );

			return snackbar

		},

		sendErrorSnackbar: function ( {commit}, e ) {
			let message = '';
			if( 'code' in e && e.code !== 0 && e.code!=='' && e.code!==null ) {
				message += e.code + ': ';
			}
			message += e.message;

			let snackbar = {
				id: "snackbar_" + crypto.randomUUID(),
				color: 'error',
				message: message,
				timeout: 5000
			};

			if( 'timeout' in e ) {
				snackbar.timeout = e.timeout;
			}

			commit( 'sendSnackbar', snackbar );

			return snackbar
		},

		removeSnackbar( {commit}, id ) {
			commit( 'removeSnackbar', id );
		},

	},
	modules: {
		route: routeStore,
		citizenMember: citizenMemberStore,
		citizenPayment: citizenPaymentStore,
		citizenSearch: citizenSearchStore,
		citizenTax: citizenTaxStore,
		citizenUtility: citizenUtilityStore,
		citizenLandfillResidential: citizenLandfillResidentialStore,
		citizenAtv: citizenAtvStore,
		citizenLandfillCommercialWaste: citizenLandfillCommercialWasteStore,
		citizenLandfillCommercialRecycling: citizenLandfillCommercialRecyclingStore,
		citizenReverse911: citizenReverse911Store,
		citizenPermit: citizenPermitStore,
		citizenBreadcrumbs: citizenBreadcrumbStore,
	}
} )
