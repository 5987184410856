import Vue                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       from 'vue';
import Vuetify, { VAlert, VApp, VAppBar, VAppBarTitle, VBreadcrumbs, VBreadcrumbsItem, VBtn, VCard, VCardActions, VCardSubtitle, VCardText, VCardTitle, VCheckbox, VChip, VCol, VContainer, VDataTable, VDialog, VDivider, VFileInput, VFooter, VIcon, VImg, VInput, VItem, VList, VListGroup, VListItem, VListItemAction, VListItemAvatar, VListItemContent, VListItemGroup, VListItemIcon, VListItemSubtitle, VListItemTitle, VMain, VMenu, VProgressLinear, VRadio, VRadioGroup, VRating, VResponsive, VRow, VSelect, VSimpleCheckbox, VSkeletonLoader, VSlideYTransition, VSnackbar, VSpacer, VSubheader, VSystemBar, VTextarea, VTextField, VToolbar, VTooltip, VTreeview } from 'vuetify/lib';

Vue.use(Vuetify, {
	components:{
		VAlert, VApp, VAppBar, VAppBarTitle, VBreadcrumbs, VBreadcrumbsItem, VBtn, VCard, VCardActions, VCardSubtitle, VCardText, VCardTitle, VCheckbox, VChip, VCol, VContainer, VDataTable, VDialog, VDivider, VFileInput, VFooter, VIcon, VImg, VInput, VItem, VList, VListGroup, VListItem, VListItemAction, VListItemAvatar, VListItemContent, VListItemGroup, VListItemIcon, VListItemSubtitle, VListItemTitle, VMain, VMenu, VProgressLinear, VRadio, VRadioGroup, VRating, VResponsive, VRow, VSelect, VSimpleCheckbox, VSkeletonLoader, VSlideYTransition, VSnackbar, VSpacer, VSubheader, VSystemBar, VTextarea, VTextField, VToolbar, VTooltip, VTreeview
	}
})

export default new Vuetify( {
	icons:      {
		iconfont: 'mdiSvg',
	},
	breakpoint: {
		mobileBreakpoint: 'sm', // This is equivalent to a value of 960
	},
} );
