import {ApiError}               from '@gcgov/apiservice';
import jwt_decode               from 'jwt-decode';
import Vue                      from 'vue';

import * as constants           from '@/constants';
import { waitUntil }            from '@/constants';
import CitizenApiError          from '@/errors/citizen/CitizenApiError';
import CitizenMaintenanceError  from '@/errors/citizen/CitizenMaintenanceError';
import CitizenConnectApiService from '@/services/citizen/CitizenConnectApiService';
import PermitsApiService        from '@/services/citizen/PermitsApiService';

const storeKey = 'citizen.store.permit';

const getDefaultState = () => {
	return {
		accessToken: '',
		user:        {},
		projects:    {},

		projectRequests: {},

		paymentClients: {},

		runningGetMemberAccounts: false,

		fetchingBlankPaymentClient: false,
		blankPaymentClient:         null,


		fetchingKeyValueItems:    false,
		keyValueItems:            [],
		keyValueItemsLastFetched: null,

		moduleStatusLastChecked: null
	}
}


const state = getDefaultState()

const getters = {

	projects:        function( state ) {
		return state.projects;
	},
	projectRequests: function( state ) {
		return state.projectRequests;
	},

	memberProjects: function( state, getters, rootState, rootGetters ) {
		let projectKeys = rootGetters[ "citizenMember/accountNumbers" ]( constants.PAYMENT_TYPE_PERMIT_CODE );
		let projects    = [];
		for( let i in projectKeys ) {
			if( Object.prototype.hasOwnProperty.call( state.projects, projectKeys[ i ].projectId ) ) {
				projects.push( state.projects[ projectKeys[ i ].projectId ] );
			}
		}
		return projects;
	},

	memberProjectRequests: function( state, getters, rootState, rootGetters ) {
		let projectRequestKeys = rootGetters[ "citizenMember/accountNumbers" ]( constants.PAYMENT_TYPE_PERMIT_REQUEST_CODE );
		let projectRequests    = [];
		for( let i in projectRequestKeys ) {
			if( Object.prototype.hasOwnProperty.call( state.projectRequests, projectRequestKeys[ i ].projectRequestId ) ) {
				projectRequests.push( state.projectRequests[ projectRequestKeys[ i ].projectRequestId ] );
			}
		}
		return projectRequests;
	},


	keyValueItemCollection: function( state ) {
		let keyValueItemObj = {};
		for( let i = 0; i<state.keyValueItems.length; i++ ) {
			let keyValueItem = state.keyValueItems[ i ];
			if( !Object.prototype.hasOwnProperty.call( keyValueItemObj, keyValueItem.group ) ) {
				keyValueItemObj[ keyValueItem.group ] = [];
			}
			keyValueItemObj[ keyValueItem.group ].push( keyValueItem );
		}
		return keyValueItemObj;
	},

	keyValueItemCollectionAsObject: function( state ) {
		let keyValueItemObj = {};
		for( let i = 0; i<state.keyValueItems.length; i++ ) {
			let keyValueItem = state.keyValueItems[ i ];
			if( !Object.prototype.hasOwnProperty.call( keyValueItemObj, keyValueItem.group ) ) {
				keyValueItemObj[ keyValueItem.group ] = {};
			}
			keyValueItemObj[ keyValueItem.group ][ keyValueItem._id ] = keyValueItem;
		}
		return keyValueItemObj;
	},
}

const actions = {
	initialize: async function( { commit, getters } ) {
		//console.log( 'citizen permit store initialize' )
		await commit( 'setModuleStateFromLocalStorage' );
		await commit( 'defineProjectShells', getters.memberProjects )
	},

	reset: async function( { commit } ) {
		commit( 'reset' )
		if( localStorage.getItem( storeKey + '.accessToken' ) ) {
			localStorage.removeItem( storeKey + '.accessToken' );
		}
		if( localStorage.getItem( storeKey + '.user' ) ) {
			localStorage.removeItem( storeKey + '.user' );
		}
	},

	getAccessToken: async function( { state, commit } ) {
		let getNewToken = state.accessToken==='';

		try {
			let decodedCurrentAccessToken = jwt_decode( state.accessToken );
			if( Date.now()>=( decodedCurrentAccessToken.exp * 1000 ) ) {
				getNewToken = true;
			}
		}
		catch( e ) {
			getNewToken = true;
		}

		if( getNewToken ) {
			try {
				let apiResponse = await PermitsApiService.exchangeCitizenConnectTokenForPermitToken()
				commit( 'setAccessToken', apiResponse.data.access_token )
			}
			catch( e ) {
				//console.log( e )
				throw new ApiError( 'Failed to connect to the permits system', 401, '87B3B696-1995-4BBC-BBDA-574C04481B5E' )
			}
		}

		return state.accessToken;
	},

	defineProjectShells: function( { commit }, projectKeys ) {
		commit( 'defineProjectShells', projectKeys )
	},

	defineProjectRequestShells: function( { commit }, projectKeys ) {
		commit( 'defineProjectRequestShells', projectKeys )
	},

	fetchKeyValueItems: async function( { commit, dispatch }, force = false ) {
		//3600000ms=24 hours
		const hours24 = 3600000;
		const now     = new Date().getTime();
		if( now - state.keyValueItemsLastFetched<hours24 && !force ) {
			return
		}
		commit( 'setFetchingKeyValueItems', true )
		//console.log( 'start fetchingKeyValueItems' )
		try {
			let apiResponse = await PermitsApiService.get( '/keyValueItem' );

			//set the data
			commit( 'setKeyValueItems', apiResponse.data );
		}
		catch( e ) {
			//console.log( e )
			dispatch( 'sendErrorSnackbar', e, { root: true } );
		}
		await
			//console.log( 'finish fetchingKeyValueItems' )
		commit( 'setFetchingKeyValueItems', false )

	},

	getMemberAccounts: async function( { state, commit, dispatch, rootGetters } ) {
		if( state.runningGetMemberAccounts ) {
			return;
		}

		//set the running state so that we don't double fetch on navigation
		commit( 'setRunningGetMemberAccounts', true );

		//update member account list
		await dispatch( 'citizenMember/getAccountNumbers', null, { root: true } );
		let memberProjectKeys        = rootGetters[ "citizenMember/accountNumbers" ]( constants.PAYMENT_TYPE_PERMIT_CODE );
		let memberProjectRequestKeys = rootGetters[ "citizenMember/accountNumbers" ]( constants.PAYMENT_TYPE_PERMIT_REQUEST_CODE );

		//get data from api
		let dispatches = [];
		for( let i in memberProjectKeys ) {
			if( typeof memberProjectKeys[ i ].projectId==='string' ) {
				//wait 100 milliseconds
				await new Promise( r => setTimeout( r, 100 ) );
				dispatches.push( dispatch( 'getProject', {
					projectId:     memberProjectKeys[ i ].projectId,
					projectNumber: memberProjectKeys[ i ].projectNumber
				} ) );
			}
		}
		for( let i in memberProjectRequestKeys ) {
			if( typeof memberProjectRequestKeys[ i ].projectRequestId==='string' ) {
				//wait 100 milliseconds
				await new Promise( r => setTimeout( r, 100 ) );
				dispatches.push( dispatch( 'getProjectRequest', {
					projectRequestId:     memberProjectRequestKeys[ i ].projectRequestId,
					projectRequestNumber: memberProjectRequestKeys[ i ].projectRequestNumber
				} ) );
			}
		}

		try {
			await Promise.all( dispatches ).finally( () => commit( 'setRunningGetMemberAccounts', false ) );
		}
		catch( e ) {
			if( e instanceof CitizenMaintenanceError ) {
				throw e
			}
		}


	},

	getModuleStatus: async function( { state, commit } ) {
		//if we have not checked the status or it has been more than 10 seconds since we last checked
		let now = new Date()
		if( state.moduleStatusLastChecked===null || now.getTime() - state.moduleStatusLastChecked.getTime()>10000 ) {
			commit( 'setModuleStatusLastChecked', now )
			//verify module is enabled
			try {
				await CitizenConnectApiService.get( 'v2/permits/status' );
			}
			catch( e ) {
				commit( 'setModuleStatusLastChecked', null )
				if( e.code==503 ) {
					throw new CitizenMaintenanceError( e.message, e.code, {}, 'F77AABE1-5B82-488F-AE12-5B00CC60EE66' );
				}
				throw new CitizenApiError( e.message, e.code, {}, '028F67BE-7EAF-4A67-B357-6C1043A6884C' );
			}
		}
	},

	getProject: async function( { state, commit, dispatch }, { projectId, projectNumber } ) {
		//shell the account in case it's not saved yet
		commit( 'defineProjectShells', [ { projectId: projectId, projectNumber: projectNumber } ] );

		//set loading state
		commit( 'setProjectLoadingTrue', projectId );
		commit( 'setProjectFetchingTrue', projectId );
		commit( 'setProjectError', { projectId: projectId, error: false, message: '' } )

		//verify module is enabled
		try {
			await dispatch( 'getModuleStatus' )
		}
		catch( e ) {
			commit( 'setProjectLoadingFalse', projectId );
			commit( 'setProjectFetchingFalse', projectId );
			throw e
		}

		//get data from api
		let projectApiResponse = null;
		try {
			projectApiResponse = await PermitsApiService.get( '/project/' + projectId );
		}
		catch( e ) {
			commit( 'setProjectLoadingFalse', projectId );
			commit( 'setProjectFetchingFalse', projectId );
			commit( 'setProjectError', { projectId: projectId, error: true, message: e.message } )
			throw new CitizenApiError( e.message, e.code, {}, '74875EE9-C55D-4456-9524-3587D41D5047' );
		}

		//handle non standard errors
		if( projectApiResponse===null ) {
			commit( 'setProjectLoadingFalse', projectId );
			commit( 'setProjectFetchingFalse', projectId );
			commit( 'setProjectError', { projectId: projectId, error: true, message: 'Failed to load project' } )
			throw new CitizenApiError( 'The server didn\'t return the account or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, '877486F6-F44F-4C4A-B587-3543E636CBC6' );
		}

		//make sure we have a template for blank payments
		if( state.blankPaymentClient===null && !state.fetchingBlankPaymentClient ) {
			await dispatch( 'fetchBlankPaymentClient' );
		}
		else if( state.blankPaymentClient===null && state.fetchingBlankPaymentClient ) {
			await waitUntil( () => state.blankPaymentClient!==null && !state.fetchingBlankPaymentClient )
		}

		try {
			await commit( 'updateAppendPaymentClient', projectApiResponse.data );
		}
		catch( e ) {
			throw new CitizenApiError( e.message + ' for project ' + projectId, e.code, {}, 'EBC3296A-1777-455C-A627-D1B9E04F0DF8' );
		}

		//set project data
		commit( 'updateAppendProject', projectApiResponse.data );

		//set loading state
		commit( 'setProjectLoadingFalse', projectId );
		commit( 'setProjectFetchingFalse', projectId );

	},

	getProjectRequest: async function( { commit, dispatch }, { projectRequestId, projectRequestNumber } ) {
		//shell the account in case it's not saved yet
		commit( 'defineProjectRequestShells', [ {
			projectRequestId:     projectRequestId,
			projectRequestNumber: projectRequestNumber
		} ] );

		//set loading state
		commit( 'setProjectRequestLoadingTrue', projectRequestId );
		commit( 'setProjectRequestFetchingTrue', projectRequestNumber );
		commit( 'setProjectRequestError', { projectRequestId: projectRequestId, error: false, message: '' } )

		//verify module is enabled
		try {
			await dispatch( 'getModuleStatus' )
		}
		catch( e ) {
			commit( 'setProjectRequestLoadingFalse', projectRequestId );
			commit( 'setProjectRequestFetchingFalse', projectRequestId );
			throw e
		}

		//get data from api
		let projectRequestApiResponse = null;
		try {
			projectRequestApiResponse = await PermitsApiService.get( '/externalProjectRequest/' + projectRequestId );
		}
		catch( e ) {
			commit( 'setProjectRequestLoadingFalse', projectRequestId );
			commit( 'setProjectRequestFetchingFalse', projectRequestId );
			commit( 'setProjectRequestError', { projectRequestId: projectRequestId, error: true, message: e.message } )
			throw new CitizenApiError( e.message, e.code, {}, 'F8A51C0A-8CCE-4DC6-82A6-65AF487F9016' );
		}

		//handle non standard errors
		if( projectRequestApiResponse===null ) {
			commit( 'setProjectRequestLoadingFalse', projectRequestId );
			commit( 'setProjectRequestError', {
				projectRequestId: projectRequestId,
				error:            true,
				message:          'Failed to load project request'
			} )
			commit( 'setProjectRequestFetchingFalse', projectRequestId );
			throw new CitizenApiError( 'The server didn\'t return the account or an error. Try signing out and back in. If you keep getting this error, contact support.', 500, {}, '301EF8CE-32CE-446B-975B-B567C3980F61' );
		}

		//convert project requests to projects if they're converted
		let addedAsProject = false
		if( projectRequestApiResponse.data?.status?._id==constants.PERMIT_EXTERNAL_STATUS_ID_CONVERTED ) {
			try {
				//console.log( 'addProject' )
				await dispatch( 'addProject', projectRequestApiResponse.data.projectSubId )
				addedAsProject = true
			}
			catch( e ) {
				//console.log( e )
			}
		}

		//set project data
		if( projectRequestId==='new' ) {
			projectRequestApiResponse.data._forceKey = 'new'
		}
		commit( 'updateAppendProjectRequest', projectRequestApiResponse.data );

		//set loading state
		commit( 'setProjectRequestLoadingFalse', projectRequestId );
		commit( 'setProjectRequestFetchingFalse', projectRequestId );

		if( addedAsProject ) {
			try {
				//console.log( 'removeProjectRequest' )
				await dispatch( 'removeProjectRequest', {
					projectRequestId:     projectRequestId,
					projectRequestNumber: projectRequestNumber
				} )
			}
			catch( e ) {
				//console.log( e )
			}
		}

		return projectRequestApiResponse.data
	},

	addProject: async function( { commit, dispatch, rootState }, projectNumber ) {

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.post( 'v2/permits/add/' + rootState.citizenMember.user.userId + '/' + projectNumber, {} );
		}
		catch( e ) {
			throw new CitizenApiError( e.message, e.code, {}, 'AB18AE0B-DCBD-4506-A512-9D7C96E06D6A' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			throw new CitizenApiError( 'Failed to add account. Try again.', 404, {}, 'A431816C-12E4-4BD8-BF56-C25447B19751' );
		}
		else if( apiResponse.data.error && apiResponse.data.status===503 ) {
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '3426293F-22C2-4A54-AAE0-CBFA6989264C' );
		}
		else if( apiResponse.data.error ) {
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '35002BB2-8B46-49B4-A26B-B82D1853CFB7' );
		}

		//add the type to member
		let projectKey = {
			projectId:     apiResponse.data.data.projectId,
			projectNumber: apiResponse.data.data.projectNumber
		}

		await dispatch( 'citizenMember/addAccount', {
			type:    constants.PAYMENT_TYPE_PERMIT_CODE,
			account: projectKey
		}, { root: true } );

		commit( 'defineProjectShells', [ projectKey ] );

		//get account data
		await dispatch( 'getProject', projectKey );

		//set loading state
		commit( 'setProjectLoadingFalse', projectKey.projectId );

		//return the new account
		return projectKey.projectId;

	},

	addProjectRequest: async function( { commit, dispatch, rootState }, projectRequestNumber ) {

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.post( 'v2/permits/addRequest/' + rootState.citizenMember.user.userId + '/' + projectRequestNumber, {} );
		}
		catch( e ) {
			throw new CitizenApiError( e.message, e.code, {}, '75B75C70-DC72-46C8-9B56-4267A125FAFC' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			throw new CitizenApiError( 'Failed to add project request. Try again.', 404, {}, 'B3F37056-A2B5-473C-ACE5-9FB7C7525F6C' );
		}
		else if( apiResponse.data.error && apiResponse.data.status===503 ) {
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'FE9C2135-130A-4C48-A16C-3420781B43B0' );
		}
		else if( apiResponse.data.error ) {
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '86BF7C0F-16F4-4986-A888-919B1ED7F8CB' );
		}

		//add the type to member
		let projectRequestKey = {
			projectRequestId:     apiResponse.data.data.projectRequestId,
			projectRequestNumber: apiResponse.data.data.projectRequestNumber
		}

		await dispatch( 'citizenMember/addAccount', {
			type:    constants.PAYMENT_TYPE_PERMIT_REQUEST_CODE,
			account: projectRequestKey
		}, { root: true } );

		commit( 'defineProjectRequestShells', [ projectRequestKey ] );

		//get account data
		await dispatch( 'getProjectRequest', projectRequestKey );

		//set loading state
		commit( 'setProjectRequestLoadingFalse', projectRequestKey.projectRequestId );

		//return the new account
		return projectRequestKey.projectId;

	},

	saveProjectRequest: async function( { commit, dispatch, state }, projectRequestId ) {

		let projectRequest = state.projectRequests[ projectRequestId ]

		//get data from api
		let apiResponse = null;
		try {
			apiResponse = await PermitsApiService.post( 'externalProjectRequest/' + projectRequest._id, projectRequest );
			commit( 'updateAppendProjectRequest', apiResponse.data );
		}
		catch( e ) {
			throw new CitizenApiError( e.message, e.code, {}, '894F3706-7736-4D3A-9F9B-D5C39ABC0A43' );
		}


		//link this project request to the member if it's not already
		let isLinkedToMember = await dispatch( 'citizenMember/isMemberAccount', {
			type:          constants.PAYMENT_TYPE_PERMIT_REQUEST_CODE,
			accountNumber: {
				projectRequestNumber: apiResponse.data.subId,
				projectRequestId:     apiResponse.data._id
			}
		}, { root: true } );
		if( !isLinkedToMember ) {
			try {
				dispatch( 'addProjectRequest', apiResponse.data.subId );
			}
			catch( e ) {
				//console.log( e )
			}
		}

		return apiResponse.data
	},

	removeProject: async function( { dispatch, commit, rootState }, projectKey ) {

		let projectId = projectKey.projectId
		//let projectNumber = projectKey.projectNumber

		//set removing state
		commit( 'setProjectRemovingTrue', projectId );

		//tell api to remove account
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.delete( 'v2/permits/remove/' + rootState.citizenMember.user.userId + '/' + projectId );
		}
		catch( e ) {
			commit( 'setProjectRemovingFalse', projectId );
			throw new CitizenApiError( e.message, e.code, {}, 'AAC82324-2935-4E42-9BFC-75AD47334B15' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit( 'setProjectRemovingFalse', projectId );
			throw new CitizenApiError( 'Failed to remove account. Try again.', 404, {}, '39A2069C-5B63-4EA3-8ED4-85D47A17CAB5' );
		}
		else if( apiResponse.data.error && apiResponse.data.status===503 ) {
			commit( 'setProjectRemovingFalse', projectId );
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '292DA57B-49DF-4297-ADB6-E7B30C87D587' );
		}
		else if( apiResponse.data.error ) {
			commit( 'setProjectRemovingFalse', projectId );
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'AC7197B9-38A9-4848-9F37-8E53366E4E7E' );
		}

		//remove the type from member store
		await dispatch( 'citizenMember/removeAccount', {
			type:    constants.PAYMENT_TYPE_PERMIT_CODE,
			account: projectKey
		}, { root: true } );

		//set removing state
		commit( 'setProjectRemovingFalse', projectId );
	},

	removeProjectRequest: async function( { dispatch, commit, rootState }, projectRequestKey ) {

		let projectRequestId = projectRequestKey.projectRequestId
		//let projectNumber = projectKey.projectNumber

		//set removing state
		commit( 'setProjectRequestRemovingTrue', projectRequestId );

		//tell api to remove account
		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.delete( 'v2/permits/removeRequest/' + rootState.citizenMember.user.userId + '/' + projectRequestId );
		}
		catch( e ) {
			commit( 'setProjectRequestRemovingFalse', projectRequestId );
			throw new CitizenApiError( e.message, e.code, {}, '7CCE718A-0FBD-4980-A09F-2CE4133CA54D' );
		}

		//handle non standard errors
		if( apiResponse===null ) {
			commit( 'setProjectRequestRemovingFalse', projectRequestId );
			throw new CitizenApiError( 'Failed to remove account. Try again.', 404, {}, 'A3AE8DED-F50C-4B8D-869F-B6FA7F628FB4' );
		}
		else if( apiResponse.data.error && apiResponse.data.status===503 ) {
			commit( 'setProjectRequestRemovingFalse', projectRequestId );
			throw new CitizenMaintenanceError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, '5641057E-5B27-4AAC-8C5C-6BC516408355' );
		}
		else if( apiResponse.data.error ) {
			commit( 'setProjectRequestRemovingFalse', projectRequestId );
			throw new CitizenApiError( apiResponse.data.message, apiResponse.data.status, apiResponse.data.data, 'B1079170-A6DD-4E8B-8C25-3C0180EB5E14' );
		}

		//remove the type from member store
		await dispatch( 'citizenMember/removeAccount', {
			type:    constants.PAYMENT_TYPE_PERMIT_REQUEST_CODE,
			account: projectRequestKey
		}, { root: true } );

		//set removing state
		commit( 'setProjectRemovingFalse', projectRequestId );
	},

	fetchBlankPaymentClient: async function( { state, commit } ) {
		if( state.fetchingBlankPaymentClient ) {
			return;
		}
		commit( 'setFetchingBlankPaymentClient', true );

		let apiResponse = null;
		try {
			apiResponse                                = await CitizenConnectApiService.get( 'v2/payment/blank_client/permits/true' );
			apiResponse.data.data.paymentForm.holdOnly = false
			await commit( 'setBlankPaymentClient', apiResponse.data.data )
		}
		catch( e ) {
			throw new CitizenApiError( e.message, e.code, {}, 'D4F3F7EF-76E9-4F8D-BAB3-BA1F4A248FD1' );
		}

		commit( 'setFetchingBlankPaymentClient', false );

	},

}

const mutations = {

	setModuleStateFromLocalStorage: function( state ) {
		if( localStorage.getItem( storeKey + '.accessToken' ) ) {
			let data = localStorage.getItem( storeKey + '.accessToken' );
			if( data!==null ) {
				state.accessToken = data
			}
		}
		if( localStorage.getItem( storeKey + '.user' ) ) {
			let data = JSON.parse( localStorage.getItem( storeKey + '.user' ) );
			if( data!==null ) {
				state.user = data
			}
		}
	},

	reset: function( state ) {
		Object.assign( state, getDefaultState() )
	},

	setAccessToken: function( state, accessToken ) {
		state.accessToken = accessToken
		let token         = jwt_decode( accessToken )
		state.user        = token.data
		localStorage.setItem( storeKey + '.accessToken', accessToken );
		localStorage.setItem( storeKey + '.user', JSON.stringify( token.data ) );
	},

	defineProjectShells: function( state, projectKeys ) {
		for( let i in projectKeys ) {
			let projectId     = projectKeys[ i ].projectId;
			let projectNumber = projectKeys[ i ].projectNumber;
			if( !Object.prototype.hasOwnProperty.call( state.paymentClients, projectId ) ) {
				//get payment client
				Vue.set( state.paymentClients, projectId, null )
			}
			if( !Object.prototype.hasOwnProperty.call( state.projects, projectId ) ) {
				let projectShell = {
					_id:        projectId,
					subId:      projectNumber,
					applicants: [],
					_meta:      {
						ui: {
							loading:  true,
							removing: false,
							fetched:  null,
							fetching: false,
						},
					}
				};
				Vue.set( state.projects, projectId, projectShell )
			}
		}

	},

	defineProjectRequestShells: function( state, projectKeys ) {
		for( let i in projectKeys ) {
			let projectRequestId     = projectKeys[ i ].projectRequestId;
			let projectRequestNumber = projectKeys[ i ].projectRequestNumber;
			if( !Object.prototype.hasOwnProperty.call( state.projectRequests, projectRequestId ) ) {
				let projectRequestShell = {
					_id:        projectRequestId,
					subId:      projectRequestNumber,
					status:     { _id: '' },
					applicants: [],
					_meta:      {
						ui: {
							loading:      true,
							removing:     false,
							fetched:      null,
							fetching:     false,
							error:        false,
							errorMessage: '',
						},
					}
				};
				Vue.set( state.projectRequests, projectRequestId, projectRequestShell )
			}
		}

	},

	setRunningGetMemberAccounts: function( state, running ) {
		state.runningGetMemberAccounts = running;
	},
	setModuleStatusLastChecked:  function( state, moduleStatusLastChecked ) {
		state.moduleStatusLastChecked = moduleStatusLastChecked;
	},

	setProjectLoadingTrue:  function( state, projectIds ) {
		if( !Array.isArray( projectIds ) ) {
			projectIds = [ projectIds ];
		}
		for( let i in projectIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projects, projectIds[ i ] ) ) {
				state.projects[ projectIds[ i ] ]._meta.ui.loading = true;
			}
		}
	},
	setProjectLoadingFalse: function( state, projectIds ) {
		if( !Array.isArray( projectIds ) ) {
			projectIds = [ projectIds ];
		}
		for( let i in projectIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projects, projectIds[ i ] ) ) {
				state.projects[ projectIds[ i ] ]._meta.ui.loading = false;
			}
		}
	},

	setProjectRequestLoadingTrue:  function( state, projectRequestIds ) {
		if( !Array.isArray( projectRequestIds ) ) {
			projectRequestIds = [ projectRequestIds ];
		}
		for( let i in projectRequestIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projectRequests, projectRequestIds[ i ] ) ) {
				state.projectRequests[ projectRequestIds[ i ] ]._meta.ui.loading = true;
			}
		}
	},
	setProjectRequestLoadingFalse: function( state, projectRequestIds ) {
		if( !Array.isArray( projectRequestIds ) ) {
			projectRequestIds = [ projectRequestIds ];
		}
		for( let i in projectRequestIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projectRequests, projectRequestIds[ i ] ) ) {
				state.projectRequests[ projectRequestIds[ i ] ]._meta.ui.loading = false;
			}
		}
	},

	setProjectError:        function( state, { projectId, error, message } ) {
		if( !Array.isArray( projectId ) ) {
			projectId = [ projectId ];
		}
		for( let i in projectId ) {
			if( Object.prototype.hasOwnProperty.call( state.projects, projectId[ i ] ) ) {
				state.projects[ projectId[ i ] ]._meta.ui.error         = error;
				state.projects[ projectId[ i ] ]._meta.ui.errorMessages = [ message ];
			}
		}
	},
	setProjectRequestError: function( state, { projectRequestId, error, message } ) {
		if( !Array.isArray( projectRequestId ) ) {
			projectRequestId = [ projectRequestId ];
		}
		for( let i in projectRequestId ) {
			if( Object.prototype.hasOwnProperty.call( state.projectRequests, projectRequestId[ i ] ) ) {
				state.projectRequests[ projectRequestId[ i ] ]._meta.ui.error         = error;
				state.projectRequests[ projectRequestId[ i ] ]._meta.ui.errorMessages = [ message ];
			}
		}
	},

	setProjectFetchingTrue:  function( state, projectIds ) {
		if( !Array.isArray( projectIds ) ) {
			projectIds = [ projectIds ];
		}
		for( let i in projectIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projects, projectIds[ i ] ) ) {
				state.projects[ projectIds[ i ] ]._meta.ui.fetching = true;
			}
		}
	},
	setProjectFetchingFalse: function( state, projectIds ) {
		if( !Array.isArray( projectIds ) ) {
			projectIds = [ projectIds ];
		}
		for( let i in projectIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projects, projectIds[ i ] ) ) {
				state.projects[ projectIds[ i ] ]._meta.ui.fetching = false;
			}
		}
	},

	setProjectRequestFetchingTrue:  function( state, projectRequestIds ) {
		if( !Array.isArray( projectRequestIds ) ) {
			projectRequestIds = [ projectRequestIds ];
		}
		for( let i in projectRequestIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projectRequests, projectRequestIds[ i ] ) ) {
				state.projectRequests[ projectRequestIds[ i ] ]._meta.ui.fetching = true;
			}
		}
	},
	setProjectRequestFetchingFalse: function( state, projectRequestIds ) {
		if( !Array.isArray( projectRequestIds ) ) {
			projectRequestIds = [ projectRequestIds ];
		}
		for( let i in projectRequestIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projectRequests, projectRequestIds[ i ] ) ) {
				state.projectRequests[ projectRequestIds[ i ] ]._meta.ui.fetching = false;
			}
		}
	},

	setProjectRemovingTrue:  function( state, projectIds ) {
		if( !Array.isArray( projectIds ) ) {
			projectIds = [ projectIds ];
		}
		for( let i in projectIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projects, projectIds[ i ] ) ) {
				state.projects[ projectIds[ i ] ]._meta.ui.removing = true;
			}
		}
	},
	setProjectRemovingFalse: function( state, projectIds ) {
		if( !Array.isArray( projectIds ) ) {
			projectIds = [ projectIds ];
		}
		for( let i in projectIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projects, projectIds[ i ] ) ) {
				state.projects[ projectIds[ i ] ]._meta.ui.removing = false;
			}
		}
	},

	setProjectRequestRemovingTrue:  function( state, projectRequestIds ) {
		if( !Array.isArray( projectRequestIds ) ) {
			projectRequestIds = [ projectRequestIds ];
		}
		for( let i in projectRequestIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projectRequests, projectRequestIds[ i ] ) ) {
				state.projectRequests[ projectRequestIds[ i ] ]._meta.ui.removing = true;
			}
		}
	},
	setProjectRequestRemovingFalse: function( state, projectRequestIds ) {
		if( !Array.isArray( projectRequestIds ) ) {
			projectRequestIds = [ projectRequestIds ];
		}
		for( let i in projectRequestIds ) {
			if( Object.prototype.hasOwnProperty.call( state.projectRequests, projectRequestIds[ i ] ) ) {
				state.projectRequests[ projectRequestIds[ i ] ]._meta.ui.removing = false;
			}
		}
	},

	setFetchingBlankPaymentClient: function( state, val ) {
		state.fetchingBlankPaymentClient = val;
	},

	setBlankPaymentClient: function( state, blankPaymentClient ) {
		state.blankPaymentClient = blankPaymentClient;
	},

	updateAppendPaymentClient: function( state, project ) {
		let paymentClient                       = { ...state.blankPaymentClient };
		paymentClient.paymentForm.invoiceNumber = [ ...Array( 10 ) ].map( () => Math.random().toString( 36 )[ 2 ] ).join( '' );
		let amount                              = {
			"id":                  [ ...Array( 10 ) ].map( () => Math.random().toString( 36 )[ 2 ] ).join( '' ),
			"account":             project.subId,
			"label":               'Balance Due',
			"value":               project._id,
			"base":                0,
			"discountableBase":    0,
			"convenienceableBase": 0,
			"credit":              { "displayValue": "0.00", "total": 0, "discountAmount": 0, "convenienceAmount": 0 },
			"echeck":              { "displayValue": "0.00", "total": 0, "discountAmount": 0, "convenienceAmount": 0 },
			"bills":               [],
			"checked":             true,
			"disabled":            false,
			"useTextbox":          false,
			"multi":               false,
			"textbox":             { "name": "", "id": "", "value": "" },
			"_meta":               []
		}
		for( let i in project.transactions ) {
			if( project.transactions[ i ].type=='fee' && project.transactions[ i ].balance>0 ) {
				amount.base += project.transactions[ i ].balance
				amount.bills.push( {
					number:     project.transactions[ i ]._id,
					amount:     project.transactions[ i ].balance,
					year:       ( new Date() ).getFullYear(),
					arCategory: 0
				} )
			}
		}
		amount.credit.displayValue = amount.base.toFixed( 2 );
		amount.credit.total        = amount.base;
		amount.echeck.displayValue = amount.base.toFixed( 2 );
		amount.echeck.total        = amount.base;

		paymentClient.amounts                              = [ amount ]
		paymentClient.max                                  = amount
		paymentClient.paymentForm.amounts                  = {}
		paymentClient.paymentForm.amounts[ project.subId ] = amount

		Vue.set( state.paymentClients, project._id, paymentClient )

	},

	updateAppendProject: function( state, newProject ) {
		let total = 0;
		for( let i in newProject.transactions ) {
			total += parseFloat( newProject.transactions[ i ].amount );
		}
		newProject.transactionsBalance = total;

		if( Object.prototype.hasOwnProperty.call( state.projects, newProject._id ) ) {
			Object.assign( state.projects[ newProject._id ], newProject )
		}
		else {
			Vue.set( state.projects, newProject._id, newProject )
		}
	},

	updateAppendProjectRequest: function( state, newProjectRequest ) {
		let key = newProjectRequest._id
		if( newProjectRequest._forceKey ) {
			key = newProjectRequest._forceKey
		}

		if( Object.prototype.hasOwnProperty.call( state.projectRequests, key ) ) {
			Object.assign( state.projectRequests[ key ], newProjectRequest )
		}
		else {
			Vue.set( state.projectRequests, key, newProjectRequest )
		}
	},

	updatePermit: function( state, { projectId, permit, permitType } ) {
		for( let i in state.projects[ projectId ].permits[ permitType ] ) {
			if( state.projects[ projectId ].permits[ permitType ][ i ]._id==permit._id ) {
				Vue.set( state.projects[ projectId ].permits[ permitType ], i, permit )
				break
			}
		}
	},

	updateInspectionGroup: function( state, { projectId, permitId, permitType, inspectionGroup } ) {
		for( let permitIndex in state.projects[ projectId ].permits[ permitType ] ) {
			let storePermit = state.projects[ projectId ].permits[ permitType ][ permitIndex ];
			if( storePermit._id==permitId ) {
				for( let inspectionGroupIndex in storePermit.inspectionGroups ) {
					if( storePermit.inspectionGroups[ inspectionGroupIndex ]._id==inspectionGroup._id ) {
						Vue.set( state.projects[ projectId ].permits[ permitType ][ permitIndex ].inspectionGroups, inspectionGroupIndex, inspectionGroup )
						break
					}
				}
			}
		}
	},

	updateInspection: function( state, { projectId, permitId, permitType, inspectionGroupId, inspection } ) {
		for( let permitIndex in state.projects[ projectId ].permits[ permitType ] ) {
			let storePermit = state.projects[ projectId ].permits[ permitType ][ permitIndex ];
			if( storePermit._id==permitId ) {
				for( let inspectionGroupIndex in storePermit.inspectionGroups ) {
					let storeInspectionGroup = storePermit.inspectionGroups[ inspectionGroupIndex ];
					if( storeInspectionGroup._id==inspectionGroupId ) {

						for( let inspectionIndex in storeInspectionGroup.inspections ) {
							let storeInspection = storeInspectionGroup.inspections[ inspectionIndex ];
							if( storeInspection._id==inspection._id ) {
								Vue.set( state.projects[ projectId ].permits[ permitType ][ permitIndex ].inspectionGroups[ inspectionGroupIndex ].inspections, inspectionIndex, inspection )
								break
							}
						}
					}
				}
			}
		}
	},

	setKeyValueItems: ( state, keyValueItems ) => {
		state.keyValueItems            = Object.freeze( keyValueItems )
		state.keyValueItemsLastFetched = new Date().getTime()
	},

	setFetchingKeyValueItems: ( state, fetching ) => {
		state.fetchingKeyValueItems = fetching
	},
}


export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
