<template>
	<v-app id="vue-app">
		<v-progress-linear indeterminate
		                   absolute
		                   v-if="$store.getters['route/routing']"></v-progress-linear>

		<v-snackbar-queue :items="$store.getters.snackbars"
		                  :timeout="$store.getters.snackbarTimeout"
		                  center
		                  :close-button-icon="icons.mdiClose"
		                  top
		                  @remove="$store.dispatch('removeSnackbar', $event)"></v-snackbar-queue>

		<router-view />
	</v-app>
</template>

<script>
import { mdiClose }              from '@mdi/js';
import { VApp, VProgressLinear } from 'vuetify/lib';

export default {
	name:       'App',
	components: {
		VApp,
		VProgressLinear
	},
	metaInfo:   {
		title:         'Garrett County Government',
		titleTemplate: '%s - Garrett County Government',
	},
	data:       () => ( {
		icons: { mdiClose },
	} ),
};
</script>
