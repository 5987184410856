import 'core-js/stable'; //support es5 browsers
import 'regenerator-runtime/runtime'; //support es5 browsers
import '@/styles/main.scss';

import Vue                  from 'vue';
import VueDataObjectPath    from 'vue-data-object-path';
import VueMeta              from 'vue-meta';
import VueTelInputVuetify   from 'vue-tel-input-vuetify/lib';
import VuetifySnackbarQueue from 'vuetify-snackbar-queue';

import App             from '@/App.vue';
import * as constants  from '@/constants';
import formattersMixin from '@/plugins/formattersMixin';
import stateMixin      from '@/plugins/stateMixin';
import vuetify         from '@/plugins/vuetify';
import router          from '@/router';
import store           from '@/store';

Vue.config.productionTip = false


//extend vue
Vue.mixin( stateMixin );
Vue.mixin( formattersMixin );
Vue.prototype.$constants = constants;

//third party plugins
Vue.use( VueMeta );
Vue.use( VuetifySnackbarQueue )
Vue.use( VueDataObjectPath )
Vue.use(VueTelInputVuetify, {
	vuetify,
});

// Subscribe to store updates
let initStore = async () => {
	await store.dispatch( 'initialize' );
	await store.dispatch( 'citizenMember/initialize' );

	let storeToLocalStorage = function( mutation, state ) {
		try {
			// Store parts of the state
			if( mutation.type.startsWith( 'citizenMember' ) ) {
				localStorage.setItem( 'citizen.store.member', JSON.stringify( state.citizenMember ) );
			}
			else if( mutation.type.startsWith( 'citizenSearch' ) ) {
				localStorage.setItem( 'citizen.store.search', JSON.stringify( state.citizenSearch ) );
			}
			else if( mutation.type.startsWith( 'citizenTax' ) ) {
				localStorage.setItem( 'citizen.store.tax', JSON.stringify( state.citizenTax ) );
			}
			else if( mutation.type.startsWith( 'citizenUtility' ) ) {
				localStorage.setItem( 'citizen.store.utility', JSON.stringify( state.citizenUtility ) );
			}
			else if( mutation.type.startsWith( 'citizenLandfillResidential' ) ) {
				localStorage.setItem( 'citizen.store.landfillResidential', JSON.stringify( state.citizenLandfillResidential ) );
			}
			else if( mutation.type.startsWith( 'citizenAtv' ) ) {
				localStorage.setItem( 'citizen.store.atv', JSON.stringify( state.citizenAtv ) );
			}
			else if( mutation.type.startsWith( 'citizenLandfillCommercialWaste' ) ) {
				localStorage.setItem( 'citizen.store.landfillCommercialWaste', JSON.stringify( state.citizenLandfillCommercialWaste ) );
			}
			else if( mutation.type.startsWith( 'citizenLandfillCommercialRecycling' ) ) {
				localStorage.setItem( 'citizen.store.landfillCommercialRecycling', JSON.stringify( state.citizenLandfillCommercialRecycling ) );
			}
		}
		catch( e ) {
			//console.log( 'memory limit hit; resetting localStorage' )
			if( localStorage.getItem( 'citizen.store.tax' ) ) {
				localStorage.removeItem( 'citizen.store.tax' );
			}
			if( localStorage.getItem( 'citizen.store.utility' ) ) {
				localStorage.removeItem( 'citizen.store.utility' );
			}
			if( localStorage.getItem( 'citizen.store.landfillResidential' ) ) {
				localStorage.removeItem( 'citizen.store.landfillResidential' );
			}
			if( localStorage.getItem( 'citizen.store.atv' ) ) {
				localStorage.removeItem( 'citizen.store.atv' );
			}
			if( localStorage.getItem( 'citizen.store.landfillCommercialWaste' ) ) {
				localStorage.removeItem( 'citizen.store.landfillCommercialWaste' );
			}
			if( localStorage.getItem( 'citizen.store.landfillCommercialRecycling' ) ) {
				localStorage.removeItem( 'citizen.store.landfillCommercialRecycling' );
			}
		}
	}

	store.subscribe( ( mutation, state ) => {

		try {
			storeToLocalStorage( mutation, state )
		}
		catch( e ) {
			//console.error( e )
			//console.log( 'Problem setting localStorage on mutation ' + mutation.type )
		}

	} );
};
initStore().then(() => {

//application
	new Vue( {
		router,
		store,
		vuetify,
		render: h => h( App )
	} ).$mount( '#vue-app' )

});
