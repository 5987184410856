export const PAYMENT_TYPE_TAX_CODE  = 'tax';
export const PAYMENT_TYPE_TAX_LABEL = 'Taxes';

export const PAYMENT_TYPE_UTILITY_CODE  = 'utility';
export const PAYMENT_TYPE_UTILITY_LABEL = 'Water and Sewer';

export const PAYMENT_TYPE_LANDFILL_CODE  = 'landfill';
export const PAYMENT_TYPE_LANDFILL_LABEL = 'Trash Permits';

export const PAYMENT_TYPE_ATV_CODE  = 'atv';
export const PAYMENT_TYPE_ATV_LABEL = 'ATV/Snowmobile Permits';

export const PAYMENT_TYPE_PERMIT_CODE  = 'permit';
export const PAYMENT_TYPE_PERMIT_LABEL = 'Permits and Inspections';

export const PAYMENT_TYPE_PERMIT_REQUEST_CODE  = 'permitRequest';
export const PAYMENT_TYPE_PERMIT_REQUEST_LABEL = 'Permits and Inspections';

export const PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_CODE     = 'commercialLandfillWaste';
export const PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_API_CODE = 'scales';
export const PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_LABEL    = 'Commercial Solid Waste';

export const PAYMENT_TYPE_COMMERCIAL_LANDFILL_RECYCLING_CODE     = 'commercialLandfillRecycling';
export const PAYMENT_TYPE_COMMERCIAL_LANDFILL_RECYCLING_API_CODE = 'misc';
export const PAYMENT_TYPE_COMMERCIAL_LANDFILL_RECYCLING_LABEL    = 'Commercial Recycling';

export const CITIZEN_PAGE_ACCOUNT_SETTINGS                = 'Account & Payment Settings'
export const CITIZEN_PAGE_ACCOUNT_HISTORICAL_TRANSACTIONS = 'Online Payment History'
export const CITIZEN_PAGE_ACCOUNT_AUTOPAY                 = 'Autopay Manager'
export const CITIZEN_PAGE_ACCOUNT_SAVED_PAYMENTS          = 'Saved Payment Methods'
export const CITIZEN_PAGE_ACCOUNT_SCHEDULED_PAYMENTS      = 'Scheduled Payments'
export const CITIZEN_PAGE_ACCOUNT_CHANGE_PASSWORD         = 'Change Password'
export const CITIZEN_PAGE_ACCOUNT_CHANGE_EMAIL            = 'Change Email Address'


export const PERMIT_STATUS_TEXT_APPLICATION  = 'Application';
export const PERMIT_STATUS_ID_APPLICATION    = '602584c7f4150000ce006b61';
export const PERMIT_STATUS_ICON_APPLICATION  = 'mdi-pencil';
export const PERMIT_STATUS_COLOR_APPLICATION = 'grey';

export const PERMIT_STATUS_TEXT_SIGNATURES_AND_PAYMENT  = 'Signatures and Payment';
export const PERMIT_STATUS_ID_SIGNATURES_AND_PAYMENT    = '602584c7f4150000ce006b62';
export const PERMIT_STATUS_ICON_SIGNATURES_AND_PAYMENT  = 'mdi-signature-freehand';
export const PERMIT_STATUS_COLOR_SIGNATURES_AND_PAYMENT = 'light-blue';

export const PERMIT_STATUS_TEXT_APPROVED  = 'Approved';
export const PERMIT_STATUS_ID_APPROVED    = '60dcdce9547700001e004457';
export const PERMIT_STATUS_ICON_APPROVED  = 'mdi-check';
export const PERMIT_STATUS_COLOR_APPROVED = 'success';

export const PERMIT_STATUS_TEXT_COMPLETE  = 'Complete';
export const PERMIT_STATUS_ID_COMPLETE    = '602584c7f4150000ce006b64';
export const PERMIT_STATUS_ICON_COMPLETE  = 'mdi-archive-arrow-down';
export const PERMIT_STATUS_COLOR_COMPLETE = 'blue-grey';

export const PERMIT_STATUS_TEXT_INACTIVE  = 'Inactive';
export const PERMIT_STATUS_ID_INACTIVE    = '602584c7f4150000ce006b63';
export const PERMIT_STATUS_ICON_INACTIVE  = 'mdi-minus-circle';
export const PERMIT_STATUS_COLOR_INACTIVE = 'warning';

export const PERMITTYPE_LABEL = {
	'assessment':    'Assessment Data',
	'construction':  'Construction Release',
	'building':      'Building Permit',
	'electrical':    'Electrical Permit',
	'entrance':      'Entrance Permit',
	'fire':          'Fire Protection',
	'floodplain':    'Floodplain Permit',
	'grading':       'Grading Permit',
	'occupancy':     'Use/Occupancy Release',
	'plumbing':      'Plumbing Permit',
	'utility':       'Utility Data',
	'zoning':        'Zoning Permit',
	'timberHarvest': 'Timber Harvest Permit',
}

export const PERMIT_EXTERNAL_STATUS_TEXT_NOT_SUBMITTED = 'Not Submitted';
export const PERMIT_EXTERNAL_STATUS_ID_NOT_SUBMITTED   = '641b5a64995883c43f0ebd7c';
export const PERMIT_EXTERNAL_STATUS_ICON_NOT_SUBMITTED  = 'mdi-pencil';
export const PERMIT_EXTERNAL_STATUS_COLOR_NOT_SUBMITTED = 'grey';

export const PERMIT_EXTERNAL_STATUS_TEXT_SUBMITTED = 'Pending Review';
export const PERMIT_EXTERNAL_STATUS_ID_SUBMITTED   = '641b5a87995883c43f0ebd7e';
export const PERMIT_EXTERNAL_STATUS_ICON_SUBMITTED  = 'mdi-human-greeting-proximity';
export const PERMIT_EXTERNAL_STATUS_COLOR_SUBMITTED = 'success';

export const PERMIT_EXTERNAL_STATUS_TEXT_CONVERTED = 'Converted';
export const PERMIT_EXTERNAL_STATUS_ID_CONVERTED   = '641b5aa5995883c43f0ebd80';
export const PERMIT_EXTERNAL_STATUS_ICON_CONVERTED  = 'mdi-archive-arrow-down';
export const PERMIT_EXTERNAL_STATUS_COLOR_CONVERTED = 'blue-grey';

export const PERMIT_EXTERNAL_STATUS_TEXT_REJECTED = 'Rejected';
export const PERMIT_EXTERNAL_STATUS_ID_REJECTED   = '641b5abb995883c43f0ebd82';
export const PERMIT_EXTERNAL_STATUS_ICON_REJECTED  = 'mdi-minus-circle';
export const PERMIT_EXTERNAL_STATUS_COLOR_REJECTED = 'error';


export const waitUntil = ( condition ) => {
	return new Promise( ( resolve ) => {
		let interval = setInterval( () => {
			if( !condition() ) {
				return
			}

			clearInterval( interval )
			resolve()
		}, 100 )
	} )
}

export const delay = function( time ) {
	return new Promise( resolve => setTimeout( resolve, time ) );
}

export const states = {
	'AL': 'ALABAMA',
	'AK': 'ALASKA',
	'AS': 'AMERICAN SAMOA',
	'AZ': 'ARIZONA',
	'AR': 'ARKANSAS',
	'CA': 'CALIFORNIA',
	'CO': 'COLORADO',
	'CT': 'CONNECTICUT',
	'DE': 'DELAWARE',
	'DC': 'DISTRICT OF COLUMBIA',
	'FL': 'FLORIDA',
	'GA': 'GEORGIA',
	'GU': 'GUAM',
	'HI': 'HAWAII',
	'ID': 'IDAHO',
	'IL': 'ILLINOIS',
	'IN': 'INDIANA',
	'IA': 'IOWA',
	'KS': 'KANSAS',
	'KY': 'KENTUCKY',
	'LA': 'LOUISIANA',
	'ME': 'MAINE',
	'MD': 'MARYLAND',
	'MA': 'MASSACHUSETTS',
	'MI': 'MICHIGAN',
	'MN': 'MINNESOTA',
	'MS': 'MISSISSIPPI',
	'MO': 'MISSOURI',
	'MT': 'MONTANA',
	'NE': 'NEBRASKA',
	'NV': 'NEVADA',
	'NH': 'NEW HAMPSHIRE',
	'NJ': 'NEW JERSEY',
	'NM': 'NEW MEXICO',
	'NY': 'NEW YORK',
	'NC': 'NORTH CAROLINA',
	'ND': 'NORTH DAKOTA',
	'MP': 'NORTHERN MARIANA IS',
	'OH': 'OHIO',
	'OK': 'OKLAHOMA',
	'OR': 'OREGON',
	'PA': 'PENNSYLVANIA',
	'PR': 'PUERTO RICO',
	'RI': 'RHODE ISLAND',
	'SC': 'SOUTH CAROLINA',
	'SD': 'SOUTH DAKOTA',
	'TN': 'TENNESSEE',
	'TX': 'TEXAS',
	'UT': 'UTAH',
	'VT': 'VERMONT',
	'VA': 'VIRGINIA',
	'VI': 'VIRGIN ISLANDS',
	'WA': 'WASHINGTON',
	'WV': 'WEST VIRGINIA',
	'WI': 'WISCONSIN',
	'WY': 'WYOMING',
};