import jwt_decode               from 'jwt-decode';
import { some }                 from 'lodash';

import * as constants           from '@/constants';
import { waitUntil }            from '@/constants';
import AuthError                from '@/errors/AuthError';
import CitizenApiError          from '@/errors/citizen/CitizenApiError';
import CitizenLogicError        from '@/errors/citizen/CitizenLogicError';
import router                   from '@/router';
import CitizenConnectApiService from '@/services/citizen/CitizenConnectApiService';
import PermitsApiService        from '@/services/citizen/PermitsApiService';

const storeKey = 'citizen.store.member';

const getDefaultState = () => {
	return {
		accessToken:             '',
		refreshToken:            '',
		roles:                   [],
		user:                    {},
		isSignedIn:              false,
		fresh:                   false,
		accountNumbers:          {
			tax:                         [],
			landfill:                    [],
			utility:                     [],
			commercialLandfillWaste:     [],
			commercialLandfillRecycling: [],
			atv:                         [],
			permit:                      [],
			permitRequest:               [],
		},
		impersonating:           false,
		_gettingAccessToken:     false,
		_exchangingRefreshToken: false,
	}
}

const state = getDefaultState()

const getters = {

	isSignedIn: function( state ) {
		if( !state.isSignedIn || !state.fresh ) {
			return false;
		}
		return true;
	},

	tryRefreshToken: function( state ) {
		if( state.refreshToken!=='' ) {
			return true;
		}
		return false;
	},

	userId: function( state ) {
		return state.user.userId;
	},

	accountNumbers: ( state ) => ( type ) => {
		return state.accountNumbers[ type ];
	},

	hasRole: ( state ) => ( role ) => {
		return state.roles.includes( role );
	},

}

const actions = {
	initialize: async function( {
		commit, dispatch
	} ) {
		//console.log( 'citizen member initialize' )
		await commit( 'setModuleStateFromLocalStorage' );
		await dispatch( 'initializeDependentStores' );
	},

	initializeDependentStores: async function( { dispatch } ) {
		//console.log( 'citizen member initializeDependentStores' )
		await dispatch( 'citizenSearch/initialize', null, { root: true } );
		await dispatch( 'citizenTax/initialize', null, { root: true } );
		await dispatch( 'citizenUtility/initialize', null, { root: true } );
		await dispatch( 'citizenLandfillResidential/initialize', null, { root: true } );
		await dispatch( 'citizenAtv/initialize', null, { root: true } );
		await dispatch( 'citizenLandfillCommercialWaste/initialize', null, { root: true } );
		await dispatch( 'citizenLandfillCommercialRecycling/initialize', null, { root: true } );
		await dispatch( 'citizenReverse911/initialize', null, { root: true } );
		await dispatch( 'citizenPermit/initialize', null, { root: true } );
	},

	reset: async function( { commit, dispatch }, clearCache=true ) {
		//console.log( 'citizen member reset' )
		let memberCancel          = CitizenConnectApiService.cancel( 'Sign out' )
		let permitsCancel = PermitsApiService.cancelAll()
		let memberReset           = commit( 'reset' );
		let dependentModulesReset = dispatch( 'resetDependentStores' );

		await memberCancel
		await permitsCancel
		await memberReset
		await dependentModulesReset

		if(clearCache) {
			if( localStorage.getItem( storeKey ) ) {
				localStorage.removeItem( storeKey );
			}
			if( localStorage.getItem( storeKey+'.impersonate' ) ) {
				localStorage.removeItem( storeKey+'.impersonate' );
			}
		}
	},

	resetDependentStores: async function( { dispatch } ) {
		let paymentReset                     = dispatch( 'citizenPayment/reset', null, { root: true } );
		let taxReset                         = dispatch( 'citizenTax/reset', null, { root: true } );
		let utilityReset                     = dispatch( 'citizenUtility/reset', null, { root: true } );
		let landfillResidentialReset         = dispatch( 'citizenLandfillResidential/reset', null, { root: true } );
		let atvReset                         = dispatch( 'citizenAtv/reset', null, { root: true } );
		let landfillCommercialWasteReset     = dispatch( 'citizenLandfillCommercialWaste/reset', null, { root: true } );
		let landfillCommercialRecyclingReset = dispatch( 'citizenLandfillCommercialRecycling/reset', null, { root: true } );
		let reverse911StoreReset             = dispatch( 'citizenReverse911/reset', null, { root: true } );
		let searchReset                      = dispatch( 'citizenSearch/reset', null, { root: true } );
		let permitReset                      = dispatch( 'citizenPermit/reset', null, { root: true } );

		await paymentReset
		await taxReset
		await utilityReset
		await landfillResidentialReset
		await atvReset
		await landfillCommercialWasteReset
		await landfillCommercialRecyclingReset
		await reverse911StoreReset
		await searchReset
		await permitReset
	},

	getAccessToken: async function( { state, commit, dispatch } ) {
		//if the task is already getting a new one
		if( state._gettingAccessToken ) {
			await waitUntil( () => !state._gettingAccessToken )
			return state.accessToken
		}

		commit( 'setGettingAccessToken', true )
		//console.log( 'getAccessToken - start' );
		let getNewToken = false;

		if( state.accessToken=="" ) {
			getNewToken = true;
		}
		else {
			try {
				let decodedCurrentAccessToken = jwt_decode( state.accessToken );
				if( Date.now()>=( decodedCurrentAccessToken.exp * 1000 ) ) {
					getNewToken = true;
					//console.log( 'getAccessToken - access token expired' );
				}
			}
			catch( e ) {
				//console.log( 'getAccessToken - access token invalid' );
				getNewToken = true;
			}
		}

		if( getNewToken ) {
			await commit( 'setAccessToken', '' );
			if( state.refreshToken=='' ) {
				commit( 'setGettingAccessToken', false )
				dispatch( 'reset' )
				router.push( { name: 'citizen.auth.signIn' } )
				return '';
			}
			//console.log( 'getAccessToken - exchange refresh token' );
			await dispatch( 'exchangeRefreshToken' );
		}

		//console.log( 'getAccessToken - return access token' );
		await commit( 'setFresh', true );
		commit( 'setGettingAccessToken', false )
		return state.accessToken;
	},

	exchangeRefreshToken: async function( { state, commit, dispatch } ) {
		commit( 'setExchangingRefreshToken', true )
		//console.log( 'exchangeRefreshToken - start' );

		//if refresh token is invalid or expired, send user to sign in page
		let refreshTokenExpiredInvalid = false;
		try {
			let decodedCurrentRefreshToken = jwt_decode( state.refreshToken );
			if( Date.now()>( decodedCurrentRefreshToken.exp * 1000 ) ) {
				refreshTokenExpiredInvalid = true;
				//console.log( 'refresh token expired' );
			}
		}
		catch( e ) {
			//console.log( 'exchangeRefreshToken - refresh token invalid' );
			refreshTokenExpiredInvalid = true;
		}

		if( refreshTokenExpiredInvalid ) {
			commit( 'setRefreshToken', '' )
			if( router.currentRoute.name!=='citizen.auth.signIn' ) {
				dispatch( 'reset' )
				await router.push( { name: 'citizen.auth.signIn' } )
			}
			commit( 'setExchangingRefreshToken', false )
			return;
		}


		//refresh token is valid, exchange it for a new access token and refresh token
		let payload = {
			'grant_type':    'refresh_token',
			'refresh_token': state.refreshToken,
			'client_id':     process.env.VUE_APP_PAYMENTS_API_CLIENT_ID,
			'scope':         state.roles.join( ' ' )
		};

		try {
			//console.log( 'exchangeRefreshToken - exchange refresh token' );
			let apiResponse = await CitizenConnectApiService.post( 'v2/auth/authorize', payload, {}, false );
			commit( 'setDataFromToken', apiResponse.data.access_token );
			commit( 'setRefreshToken', apiResponse.data.refresh_token );
		}
		catch( e ) {
			//console.log( 'exchangeRefreshToken - error exchanging refresh token: ' + e.message );
			dispatch( 'reset' )
			await router.push( { name: 'citizen.auth.signIn' } )
		}

		commit( 'setExchangingRefreshToken', false )

		//console.log( 'exchangeRefreshToken - end' );
	},

	exchangeAuthorizationGrantCode: async function( { commit, dispatch }, authorizationGrantCode ) {
		//console.log( 'exchangeAuthorizationGrantCode - start' );

		//refresh token is valid, exchange it for a new access token and refresh token
		let payload = {
			'grant_type': 'authorization_code',
			'code':       authorizationGrantCode,
			'client_id':  process.env.VUE_APP_PAYMENTS_API_CLIENT_ID
		};

		try {
			//console.log( 'exchangeAuthorizationGrantCode - exchange refresh token' );
			let apiResponse = await CitizenConnectApiService.post( 'v2/auth/authorize', payload, {}, false );
			commit( 'setDataFromToken', apiResponse.data.access_token );
			commit( 'setRefreshToken', apiResponse.data.refresh_token );
			await dispatch( 'getAccountNumbers' );
			await dispatch( 'initializeDependentStores' );
			await router.push( { name: 'citizen.dashboard' } )
		}
		catch( e ) {
			//console.log( 'exchangeAuthorizationGrantCode - error exchanging refresh token: ' + e.message );
			//console.log( e );
			await router.push( {
				name:  'citizen.auth.error',
				query: {
					code:    e.code,
					message: e.message
				}
			} )
		}

		//console.log( 'exchangeAuthorizationGrantCode - end' );
	},

	passwordGrantSignIn: async function( { commit, dispatch }, form ) {
		//console.log( 'passwordGrantSignIn - start' );

		//refresh token is valid, exchange it for a new access token and refresh token
		let payload = {
			'grant_type': 'password',
			'client_id':  process.env.VUE_APP_PAYMENTS_API_CLIENT_ID,
			'scope':      'login',
			'username':   form.email,
			'password':   form.password
		};

		try {
			//console.log( 'passwordGrantSignIn - post' );
			let apiResponse = await CitizenConnectApiService.post( 'v2/auth/authorize', payload, {}, false );
			commit( 'setDataFromToken', apiResponse.data.access_token );
			commit( 'setRefreshToken', apiResponse.data.refresh_token );
			await dispatch( 'getAccountNumbers' );
			await dispatch( 'initializeDependentStores' );
			//console.log( 'go to dashboard' );
			await router.push( { name: 'citizen.dashboard' } )
		}
		catch( e ) {
			//console.log( 'passwordGrantSignIn - error with sign in: ' + e.message );
			if( e.response && e.response.data.message ) {
				return e.response.data.message;
			}
			return e.message;
		}

		//console.log( 'passwordGrantSignIn - end' );
	},

	getAccountNumbers: async function( { state, dispatch } ) {

		let apiResponse = null;
		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/member/accountNumbers/' + state.user.userId );
			await dispatch( 'setAccounts', {
				accounts: apiResponse.data.data.taxes,
				type:     constants.PAYMENT_TYPE_TAX_CODE
			} )
			await dispatch( 'setAccounts', {
				accounts: apiResponse.data.data.public_utilities,
				type:     constants.PAYMENT_TYPE_UTILITY_CODE
			} )
			await dispatch( 'setAccounts', {
				accounts: apiResponse.data.data.permits,
				type:     constants.PAYMENT_TYPE_PERMIT_CODE
			} )
			await dispatch( 'setAccounts', {
				accounts: apiResponse.data.data.permitRequests,
				type:     constants.PAYMENT_TYPE_PERMIT_REQUEST_CODE
			} )
			await dispatch( 'setAccounts', {
				accounts: apiResponse.data.data.landfill,
				type:     constants.PAYMENT_TYPE_LANDFILL_CODE
			} )
			await dispatch( 'setAccounts', {
				accounts: apiResponse.data.data.atv,
				type:     constants.PAYMENT_TYPE_ATV_CODE
			} )
			await dispatch( 'setAccounts', {
				accounts: apiResponse.data.data.wasteworks[ constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_API_CODE ],
				type:     constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_WASTE_CODE
			} )
			await dispatch( 'setAccounts', {
				accounts: apiResponse.data.data.wasteworks[ constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_RECYCLING_API_CODE ],
				type:     constants.PAYMENT_TYPE_COMMERCIAL_LANDFILL_RECYCLING_CODE
			} )
		}
		catch( e ) {
			//console.error( e );
			throw new CitizenApiError( e.message, e.code, {}, '8F041B1C-AF8C-429F-A997-1CFB9F3D7773' );
		}
	},

	setAccounts: async function( { state, commit }, { accounts, type } ) {
		// accountsAndType = { accounts:["",""], type:"" }

		//verify accountsAndType structure is correct
		if( typeof accounts==='undefined') {
			throw new CitizenLogicError( 'Missing account numbers', 500, {}, '865A3B68-7F2F-4F9A-BA44-71BD3E014621' );
		}
		else if( typeof type==='undefined' ) {
			throw new CitizenLogicError( 'Missing account type', 500, {}, 'A94C94C8-D8C6-4E79-8D9D-962AFD9C329C' );
		}

		//verify types are valid
		if( !Object.hasOwn( state.accountNumbers, type ) ) {
			throw new CitizenLogicError( type+' is an invalid accounts type', 500, {}, '60FDAE12-03EA-4694-85A1-E84BFA6623C7' );
		}

		//add account
		commit( 'setAccounts', {accounts, type} )

	},

	addAccount: async function( { state, commit, dispatch }, { account, type } ) {

		//verify accountsAndType structure is correct
		if( typeof account==='undefined') {
			throw new CitizenLogicError( 'Missing account number', 500, {}, 'C3D0C3EC-7043-450F-B8EB-2512764E7827' );
		}
		else if( typeof type ==='undefined' ) {
			throw new CitizenLogicError( 'Missing account type', 500, {}, '3A63CF89-B439-4485-9877-FE5F0E0607AC' );
		}

		//verify types are valid
		if( !Object.hasOwn( state.accountNumbers, type ) ) {
			throw new CitizenLogicError( type+' is an invalid account type', 500, {}, 'F49C8D92-F923-4615-8C10-2739EED9A805' );
		}

		//don't duplicate an account
		let isMemberAccount = await dispatch('isMemberAccount', { accountNumber:account, type:type })
		if(!isMemberAccount) {
			commit( 'addAccount', { account, type } )
		}


	},

	removeAccount: async function( { state, commit }, accountsAndType ) {
		// accountsAndType = { account:"", type:"" }

		//verify accountsAndType structure is correct
		if( !Object.prototype.hasOwnProperty.call( accountsAndType, 'account' ) ) {
			throw new CitizenLogicError( 'Missing account number', 500, {}, 'C3D0C3EC-7043-450F-B8EB-2512764E7827' );
		}
		else if( !Object.prototype.hasOwnProperty.call( accountsAndType, 'type' ) ) {
			throw new CitizenLogicError( 'Missing account type', 500, {}, '3A63CF89-B439-4485-9877-FE5F0E0607AC' );
		}

		//verify types are valid
		if( !Object.prototype.hasOwnProperty.call( state.accountNumbers, accountsAndType.type ) ) {
			throw new CitizenLogicError( 'Invalid account type', 500, {}, 'F49C8D92-F923-4615-8C10-2739EED9A805' );
		}

		//add account
		commit( 'removeAccount', accountsAndType )

	},

	changePassword: async function( { state }, password ) {
		let apiResponse = null;

		try {
			apiResponse = await CitizenConnectApiService.post( 'v2/member/changePassword/' + state.user.userId, { password: password } );
		}
		catch( e ) {
			//console.error( e );
			throw new CitizenApiError( e.message, e.code, {}, '68F0468E-8BC9-43BD-ABD1-33EE4075BDF6' );
		}

		return apiResponse
	},

	changeEmail: async function( { state }, email ) {
		let apiResponse = null;

		try {
			apiResponse = await CitizenConnectApiService.post( 'v2/member/changeEmail/' + state.user.userId, { email: email } );
		}
		catch( e ) {
			//console.error( e );
			throw new CitizenApiError( e.message, e.code, {}, 'C3CD61BE-7B05-49AF-B934-A276CA67FBB0' );
		}

		return apiResponse

	},

	confirmChangeEmail: async function( vueX, { memberId, changeRequestId, requestVerificationCode } ) {
		let apiResponse = null;

		try {
			apiResponse = await CitizenConnectApiService.post( 'v2/member/confirmChangeEmail/' + memberId + '/' + changeRequestId + '/' + requestVerificationCode, {}, {}, false );
		}
		catch( e ) {
			//console.error( e );
			throw new CitizenApiError( e.message, e.code, {}, '8A1B6B01-85E3-4A02-A051-043BC75A518F' );
		}

		return apiResponse
	},

	getChangeEmailRequest: async function( vueX, { memberId, changeRequestId, requestVerificationCode } ) {

		let apiResponse = null;

		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/member/getChangeEmailRequest/' + memberId + '/' + changeRequestId + '/' + requestVerificationCode, {}, false );
		}
		catch( e ) {
			//console.error( e );
			throw new CitizenApiError( e.message, e.code, {}, 'AB810E71-B75D-4B2F-915F-399CA16E8CFD' );
		}

		return apiResponse

	},

	verifyAccount: async function( vueX, { memberId, verificationCode } ) {
		let apiResponse = null;

		try {
			apiResponse = await CitizenConnectApiService.post( 'v2/member/verifyAccount/' + memberId + '/' + verificationCode, {}, {}, false );
		}
		catch( e ) {
			//console.error( e );
			throw new CitizenApiError( e.message, e.code, {}, '0A11A21B-DB7A-4008-ABF3-99CE65DF58F1' );
		}

		return apiResponse
	},

	getVerifyAccount: async function( vueX, { memberId, verificationCode } ) {

		let apiResponse = null;

		try {
			apiResponse = await CitizenConnectApiService.get( 'v2/member/getVerifyAccount/' + memberId + '/' + verificationCode, {}, false );
		}
		catch( e ) {
			//console.error( e );
			throw new CitizenApiError( e.message, e.code, {}, '7EAD2ECB-96A9-4C83-BA86-03A29B02FAD1' );
		}

		return apiResponse

	},

	isMemberAccount: function( { state }, { type, accountNumber } ) {
		let memberAccounts = state.accountNumbers[ type ]
		//string account numbers
		if( memberAccounts.includes( accountNumber ) ) {
			return true
		}
		//integer account numbers
		else if( memberAccounts.includes( parseInt( accountNumber ) ) ) {
			return true
		}
		//multikey account numbers
		else {
			return some( memberAccounts, accountNumber )
		}

	},

	impersonate:async function( { state, commit, dispatch }, { access_token, refresh_token } ) {
		localStorage.setItem( storeKey+'.impersonate', JSON.stringify( state ) );
		await dispatch('resetDependentStores')
		commit('setImpersonating', true )
		commit( 'setDataFromToken', access_token );
		commit( 'setRefreshToken', refresh_token );
		await dispatch( 'getAccountNumbers' );
		await dispatch( 'initializeDependentStores' );
	},

	exitImpersonate:async function({ commit, dispatch } ) {
		await CitizenConnectApiService.cancel( 'Request cancelled' )
		await dispatch('resetDependentStores')
		commit('setModuleStateFromImpersonateLocalStorage');
		commit('setImpersonating', false )
		await dispatch( 'getAccountNumbers' );
		await dispatch( 'initializeDependentStores' );
	}
}

const mutations = {

	setDataFromToken: function( state, accessToken ) {
		try {
			let decodedAccessToken = jwt_decode( accessToken );
			state.accessToken      = accessToken;
			state.roles            = decodedAccessToken.scope;
			state.user             = decodedAccessToken.data;
			state.isSignedIn       = true;
			state.fresh            = true;
		}
		catch( e ) {
			throw new AuthError( 'Invalid authentication token provided by API', 500, e, 'DDA5CE54-D6DB-4753-85FF-B947ED042ACE' );
		}
	},
	setAccessToken:   function( state, accessToken ) {
		state.accessToken = accessToken;
	},
	setRefreshToken:  function( state, refreshToken ) {
		state.refreshToken = refreshToken;
	},

	setModuleStateFromLocalStorage: function( state ) {
		//console.log( 'citizen member setModuleStateFromLocalStorage' )
		if( localStorage.getItem( storeKey ) ) {
			// Replace the state object with the stored item
			let data = JSON.parse( localStorage.getItem( storeKey ) );
			if( data!==null ) {
				Object.assign( state, data )
				state.fresh                   = false;
				state._gettingAccessToken     = false;
				state._exchangingRefreshToken = false;
			}
		}
	},
	setModuleStateFromImpersonateLocalStorage: function( state ) {
		if( localStorage.getItem( storeKey+'.impersonate' ) ) {
			// Replace the state object with the stored item
			let data = JSON.parse( localStorage.getItem( storeKey+'.impersonate' ) );
			if( data!==null ) {
				Object.assign( state, data )
				state.fresh                   = false;
				state._gettingAccessToken     = false;
				state._exchangingRefreshToken = false;
			}
		}
	},

	setFresh: function( state, val ) {
		state.fresh = val;
	},

	setImpersonating: function( state, val ) {
		state.impersonating = val;
	},

	setGettingAccessToken: function( state, val ) {
		state._gettingAccessToken = val;
	},

	setExchangingRefreshToken: function( state, val ) {
		state._exchangingRefreshToken = val;
	},

	reset( state ) {
		Object.assign( state, getDefaultState() )
	},

	setAccounts( state, accountsAndType ) {
		state.accountNumbers[ accountsAndType.type ] = accountsAndType.accounts;
	},

	addAccount( state, accountsAndType ) {
		state.accountNumbers[ accountsAndType.type ].push( accountsAndType.account );
	},

	removeAccount( state, accountsAndType ) {
		let index = state.accountNumbers[ accountsAndType.type ].findIndex( accountNumber => accountNumber==accountsAndType.account )
		if( accountsAndType.type==='permit' ) {
			index = state.accountNumbers[ accountsAndType.type ].findIndex( accountNumber => accountNumber.projectId==accountsAndType.account.projectId )
		}
		if( accountsAndType.type==='permitRequest' ) {
			index = state.accountNumbers[ accountsAndType.type ].findIndex( accountNumber => accountNumber.projectRequestId==accountsAndType.account.projectRequestId )
		}
		if( index!== -1 ) {
			state.accountNumbers[ accountsAndType.type ].splice( index, 1 );
		}
	},

	setEmail( state, email ) {
		state.user.email = email
	}

}


export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
