import Vue       from 'vue';
import VueRouter from 'vue-router';

import routes    from '@/router/routes';
import store     from '@/store/index.js';

Vue.use( VueRouter )


const router = new VueRouter( {
	mode: 'history',
	base: '/',
	routes,
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return { x:savedPosition.x, y:savedPosition.y, behavior: 'smooth' }
		} else {
			let y = ((document.getElementById('vue-app')?.getBoundingClientRect()?.top ?? 0) + window.scrollY )-160
			return { x: 0, y: y, behavior: 'smooth' }
		}
	}
} )


router.beforeEach( async ( to, from, next ) => {

	//warn and bail if needed
	if(store.getters['route/warnOnRouteChange']) {
		if(!confirm('Are you sure you want to navigate away? You will lose any unsaved changes.')) {
			return;
		}
	}

	//VISUAL: START ROUTING LOADING
	store.commit('route/routing', true);
	store.commit('route/to', to);

	//AUTHENTICATION GUARD
	if( to.meta.citizenAuth.required && !store.getters[ 'citizenMember/isSignedIn' ] && store.getters[ 'citizenMember/tryRefreshToken' ] ) {
		await store.dispatch( 'citizenMember/getAccessToken' );
	}
	else if( to.meta.citizenAuth.required && !store.getters[ 'citizenMember/isSignedIn' ] ) {
		await store.dispatch( 'citizenMember/reset' )
		await router.push( { name: 'citizen.auth.signIn' } )
		return
	}

	//verify roles allow access
	if( to.meta.citizenAuth.required && to.meta.citizenAuth.roles.length>0 ) {
		for( let i in to.meta.citizenAuth.roles ) {
			if( !store.state.citizenMember.roles.includes( to.meta.citizenAuth.roles[ i ] ) ) {
				store.dispatch( 'sendSnackbar', { color: 'error', message: 'Access denied' } )
				await router.push( { name: 'citizen.dashboard' } )
				return
			}
		}
	}

	next()
} );

router.onError( () => {
	//VISUAL: END ROUTING LOADING
	store.commit( 'route/routing', false );
} )

router.afterEach( ( to, from, failure ) => {

	if( failure ) {
		//console.log( 'fail' )
	}

	//VISUAL: END ROUTING LOADING
	store.commit( 'route/routing', false );

} )

export default router
